import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BetSlipBestOfInterface } from '..';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';


const apiBetBestOfUrl = `${environment.api.request}/bet/bestof`;

@Injectable({
    providedIn: 'root'
})
export class BetSlipBestOfService {
    constructor(
        private httpService: HttpService
    ) {}


    public get(): Observable<BetSlipBestOfInterface> {
        return this.loadBestOf();
    }

    /**
     * loads the best of from the api
     * 
     * @param betSlipId 
     * @returns 
     */
    private loadBestOf(): Observable<BetSlipBestOfInterface> {
        return this.httpService.get<BetSlipBestOfInterface>(`${apiBetBestOfUrl}`);
    }    
}