<ng-template #modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="glamour-container animate__animated animate__flipInX">
            <div class="starrays" [class]="(data.ranking === 1) ? 'gold' : ''"></div>
            <span class="tiger-sprite" [class]="'huge-icon arena-top-winner-'+data.ranking"></span>
        </div>        
    </div>
    <!-- <span class="tiger-sprite huge-icon rank-1"></span> -->
    <div class="modal-body pb-5">
        <div class="row">
            <div class="col-12 col-sm-11 mx-auto text-center">
                <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">{{ 'account.bets.slip.modalBestOf.title' | translate }}</h1>
            </div>
            <div class="col-12 col-sm-10 mx-auto text-center">
           
                <p class="subline mb-4 animate__animated animate__fadeInUp animate__delay-1s">
                    {{ 'account.bets.slip.modalBestOf.date' | translate: { date: (data.eventDate | todayDate) } }}
                    <span class="placement-highlight-large">{{ 'account.bets.slip.modalBestOf.ranking' | translate: { ranking: data.ranking } }}</span>
                    {{ 'account.bets.slip.modalBestOf.category' | translate: { category: data.category } }}
                </p>

                <div class="animate__animated animate__fadeInUp animate__delay-2s">
                    <p class="subline mb-3">{{ 'account.bets.slip.modalBestOf.bonus' | translate }}</p>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="data?.bonus?.coins">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png">
                                    +<app-shared-countup [to]="data.bonus.coins" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'coins' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>                                   
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="data?.bonus?.xp">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/xp-1.png">
                                    +<app-shared-countup [to]="data.bonus.xp" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'xp' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="data?.bonus?.booster">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png"> {{ 'booster.'+data.bonus.booster+'.title' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>

           </div>
           <div class="col-12 col-sm-11 mx-auto text-center animate__animated animate__fadeInUp animate__delay-2500ms">
                <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">
                  {{ 'account.bets.slip.modalBestOf.button' | translate }}
                </button>
                <p class="text-center mt-2 d-block d-md-none d-lg-none d-xl-none"><span class="cursor-pointer" (click)="modalDismiss()">{{ 'close' | translate }}</span></p>
           </div>
        </div>
   </div>
</ng-template>


        