<ng-template #modal>
    <div class="modal-header" [class]="data.headerClass">
        <button type="button" class="close" [class]="data.headerClass" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <span class="tiger-sprite huge-icon rank-1"></span>
    <div class="modal-body pb-5">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                <span class="feedback-icon" *ngIf="data.icon"><i class="fa" [class]="data.icon"></i></span><!-- fa-check-circle -->
                <h2 class="tigerfont text-uppercase mb-3">
                    <ng-container *ngIf="isModalText(data.title); else elseTitleTranslation">{{ data.title.text | translate: data.title.parameter }}</ng-container>
                    <ng-template #elseTitleTranslation>{{ data.title | translate }}</ng-template>
                </h2>
            </div>
            <div class="col-12 col-sm-10 mx-auto text-center">
                <p>
                    <ng-container *ngIf="isModalText(data.message); else elseMessageTranslation">{{ data.message.text | translate: data.message.parameter }}</ng-container>
                    <ng-template #elseMessageTranslation>{{ data.message | translate }}</ng-template>
                </p>
                <p class="font-italic" *ngIf="data.note">
                    <ng-container *ngIf="isModalText(data.note); else elseNoteTranslation">{{ data.note.text | translate: data.note.parameter }}</ng-container>
                    <ng-template #elseNoteTranslation>{{ data.note | translate }}</ng-template>
                </p>
            </div>
            <div class="col-12 col-sm-11 mx-auto text-center" *ngIf="!data.hideButtons">
                <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">
                    <ng-container *ngIf="isModalText(data.buttonConfirm); else elseButtonConfirmTranslation">{{ data.buttonConfirm.text | translate: data.buttonConfirm.parameter }}</ng-container>
                    <ng-template #elseButtonConfirmTranslation>{{ data.buttonConfirm | translate }}</ng-template>
                </button>
            </div>
        </div>
    </div>
</ng-template>