<div class="modal-header tigerfont">
    <h2>{{ 'bet.slip.title' | translate }}</h2>
    <div class="betslip-countdown">
        {{ 'season.banner.duration' | translate }}
        <span>
            <span *ngIf="daysLeft > 0">
                <countdown [config]="{ stopTime: season.endDate | timestamp, format: 'D', formatDate: dateFormatReduceFix }"></countdown>
                {{ 'season.banner.days' | translate:{ count: daysLeft } }}
            </span>
            <countdown *ngIf="daysLeft == 0" [config]="{ stopTime: season.endDate | timestamp }"></countdown>
        </span>
    </div>
    <div class="betslip-tabs tigerfont">
        <a href="#" class="betslip-tab tab1 betslip-mode betslip-mode-SINGLE" [class]="getModeCss(betSlipMode.Single)" (click)="setMode(betSlipMode.Single)">{{ 'bet.slip.type.single.title' | translate }}</a>
        <a href="#" class="betslip-tab tab2 betslip-mode betslip-mode-COMBI" [class]="getModeCss(betSlipMode.Combi)" (click)="setMode(betSlipMode.Combi)">{{ 'bet.slip.type.combi.title' | translate }}</a>
        <a href="#" class="betslip-tab tab3 betslip-mode betslip-mode-SYSTEM" [class]="getModeCss(betSlipMode.System)" (click)="setMode(betSlipMode.System)">{{ 'bet.slip.type.system.title' | translate }}</a>
    </div>
    <button type="button" class="close" (click)="closeBetSlip()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" id="betSlipScrollerMobile">
    <div class="betslip-content">
        <div class="betslip-entries" id="betslip-entries">

            <div class="betslip-notice" *ngIf="betSlip.count === 0">
                <i class="fas fa-clipboard"></i>
                <p>{{ 'bet.slip.empty.title' | translate }}<br />{{ 'bet.slip.empty.message' | translate }}</p>
            </div>  

            <div class="betslip-entry" *ngFor="let bet of betSlip.bets | keyvalue: orderAdded">
                <div class="betslip-info">
                    <span class="betslip-result">
                        <ng-container *ngIf="('bet.offer.'+bet.value.offer.marketName+'.title' | translate) === 'bet.offer.'+bet.value.offer.marketName+'.title'; else translatedMarket">
                            {{ bet.value.offer.marketName }}:
                        </ng-container>
                        <ng-template #translatedMarket>
                            {{ 'bet.offer.'+bet.value.offer.marketName+'.title' | translate }}:
                        </ng-template>

                        <ng-container *ngIf="isDefaultOffer(bet.value.offer.name); else otherOffer">
                            <ng-container *ngIf="bet.value.offer.name === '1'">{{ bet.value.game.homeTeamName }}</ng-container>
                            <ng-container *ngIf="bet.value.offer.name === 'X'">{{ "bet.slip.draw" | translate }}</ng-container>
                            <ng-container *ngIf="bet.value.offer.name === '2'">{{ bet.value.game.awayTeamName }}</ng-container>
                        </ng-container>
                        <ng-template #otherOffer>
                            <ng-container *ngIf="isTranslateableOfferDetail(bet.value.offer.name); else rawOffer">
                                {{ 'bet.offerDetail.'+bet.value.offer.name | translate }}
                            </ng-container>
                            <ng-template #rawOffer>
                                {{ bet.value.offer.name }} {{ bet.value.offer.name.line }}
                            </ng-template>
                        </ng-template>
                    </span>
                    <span class="betslip-teams">
                        {{ bet.value.game.homeTeamName }}<br />
                        {{ bet.value.game.awayTeamName }}
                    </span>
                </div>
                <div class="betslip-quote tigerfont">{{ bet.value.offer.odds | number: '1.0' }}</div>
                <a href="#" class="betslip-close betslip-remove-bet" (click)="remove(bet.value.offer)">
                    <span class="tiger-sprite medium-icon close"></span>
                </a>
                <div class="single-bet" *ngIf="betSlip.mode === betSlipMode.Single">
                    <span class="tiger-sprite medium-icon coin"></span>
                    {{ 'bet.slip.stake.title' | translate }}:
                    <input type="number" min="10" step="1" class="tigerfont" [(ngModel)]="betSlip.coins.single[bet.value.offer.outcomeId]" (focus)="selectCoinInput('mobile_betSlipSingleBet'+bet.value.offer.outcomeId)" (blur)="onCoinChange($event, bet.value.offer)" [id]="'mobile_betSlipSingleBet'+bet.value.offer.outcomeId">
                    <span class="coins-text tigerfont" (click)="selectCoinInput('mobile_betSlipSingleBet'+bet.value.offer.outcomeId)">{{ 'coins' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer" [class]="betSlip.mode | lowercase">
    <div class="system-options" *ngIf="betSlip.mode === betSlipMode.System">
        <select class="form-control" (change)="onSystemChange($event)">
            <option value="{{ option.key }}" id="system{{ option.key }}" [selected]="isSystemBetSelected(option.key)" *ngFor="let option of getSelectableSystemBet() | keyvalue: orderOrigin">
                {{ 'bet.slip.system' | translate:{ option:(option.key), bets: betSlip.count} }} ({{ 'bet.slip.count' | translate:{ count: (option.value | number: '1.0') } }})
            </option>
        </select>
    </div>
    <div class="betslip-options">
        <div class="count-bets">
            {{ 'bet.slip.count' | translate: { count: getBetCount() } }} | {{ 'bet.slip.win.quote' | translate }}: {{ betSlip.quote | number: '1.0' }} X
        </div>
        <a href="#" class="betslip-delete-all betslip-remove-all" (click)="removeAll()">
            <i class="fas fa-trash-alt" tooltip="{{ 'bet.slip.clear' | translate }}"></i>
        </a>
    </div>
    <div class="betslip-custom" *ngIf="betSlip.mode !== betSlipMode.Single">
        <div class="betslip-custom-headline tigerfont" *ngIf="betSlip.mode === betSlipMode.Combi">{{ 'bet.slip.stake.total' | translate }}:</div>
        <div class="betslip-custom-headline tigerfont" *ngIf="betSlip.mode === betSlipMode.System">{{ 'bet.slip.stake.perBet' | translate }}:</div>
        <div class="coins-einsatz tigerfont" *ngIf="betSlip.mode !== betSlipMode.Single">
            <a href="#" class="less decrease-wager" (click)="onCoinDecrease()">-</a>
            <input type="number" class="tigerfont" autocomplete="off" [(ngModel)]="betSlip.coins.system" (blur)="onCoinChange($event)" *ngIf="betSlip.mode === betSlipMode.System" id="mobile_betSlipMultiBet" (click)="selectCoinInput('mobile_betSlipMultiBet')">
            <input type="number" class="tigerfont" autocomplete="off" [(ngModel)]="betSlip.coins.combi" (blur)="onCoinChange($event)" *ngIf="betSlip.mode === betSlipMode.Combi" id="mobile_betSlipMultiBet" (click)="selectCoinInput('mobile_betSlipMultiBet')">
            <span class="tiger-sprite medium-icon coin"></span><span class="coins-text" (click)="focusAndSelectCoinInput('mobile_betSlipMultiBet')">{{ 'coins' | translate }}</span>
            <a href="#" class="more increase-wager"(click)="onCoinIncrease()">+</a>
        </div>
    </div>
    <ul class="betslip-outcome">
        <li class="outcome-xp"><div class="xp-result tigerfont betslip-max-xp"><span class="value">{{ betSlip.win.xp | number: '1.0' }}</span> <span class="name">{{ 'xp' | translate }}</span></div>{{ 'bet.slip.win.payout' | translate }}</li>  
        <li class="outcome-coins"><div class="coin-result tigerfont betslip-max-payout "><span class="value">{{ betSlip.win.coins | number: '1.0' }}</span> <span class="name">{{ 'coins' | translate }}</span></div>{{ 'bet.slip.win.payout' | translate }}</li> 
    </ul>
    <div class="betslip-action">
        <a href="#" class="btn btn-primary btn-lg btn-block tigerfont" [class]="getSubmitButtonCss()" (click)="onSubmit()">{{ 'bet.slip.place' | translate: { type: betSlip.mode } }} <br>{{ betSlip.coins.stake | number:'1.0' }} {{ 'coins' | translate }}</a>
    </div>
</div>
