import moment from 'moment';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Affiliate, AffiliateMap } from '../model/affiliate.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AffiliateService {
    /**
     * storage entry name
     */
    private storageName = "affiliate";

    public constructor(
        private cookieService: CookieService
    ) {
        // setup the storage name for the current environment
        this.storageName = environment.cookies.affiliate || this.storageName;
    }

    /**
     * returns a affiliate object (from storage or new)
     */
    public get(): Affiliate {
        //return this.getLocaleStorage() || new Affiliate()
        return this.getCookieStorage() || new Affiliate();
    }

    /**
     * returns a affiliate object (from storage or new)
     */
    public set(affiliate: Affiliate): void {
        //this.setLocaleStorage(affiliate);
        this.setCookieStorage(affiliate);
    }

    /**
     * set an empty affiliate to storage
     */
    public reset(): void {
        //this.setLocaleStorage(new Affiliate());
        this.setCookieStorage(new Affiliate());
    }

    /**
     * use only once and in affiliate initializer
     */
    public initialize(): void {
        const url = new URL(window.location.href);
        const referrer: string = window.document.referrer || window.location.href;
        const affiliate: Affiliate = this.get();

        for (const mapKey in AffiliateMap) {
            const mapping: string = AffiliateMap[mapKey];
            if (!mapping) { continue; }

            const parameter: string = url.searchParams.get(mapping)
            if (!parameter) { continue; }

            affiliate[mapKey] = url.searchParams.get(mapping);
        }
        affiliate.referrer = referrer;
        this.set(affiliate);
    }



    /**
     * return a affiliate object from cookie storage
     */
    private getCookieStorage(): Affiliate | null {
        const item: string = this.cookieService.get(this.storageName);
        if (!item) { return null; }
        return JSON.parse(item);
    }

    /**
     * sets a affiliate object to cookie storage
     * 
     * @param affiliate 
     */
    private setCookieStorage(affiliate: Affiliate): void {
        const expires = moment().add(30, 'days').toDate();
        this.cookieService.set(this.storageName, JSON.stringify(affiliate), expires);
    }

    /**
     * return a affiliate object from locale storage
     */
    private getLocaleStorage(): Affiliate | null {
        const item: string = localStorage.getItem(this.storageName);
        if (!item) { return null; }
        return JSON.parse(item);
    }

    /**
     * sets a affiliate object to locale storage
     * 
     * @param affiliate 
     */
    private setLocaleStorage(affiliate: Affiliate): void {
        localStorage.setItem(this.storageName, JSON.stringify(affiliate));
    }
}