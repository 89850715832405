/**
 * after seaching for along time, finally found an option to extend
 * an observable to with an function which is called on subscribe
 * 
 * 
 * https://stackoverflow.com/a/48983205
 * https://gist.github.com/evxn/750702f7c8e8d5a32c7b53167fe14d8d
 */
import { defer, Observable } from "rxjs";


/** Example
import {from} from 'rxjs/observable/from';
from([1, 2, 3])
    .pipe(doOnSubscribe(() => console.log('subscribed to stream')))
    .subscribe(x => console.log(x), null, () => console.log('completed'));
*/

export function doOnSubscribe<T>(onSubscribe: () => void): (source: Observable<T>) => Observable<T> {
    return function inner(source: Observable<T>): Observable<T> {
        return defer(() => {
            onSubscribe();
            return source;
        });
    };
}