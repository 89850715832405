import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';



/**
 * Basic Api Url for member actions
 */
const apiMemberUrl = `${environment.api.request}/member`;


@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {
    /**
     * setup dependencies, init auth variables and subscripbe to events
     * 
     * @param http 
     */
    constructor(
        private httpService: HttpService
    ) { }


    /**
     * request a password forget mail
     */
    public request(email: string): Observable<{}> {
        return this.httpService.post<{}>(`${apiMemberUrl}/password/request`, { email: email });
    }

    /**
     * request a password forget mail
     */
    public validate(memberId: number, validation: number, hash: string): Observable<{}> {
        return this.httpService.post<{}>(`${apiMemberUrl}/password/validate`, { m: memberId, validation: validation, hash: hash });
    }

    /**
     * request a password forget mail
     */
    public reset(memberId: number, validation: number, hash: string, password: string): Observable<{}> {
        return this.httpService.patch<{}>(`${apiMemberUrl}/password/reset`, { m: memberId, validation: validation, hash: hash, password: password });
    }
}