import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";


const requestUrl = `${environment.api.request}/m2p/minigame`;

@Injectable({
    providedIn: "root",
})
export class M2pMiniGameService {
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * returns the url to init the minigame
     * 
     * @returns 
     */
    public getUrl(): Observable<string> {
        return this.loadGameUrl();
    }

    /**
     * loads the url from the api
     *
     * @param betSlipId
     * @returns
     */
    private loadGameUrl(): Observable<string> {
        return this.httpService.get<string>(requestUrl+"/init").pipe(
            map((response: any) => response.url)
        );
    }
}
