import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, first, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../http";
import { AuthenticationInterface } from "../interface/authentication.interface";
import { RoleNameEnum } from "../model/role-name.enum";
import { RolePriorityEnum } from "../model/role-priority.enum";

/**
 * Basic Api Url for auth actions
 */
const apiAuthUrl = `${environment.api.request}/auth`;

@Injectable({
    providedIn: "root",
})
export class AuthenticationService {
    /**
     * the current authentication
     */
    private authSubject: BehaviorSubject<AuthenticationInterface>;

    /**
     * observable authentication genereted from authSubject
     */
    private authObservable: Observable<AuthenticationInterface>;

    /**
     * a snapshot from the authSubject but not null if it
     * is currently refreshing
     */
    private authSnapshot: AuthenticationInterface;

    /**
     * indicates if the auth service is currently loading
     */
    private isLoading: boolean;

    constructor(private httpService: HttpService) {
        // inject dependencies
        this.authSnapshot = null;
        this.authSubject = new BehaviorSubject<AuthenticationInterface>(null);
        this.authObservable = this.authSubject.asObservable();
        this.isLoading = false;
    }

    /**
     * signs a request with Bearer authorization header
     * and returns the signed request
     *
     * @param request
     */
    public signRequestHeader(request: HttpRequest<any>): HttpRequest<any> {
        // validate if the request is an api request
        let isApiRequest: boolean = request.url.startsWith(environment.api.request);
        // check if an authentication exist and if its an api request
        if (isApiRequest && this.hasAuthentication()) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${this.getToken()}` },
            });
        }
        return request;
    }

    /**
     * returns the current auth object
     */
    public getAuthentication(): AuthenticationInterface {
        return this.authSnapshot;
    }

    /**
     * returns an observable authentication
     */
    public getAuthenticationObservable(): Observable<AuthenticationInterface> {
        return this.authObservable;
    }

    /**
     * alias for getToken
     */
    public getJwtToken(): string | null {
        return this.getToken();
    }

    /**
     * return current jwt token or null
     */
    public getToken(): string | null {
        if (this.hasAuthentication()) {
            return this.getAuthentication().token;
        }
        return null;
    }

    /**
     * checks if the is any authentication at the moment
     */
    public hasAuthentication(): boolean {
        return this.getAuthentication() !== null;
    }

    /**
     * returns the member role or null
     *
     * @returns
     */
    public getRole(): RoleNameEnum | string | null {
        return this.hasAuthentication() ? this.getAuthentication().role : null;
    }

    /**
     * check if the user has the passed role. If
     * strict is set to false, it this function
     * return also true if the role is above the
     * passed one.
     *
     * @param role
     * @param strict
     * @returns
     */
    public hasRole(role: RolePriorityEnum | RoleNameEnum | string | number, strict: boolean = true): boolean {
        // get the member priority from RoleEnum
        const memberPriority = RolePriorityEnum[this.getRole()];
        // get required priority
        const requiredPriority =
            // if role is a string, get the priority from the RoleEnum
            typeof role === "string"
                ? RolePriorityEnum[role]
                : // if its a number use it as priority
                typeof role === "number"
                ? role
                : // anything else results in unefined
                  undefined;

        // if required priority is not a number, we log this to console
        if (typeof requiredPriority !== "number") {
            console.warn("Priority for Role '" + role + "' is not defined");
        }
        // if required or member priority is not known, its a no and we return false
        if (typeof requiredPriority !== "number" || typeof memberPriority !== "number") {
            return false;
        }
        // validate role
        return strict
            ? // if strict, compare priorities
              memberPriority === requiredPriority
            : // if not strict, check if the member role priority is at bigger or
              // equal to the required priority
              memberPriority >= requiredPriority;
    }

    /**
     * checks if the user has the passed role
     * or a higher one
     *
     * @param role
     * @returns
     */
    public hasRoleAccess(role: RolePriorityEnum | RoleNameEnum | string | number): boolean {
        return this.hasRole(role, false);
    }

    /**
     * sets auth with a login token
     *
     * @param token
     */
    public setLoginToken(token: string): void {
        this.setAuth(token);
    }

    /**
     * logs in with credentials at the api and set auth object
     *
     * @param email
     * @param password
     */
    public login(email: string, password: string): Observable<AuthenticationInterface> {
        return this.authRequest("/login", { email, password });
    }

    /**
     * switch logged member
     *
     * @param memberId
     */
    public switchMember(memberId: number): Observable<AuthenticationInterface> {
        return this.refresh().pipe(
            switchMap((auth: AuthenticationInterface) => {
                return this.authRequest("/login/switch", { memberId: memberId });
            })
        );
    }

    /**
     * refresh the authentication
     */
    public refresh(): Observable<AuthenticationInterface> {
        return this.authRequest("/refresh");
    }

    /**
     * stop refreshing, invoke token and emtpty the auth object
     */
    public logout(): Observable<{ success: boolean }> {
        return this.httpService.post<{ success: boolean }>(`${apiAuthUrl}/logout`, {}, undefined, true).pipe(
            tap((data: { success: boolean }) => {
                this.unsetAuth();
            })
        );
    }

    /**
     * removes all authentication tokens for this member from db
     *
     * @param memberId
     * @returns
     */
    public logoutByMemberId(memberId: number): Observable<{ success: boolean }> {
        return this.httpService.post<{ success: boolean }>(`${apiAuthUrl}/logout/member`, {
            memberId: memberId,
        });
    }

    /**
     * request a url (with passed data) for a authentifiation
     *
     * @param url
     * @param data
     */
    private authRequest(url: string, data = {}): Observable<AuthenticationInterface> {
        // if the authentication request is allready loading, we wait for the response
        // and return that result like we would return the real request
        if (this.isLoading) {
            return this.authSubject.pipe(
                filter((authData: AuthenticationInterface) => authData !== null),
                first()
            );
        }
        // we start to load the authentication from the api
        this.isLoading = true;
        this.authSubject.next(null);
        return this.httpService.post<AuthenticationInterface>(apiAuthUrl + url, data, undefined, true).pipe(
            // if some error occures, set loading to false
            // and return the error (important!)
            catchError((error) => {
                this.isLoading = false;
                return throwError(error);
            }),
            // intern handle for a successfull request
            tap((data: AuthenticationInterface) => {
                this.setAuth(data.token);
                this.isLoading = false;
            })
        );
    }

    /**
     * sets authentication by parsing the payload of the passed jwt token
     *
     * @param token
     */
    private setAuth(token: string): void {
        const auth: AuthenticationInterface = this.encodeToken(token);
        // DONT CHANGE THE ORDER!
        // WE NEED TO SET THE SNAPSHOP BEFORE THE SUBJECT!
        this.authSnapshot = auth;
        this.authSubject.next(auth);
    }

    /**
     * unsets authentication
     */
    private unsetAuth(): void {
        // DONT CHANGE THE ORDER!
        // WE NEED TO CLEAR THE SNAPSHOP BEFORE THE SUBJECT!
        this.authSnapshot = null;
        this.authSubject.next(null);
    }

    /**
     * encodes payload from a jwt token and returns the content
     * as object including the the passed token
     *
     * credits:
     * https://www.codegrepper.com/code-examples/typescript/how+to+encode+jwt+token
     *
     * @param token
     */
    private encodeToken(token: string): AuthenticationInterface {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        // return the decoded token include the token itself
        return { ...JSON.parse(jsonPayload), token: token };
    }
}
