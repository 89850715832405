import { LabelService } from "../service/label.service";

/**
 * detects the current label
 * to use by the hostname
 * 
 * @param labelService
 */
export function LabelInitializer(labelService: LabelService) {
    return () => new Promise(resolve => {
        // check the hostname to configure the label to use
        labelService.initialaize(false)
            .subscribe({ error: e => { } })
            .add(resolve);
    });
}