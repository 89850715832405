import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { BettingGameChatInterface } from "../interface/betting-game-chat.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame/chat`;

@Injectable({
    providedIn: "root",
})
export class BettingGameChatService {
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * This function returns the chat of specific betting game
     *
     * @param {string} uuid - The uuid of the betting game
     * @returns An array of GameInterface objects.
     */
    public get(uuid: string): Observable<BettingGameChatInterface[]> {
        const url = apiUrl + "/get/" + uuid;
        return this.httpService.get<BettingGameChatInterface[]>(url);
    }

    /**
     * The function sends a message to the server and returns an array of BettingGameChatInterface
     * objects
     *
     * @param {string} uuid - The unique identifier of the betting game.
     * @param {string} message - The message you want to send
     * @returns An observable of an array of BettingGameChatInterface objects.
     */
    public send(uuid: string, message: string): Observable<BettingGameChatInterface[]> {
        const url = apiUrl + "/add";
        return this.httpService.post<BettingGameChatInterface[]>(url, {
            message: message,
            uuid: uuid,
        });
    }

    /**
     * It clears the chat.
     *
     * @param {string} uuid - The UUID of the user to clear the cart for.
     * @returns Observable<boolean>
     */
    public clear(uuid: string): Observable<boolean> {
        const url = apiUrl + "/clear";
        return this.httpService
            .post<{ success: boolean }>(url, { uuid: uuid })
            .pipe(map((response: { success: boolean }) => response.success));
    }
}
