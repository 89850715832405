import { DOCUMENT } from "@angular/common";
import { Component, Inject, Renderer2 } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-loading",
    templateUrl: "./modal-loading.component.html",
    styleUrls: ["./modal-loading.component.scss"],
})
export class ModalLoadingComponent extends ModalComponent {
    /**
     * loading message
     */
    public message: string;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     * @param document
     * @param renderer
     */
    constructor(
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal,

        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ message: "" });
    }

    public onOpen(): void {
        this.renderer.addClass(this.document.body, "home");
        this.renderer.addClass(this.document.body, "loading");
    }

    public onHidden(): void {
        this.renderer.removeClass(this.document.body, "home");
        this.renderer.removeClass(this.document.body, "loading");
    }
}
