/**
 * commads for events / communication between modal and parent
 */
export enum ModalEventType {
    /**
     * used to send an open event to modal or
     * feeback to parent
     */
    Open = "open",

    /**
     * used to send feedback from modal
     * to parent when shown state is reached
     */
    Shown = "shown",

    /**
     * used to send an close event to modal
     */
    Close = "close",

    /**
     * used to send an is-closed-event from modal to parents
     * after the modal is completly hidden
     */
    Closed = "closed",

    /**
     * used at destroy command
     */
    Destroy = "destroy",

    /**
     * used to send feedback if one side (mostly the modal)
     * is destroyed
     */
    Destroyed = "destroyed",

    /**
     * used to transfer data between modal and parents
     */
    Data = "data",

    /**
     * used to transfer data between modal and parents
     */
    Update = "update",

    /**
     * generally info event
     */
    Info = "info",

    /**
     * generally success event
     */
    Success = "success",

    /**
     * generally error event
     */
    Error = "error",
}
