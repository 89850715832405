import {
    AfterContentChecked,
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import merge from "deepmerge";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";
import { ModalParameterInterface } from "../..";
import { ModalEventInterface } from "../../interface/modal-event.interface";
import { ModalController } from "../../model/modal-controller.model";
import { ModalEventType } from "../../model/modal-event-type.model";
import { Modal } from "../../model/modal.model";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements AfterViewInit, AfterContentChecked, OnDestroy {
    /**
     * contains the received modal parameter data
     */
    public data: ModalParameterInterface;

    /**
     * tracks if the modal is open or not
     */
    public isOpen: boolean = false;

    /**
     * modal ref
     */
    public bsModalRef: BsModalRef;

    /**
     * subsription for open events
     */
    protected modalSubscription: Subscription;

    /**
     * subsription for onHidden
     */
    protected onHiddenSubscription: Subscription;

    /**
     * modal template
     */
    @ViewChild("modal")
    protected modalTemplate: TemplateRef<any>;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        // save the data received for this modal
        this.data = this.modal.data;
    }

    /**
     * subscribe controller events
     */
    public ngAfterViewInit(): void {
        this.subscribeController();
    }

    /**
     * register changes
     */
    public ngAfterContentChecked(): void {}

    /**
     * unsubscrive events and send feedback
     */
    public ngOnDestroy(): void {
        this.modalController.sendToParent(ModalEventType.Destroyed);
        this.unsubscribe();
    }

    /**
     * overwrite to hook into opening process
     *
     * @param data
     */
    public onOpen(data?: any): void {}

    /**
     * overwrite to generate a callback
     * wich is fired after modal start
     * to close
     *
     * @param data
     */
    public onClose(data?: any): void {}

    /**
     * overwrite to generate a callback
     * wich is fired after modal is closed
     *
     * @param data
     */
    public onHidden(data?: any): void {}

    /**
     * opens the the modal and set the modal options
     */
    public open(data: any): void {
        if (this.isOpen) {
            return;
        }
        Promise.resolve(null).then(() => {
            this.isOpen = true;
            this.openBsModal(data);
        });
        // // open the modal
        // setTimeout(() => {
        //     this.isOpen = true;
        //     this.openBsModal(data);
        // }, 0)
    }

    /**
     * close the modal (with given delay if data.delay is set)
     *
     * @param data
     */
    public close(data?: any): void {
        // clode direct if no delay ist given
        if (!data?.delay) {
            this.closeBsModal(data);
            return;
        }
        // delay closing
        setTimeout(() => this.closeBsModal(data), data.delay);
    }

    /**
     * close the modal via the button,
     * so we can assign different actions
     * to button click, 'x' click or just
     * close
     *
     * @param data
     */
    public modalButton(data?: any): void {
        this.close(data);
    }

    /**
     * close the modal via the 'x' button,
     * so we can assign different actions
     * to button click, 'x' click or just
     * close
     *
     * @param data
     */
    public modalDismiss(data?: any): void {
        this.close(data);
    }

    /**
     * returns true if tp implementet ModalTextInterface
     *
     * @param tp
     * @returns
     */
    public isModalText(tp: any): boolean {
        return typeof tp === "object" && typeof tp.text === "string" && typeof tp.parameter === "object";
    }

    /**
     * returns true if text is a string
     *
     * @param text
     * @returns
     */
    public isText(text: any): boolean {
        return typeof text === "string";
    }

    /**
     * opens the bootstrap modal
     */
    protected openBsModal(data?: any): void {
        // get bsOptions and fix modal css classes
        let bsOptions = this.modal?.options?.bsOptions || {};
        bsOptions.class = this.modal?.options?.bsClassOverwrite
            ? // if overwrite we just set the options from bsOptions.class (and add the modal type)
              bsOptions.class + " " + this.modal.type
            : // set the default classes and extends them by the bsOptions.class and modal type
              "modal-dialog-centered " + (bsOptions.class || "") + " system " + this.modal.type;
        // fire onOpen and open the modal
        this.onOpen(data);
        this.bsModalRef = this.bsModalService.show(this.modalTemplate, bsOptions);
        this.modalController.sendToParent(ModalEventType.Open);
        this.onHiddenSubscription = this.bsModalRef.onHidden.subscribe(() => this.bsModalHidden());
    }

    /**
     * close the bootstrap modal
     */
    protected closeBsModal(data?: any): void {
        this.modalController.sendToParent(ModalEventType.Close);
        this.bsModalRef.hide();
        this.onClose(data);
    }

    /**
     * set isOpen to false if modal becomes hidden and fire "event"
     */
    protected bsModalHidden(data?: any): void {
        this.onHiddenSubscription.unsubscribe();
        this.modalController.sendToParent(ModalEventType.Closed);
        this.isOpen = false;
        this.onHidden(data);
    }

    /**
     * subscribe to all necessary events
     */
    protected subscribeController(): void {
        this.modalSubscription = this.modalController
            .onModalObservable()
            .subscribe((event: ModalEventInterface) => {
                this.handleModalEvent(event);
            });
    }

    /**
     * unsubscribe all subscriptions
     */
    protected unsubscribe(): void {
        this.modalSubscription.unsubscribe();
        this.onHiddenSubscription.unsubscribe();
    }

    /**
     * handles received modal event
     *
     * @param event
     */
    protected handleModalEvent(event: ModalEventInterface): void {
        switch (event.type) {
            case ModalEventType.Open:
                return this.open(event.data);

            case ModalEventType.Close:
                return this.close(event.data);
        }
    }

    /**
     * set fallback values for the modal parameter data
     *
     * @param fallback
     */
    protected setDataFallback(fallback: Partial<ModalParameterInterface>): Partial<ModalParameterInterface> {
        const merged = merge(fallback, this.data || {});
        this.data = merged;
        return merged;
    }
}
