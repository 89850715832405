import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CountryService } from '../../../../core/country';
import { HttpService } from '../../../../core/http';
import { MemberInterface, MemberPersonalInterface } from '../interface/member.interface';
import { MemberService } from './member.service';
import { SettingsService } from './settings.service';


/**
 * Basic Api Url for personal actions
 */
const apiPersonalUrl = `${environment.api.request}/member/personal`;

@Injectable({
    providedIn: 'root'
})
export class PersonalService {
    /**
     * member
     */
    private member: MemberInterface;

    /**
     * the current personal subject
     */
    private personalSubject: BehaviorSubject<MemberPersonalInterface>;

    /**
     * current loading state
     */
    private isLoading: boolean = false;


    /**
     * prepare the service
     * 
     * @param httpService 
     * @param memberService 
     * @param countryService 
     * @param settingsService 
     */
    constructor(
        private httpService: HttpService,
        private memberService: MemberService,
        private countryService: CountryService,
        private settingsService: SettingsService
    ) {
        // set personal subject
        this.personalSubject = new BehaviorSubject<MemberPersonalInterface>(null);
        // listen on member changes to remove the personal data
        this.memberService.getMemberObservable().pipe(tap((member: MemberInterface) => {
            if (member) { this.member = member; }
            else {
                this.personalSubject.next(null);
                this.member = null;
            }
        })).subscribe();
    }

    /**
     * returns an personal observable
     */
    public getPersonalObservable(): Observable<MemberPersonalInterface> {
        // start a new laoding request and return it
        if (!this.isLoading) {
            this.personalSubject.next(null);
            this.load();
        }
        // transform current personal into an observable and return it
        return this.personalSubject.asObservable().pipe(
            filter(personal => personal != null),
            first()
        );
    }

    /**
     * loads member personal data from api
     */
    private load() {
        this.isLoading = true;
        // lets create some prommises
        const promises: Promise<any>[] = [
            // load countries
            this.countryService.getCountries().toPromise(),
            // load settings
            this.settingsService.getSettingsObservable().toPromise(),
            // and finally load the personal data
            this.httpService.get<MemberPersonalInterface>(`${apiPersonalUrl}/` + this.member.memberId).pipe(
                tap((personal) => {
                    this.personalSubject.next(personal);
                    this.isLoading = false;
                })
            ).toPromise()
        ];
        Promise.all(promises);
    }

    /**
     * saves member personal at api
     */
    public save(data: MemberPersonalInterface): Observable<MemberPersonalInterface> {
        return this.httpService.post<MemberPersonalInterface>(`${apiPersonalUrl}/` + this.member.memberId, { update: data }).pipe(
            tap((personal: MemberPersonalInterface) => {
                this.personalSubject.next(personal);
            }));
    }
}
