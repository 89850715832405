import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { MemberInterface, MemberService } from '../../member';
import { BoosterActiveGroupInterface } from '../interface/booster-active.interface';
import { BoosterInventoryGroupInterface, BoosterInventoryInterface } from '../interface/booster-inventory.interface';
import { BoosterGroupInterface, BoosterTypeInterface } from '../interface/booster-type.interface';


/**
 * api url for booster requests
 */
const apiBoosterUrl = `${environment.api.request}/booster`;

@Injectable({
    providedIn: 'root'
})
export class BoosterService {
    /**
     * all booster types 
     */
    private boosterTypesSubject: BehaviorSubject<BoosterGroupInterface>;

    /**
     * active booster
     */
    private activeSubject: BehaviorSubject<BoosterActiveGroupInterface>;

    /**
     * booster in inventory
     */
    private inventorySubject: BehaviorSubject<BoosterInventoryGroupInterface>;

    /**
     * indicatates if boosters are loading from api
     */
    private isBoosterLoading: boolean = false;

    /**
     * indicates if active boosters are loading from api
     */
    private isActiveLoading: boolean = false;

    /**
     * indicates if inventory is loading from api
     */
    private isInventoryLoading: boolean = false;

    /**
     * note the last time we loaded the booster
     * types to the server and if its to old,
     * boostertypes become reloaded instead
     * of just returned on get/load
     */
    private lastBoosterTypeUpdate: number = 0;

    /**
     * defines how old the loaded boosters
     * can become (in millisecondsa) before
     * a reload is forced on get/load
     */
    private boosterTypeLifetime: number = 10000;


    /**
     * prepare the service
     * 
     * @param httpService 
     * @param memberService 
     */
    constructor(
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        this.boosterTypesSubject = new BehaviorSubject<BoosterGroupInterface>(null);
        this.activeSubject = new BehaviorSubject<BoosterActiveGroupInterface>(null);
        this.inventorySubject = new BehaviorSubject<BoosterInventoryGroupInterface>(null);
        this.loadBoosterTypes().subscribe({ error: (e) => { } });
        this.loadOnMemberChange();
    }


    public getBoosterTypesObservable(): Observable<BoosterGroupInterface> {
        return this.boosterTypesSubject.asObservable();
    }

    public getActiveObservable(): Observable<BoosterActiveGroupInterface> {
        return this.activeSubject.asObservable();
    }

    public getInventoryObservable(): Observable<BoosterInventoryGroupInterface> {
        return this.inventorySubject.asObservable();
    }



    /**
     * redeems a booster by type
     * 
     * @param boosterTypeId 
     */
    public redeem(boosterTypeId: number): Observable<BoosterTypeInterface> {
        return this.postToApi<BoosterTypeInterface>('/redeem', { boosterTypeId: boosterTypeId });
    }

    /**
     * returns true if the same booster type is allready active
     * 
     * @param boosterTypeId 
     * @returns 
     */
    public isBoosterActive(boosterTypeId: number): boolean {
        const active = this.activeSubject.value;
        if (!active) {
            return false;
        }
        for (const key of Object.keys(active)) {
            if (active[key]?.boosterType?.boosterTypeId === boosterTypeId) {
                return true;
            }
        }
        return false;
    }

    /**
     * returns true if the same category of booster type is allready active
     * 
     * @param boosterTypeId 
     * @returns 
     */
    public isCategoryActive(boosterTypeId: number): boolean {
        const boosterTypes = this.boosterTypesSubject.value;
        const active = this.activeSubject.value;
        if (!boosterTypes || !active) {
            return false;
        }
        // get category and check if there is any active booster
        // of this category
        const category = this.getBoosterTypeCategory(boosterTypeId);
        if (active[category] !== null) {
            return true;
        }
        return false;
    }

    /**
     * get known booster types (alias of loadBooster)
     */
    public getBoosterTypes(): Observable<BoosterGroupInterface> {
        return this.loadBoosterTypes();
    }

    /**
     * return the count of booster of given type in the current inventory
     * 
     * @param boosterTypeId
     */
    public getInventoryCount(boosterTypeId: number): number {
        const inventory = this.inventorySubject.value;
        if (!inventory) {
            return 0;
        }
        for (const key of Object.keys(inventory)) {
            const item: { [key: string]: any } = Object.values(inventory[key]).find((element: BoosterInventoryInterface) => {
                return element.boosterTypeId === boosterTypeId;
            });
            if (item) {
                return item.count;
            }
        }
        return 0;
    }

    /**
     * return the category of the boostertype
     * 
     * @param boosterTypeId
     */
    public getBoosterTypeCategory(boosterTypeId: number): string | null {
        const boosterTypes = this.boosterTypesSubject.value;
        if (!boosterTypes) {
            return null;
        }
        for (const key of Object.keys(boosterTypes)) {
            const item: { [key: string]: any } = Object.values(boosterTypes[key]).find((element: BoosterTypeInterface) => {
                return element.boosterTypeId === boosterTypeId;
            });
            if (item) {
                return key;
            }
        }
        return null;
    }

    /**
     * loads boster from api
     */
    public loadBoosterTypes(): Observable<BoosterGroupInterface> {
        if (!this.isBoosterLoading && (this.boosterTypesSubject.value === null || this.isBoosterTypesOutdated())) {
            this.isBoosterLoading = true;
            return this.loadBoosterDataFromApi<any>().pipe(
                // if some error occures, set loading to false
                // and return the error
                catchError((error) => {
                    this.isBoosterLoading = false;
                    return throwError(error);
                }),
                // handle received booster
                tap((booster: BoosterGroupInterface) => {
                    this.boosterTypesSubject.next(booster);
                    this.lastBoosterTypeUpdate = Date.now();
                    this.isBoosterLoading = false;
                })
            );
        }
        return this.getBoosterTypesObservable().pipe(filter(booster => booster != null), first());
    }

    /**
     * returns the booster type (from current boosters) by id
     * 
     * @param boosterTypeId 
     */
    public loadBoosterType(boosterTypeId: number): Observable<BoosterTypeInterface | null> {
        return this.loadBoosterTypes().pipe(switchMap((data: BoosterGroupInterface) => {
            const boosterType = this.getBoosterType(boosterTypeId);
            return of(boosterType);
        }));
    }

    /**
     * reloads member booster (inventory and active)
     */
    public reloadMemberBooster(): Observable<{ inventory: BoosterInventoryGroupInterface, active: BoosterActiveGroupInterface }> {
        return this.loadMemberBooster(true);
    }

    /**
     * loads member booster (inventory and active)
     * 
     * @param reload 
     */
    public loadMemberBooster(reload: boolean = false): Observable<{ inventory: BoosterInventoryGroupInterface, active: BoosterActiveGroupInterface }> {
        return this.loadIventory(reload).pipe(switchMap((inventory: BoosterInventoryGroupInterface) => {
            return this.loadActive(reload).pipe(switchMap((active: BoosterActiveGroupInterface) => {
                return of({ inventory: inventory, active: active });
            }));
        }));
    }

    /**
     * loads inventory from api
     * 
     * @param reload 
     */
    public loadIventory(reload: boolean = false): Observable<BoosterInventoryGroupInterface> {
        if (!this.isInventoryLoading && (reload || this.inventorySubject.value === null)) {
            this.isInventoryLoading = true;
            this.inventorySubject.next(null);
            return this.loadBoosterDataFromApi<BoosterInventoryGroupInterface>('/inventory').pipe(
                tap((booster: BoosterInventoryGroupInterface) => {
                    this.inventorySubject.next(booster);
                    this.isInventoryLoading = false;
                }));
        }
        return this.getInventoryObservable().pipe(filter(booster => booster != null), first());
    }

    /**
     * loads active booster from api
     */
    public loadActive(reload: boolean = false): Observable<BoosterActiveGroupInterface> {
        if (!this.isActiveLoading && (reload || this.activeSubject.value === null)) {
            this.isActiveLoading = true;
            this.activeSubject.next(null);
            return this.loadBoosterDataFromApi<BoosterActiveGroupInterface>('/active').pipe(
                tap((booster: BoosterActiveGroupInterface) => {
                    this.activeSubject.next(booster);
                    this.isActiveLoading = false;
                }));
        }
        return this.getActiveObservable().pipe(filter(booster => booster != null), first());
    }


    /**
     * returns the booster type (from current boosters) by id
     * 
     * @param boosterTypeId 
     */
    private getBoosterType(boosterTypeId: number): BoosterTypeInterface | null {
        const boosterTypes = this.boosterTypesSubject.value || {};
        for (const key in boosterTypes) {
            const item: { [key: string]: any } = Object.values(boosterTypes[key]).find((element: BoosterTypeInterface) => {
                return element.boosterTypeId === boosterTypeId;
            });
            if (item) {
                return <BoosterTypeInterface>item;
            }
        }
        return null;
    }

    /**
     * checks if the current booster type data
     * is outdated
     * 
     * @returns 
     */
    private isBoosterTypesOutdated(): boolean {
        const timePassed = Date.now() - this.lastBoosterTypeUpdate;
        return timePassed > this.boosterTypeLifetime;
    }

    /**
     * loads data from api
     * 
     * @param url 
     */
    private loadBoosterDataFromApi<Type>(url: string = ''): Observable<Type> {
        return this.httpService.get<Type>(`${apiBoosterUrl}` + url);
    }

    /**
     * post data to api
     * 
     * @param url 
     */
    private postToApi<Type>(url: string = '', data: {[key: string]: any}): Observable<Type> {
        return this.httpService.post<Type>(`${apiBoosterUrl}` + url, data);
    }

    /**
     * each time the member becomes loaded/reloaded we will also reload
     * the booster of the member
     */
    private loadOnMemberChange(): void {
        this.memberService.getMemberObservable().pipe(
            filter((member: MemberInterface) => member !== null)
        ).subscribe((data: MemberInterface) => {
            this.loadMemberBooster(true).subscribe();
        });
    }
}
