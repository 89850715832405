import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class SslService {
    constructor() {}

    /**
     * forces the connection to be
     * ssl (https) if we are not in
     * development mode
     *
     * @returns
     */
    public forceSSL(): Observable<void> {
        // no ssl and not in dev mode
        if (environment.forceSSL && !this.isSSL()) {
            this.setSSL();
        }
        // else, if dont need to redirect, we return
        // an observable so that the app can go on
        else {
            return of();
        }
    }

    /**
     * check if the client is connected
     * via ssl (https)
     *
     * @returns
     */
    public isSSL(): boolean {
        return location.protocol === "https:";
    }

    /**
     * set the url to https (ssl) and
     * loads the page again
     */
    public setSSL(): void {
        // force a reload with https instead of http
        window.location.href = location.href.replace("http", "https");
    }
}
