<ng-template #modal>
    <div class="modal-header">
        <h5 class="tigerfont">{{ 'account.bets.mode.SYSTEM' | translate }} {{ betSlip.systemOption }}/{{ betSlip.betCount }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="list-box my-bets shadow">

                    <div class="legend d-none d-xl-flex">
                        <div class="cell col-2 text-left">{{ 'account.bets.header.bet' | translate }}</div>
                        <div class="cell col-5 text-left">{{ 'account.bets.header.match' | translate }}</div>
                        <div class="options d-flex col-lg-4 p-0">
                            <div class="cell col-4 text-center">{{ 'account.bets.header.stake' | translate }}</div>
                            <div class="cell col-4 text-center">{{ 'account.bets.header.quote' | translate }}</div>
                            <div class="cell col-4 text-center">{{ 'account.bets.header.result' | translate }}</div>
                        </div>
                        <div class="cell col-1 text-center">{{ 'account.bets.header.state' | translate }}</div>
                    </div>


                    <div class="entry combination d-flex flex-column flex-lg-row" *ngFor="let combi of betSlip.systemBets | keyvalue" title="Ticket ID: {{ betSlip.ticketId }}">
                        <div class="cell bet col-md-12 col-lg-2">
                            <div class="bet-infos tigerfont">
                                <span>{{ 'account.bets.mode.COMBI' | translate }}</span>
                                <span class="bet-time">{{ betSlip.insertDate | todayDate | translate }}, {{ betSlip.insertDate | date: 'HH:mm:ss' }}</span>
                            </div>
                        </div>
                        <div class="cell encounter col-md-12 col-lg-5 text-left">
                            <div *ngFor="let betId of combi.value.outcomeIds | keyvalue" class="game" [class]="getBet(betId.value).status | lowercase">
                                <div class="time">{{ getBet(betId.value).lsportsEvent.startDate | date }} {{ getBet(betId.value).lsportsEvent.startDate | date: "HH:mm" }}</div>
                                <div class="opponents">{{ getBet(betId.value).lsportsEvent.homeTeamName }} - {{ getBet(betId.value).lsportsEvent.awayTeamName }}</div>
                                <div class="your-bet">
                                    {{ 'account.bets.tip' | translate }}: {{ 'bet.offer.'+getBet(betId.value).marketName+'.title' | translate }}: 
                                    {{ ('bet.offer.'+getBet(betId.value).marketName+'.detail.'+getBet(betId.value).lsportsBetOutcome.name | translate) === 'bet.offer.'+getBet(betId.value).marketName+'.detail.'+getBet(betId.value).lsportsBetOutcome.name
                                        ? getBet(betId.value).lsportsBetOutcome.name
                                        : ('bet.offer.'+getBet(betId.value).marketName+'.detail.'+getBet(betId.value).lsportsBetOutcome.name | translate)
                                    }} {{ getBet(betId.value).lsportsBetOutcome.line }}
                                </div>
                                <div class="result" *ngIf="isEventFinished(betId.value)">
                                    {{ 'account.bets.result' | translate }}: {{ getBet(betId.value).lsportsEvent.scoreboardResult?.final[0] || 0 }}:{{ getBet(betId.value).lsportsEvent.scoreboardResult?.final[1] || 0 }}
                                </div>                                
                            </div>
                        </div>


                        <div class="options d-flex col-lg-4">
                            <div class="cell bet-amount col-4 tigerfont">
                               <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.stake' | translate }}:</div>
                               <div class="coin-use">{{ betSlip.coinsPerBet | number: '1.0' }} <span>{{ 'coins' | translate }}</span></div>
                            </div>
                            <div class="cell quote col-4 tigerfont">
                               <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.quote' | translate }}:</div>
                                {{ combi.value.quote | number: '1.0' }}
                            </div>
                            <div class="cell result col-4 tigerfont">
                               <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.result' | translate }}:</div>
                               {{ combi.value.coins | number: '1.0' }} <span>{{ 'coins' | translate }}</span><br>
                               {{ combi.value.xp | number: '1.0' }} <span>{{ 'xp' | translate }}</span>
                               <div>
                                   <img src="/assets/images/booster-coins-gray-small.png" class="betBoosterImg m-2" *ngIf="betSlip.payoutBoost && betSlip.payoutBoost > 0" tooltip="{{ 'booster.coins.title' | translate }}: {{ betSlip.payoutBoost }}%" />
                                   <img src="/assets/images/booster-xp-gray-small.png" class="betBoosterImg m-2" *ngIf="betSlip.xpBoost && betSlip.xpBoost > 0" tooltip="{{ 'booster.xp.title' | translate }}: {{ betSlip.xpBoost }}%" />
                               </div>
                            </div>
                        </div>
                        <div *ngIf="getCombinationStatus(combi.key) === status.Pending" class="cell status col-md-4 col-lg-1 pending"><i class="fa fa-hourglass-half"></i></div>
                        <div *ngIf="getCombinationStatus(combi.key) === status.Lose" class="cell status col-md-4 col-lg-1 result-loss"><i class="fa fa-times"></i></div>
                        <div *ngIf="getCombinationStatus(combi.key) === status.Win" class="cell status col-md-4 col-lg-1 result-win"><i class="fa fa-check"></i></div>
                        <div *ngIf="getCombinationStatus(combi.key) === status.Void" class="cell status col-md-4 col-lg-1 result-void"><i class="fa fa-times"></i></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>