import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { PageListEmpty } from '..';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { BetSlipListInterface } from '../interface/bet-slip-list.interface';



/**
 * Basic Api Url for member bet requests
 */
const apiMemberBetSlipsUrl = `${environment.api.request}/member/betslips`;

@Injectable({
    providedIn: 'root'
})
export class BetSlipListService {
    /**
     * current loading state
     */
    private isLoading: boolean = false;

    /**
     * currently loaded bet slip list
     */
    private betSlipListSubject: BehaviorSubject<BetSlipListInterface>;

    /**
     * prepare the service
     * 
     * @param httpService 
     */
    constructor(
        private httpService: HttpService,
    ) {
        this.betSlipListSubject = new BehaviorSubject<BetSlipListInterface>(null);
    }

    /**
     * returns a bet slip list page as (onetime) observable
     * 
     * @param username 
     * @param page 
     * @returns 
     */
    public getPage(username: string, page: number, perPage?: number): Observable<BetSlipListInterface> {
        // if the page is not currently loading
        if (!this.isLoading) {
            page = (page <= 0) ? 1 : page;
            this.betSlipListSubject.next(null);
            return this.loadPage(username, page, perPage);
        }
        // if the page is currently loading jsut return the next
        // result as observable
        return this.betSlipListSubject.asObservable().pipe(
            filter(list => list != null), first()
        );
    }

    /**
     * clears the current list
     */
    public clear(): void {
        this.betSlipListSubject.next(null);
    }

    /**
     * loads the bet slip list from api
     */
    private loadPage(username: string, page: number, perPage?: number): Observable<BetSlipListInterface> {
        this.isLoading = true;
        const params = new HttpParams().set("page", '' + page).set('perPage',''+(perPage || 0));
        return this.httpService.get<BetSlipListInterface>(`${apiMemberBetSlipsUrl}/` + username, params, new PageListEmpty()).pipe(
            tap((list) => {
                this.betSlipListSubject.next(list);
                this.isLoading = false;
            }));
    }
}
