import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from 'ngx-pixel';
import { Observable, of } from 'rxjs';
import { PaymentTransactionInterface } from '../../../../tcp/core/payment';

@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    constructor(
        private router: Router,
        private googleTagManagerService: GoogleTagManagerService,
        private facebookPixelService: PixelService
    ) { }

    /**
     * starts automatic tracking
     * 
     * @returns 
     */
    public onAppInitialization(): Observable<boolean> {
        this.initalize();
        this.subscribePageView();
        return of(true);
    }

    /**
     * tracks a page view
     * 
     * @param page 
     */
    public trackPageView(page: string): void {
        this.trackGoogleTagManagerPageView(page);
        this.trackFacebookPixelPageView();
    }

    /**
     * tracks a conversion
     */
    public trackRegistration(): void {
        this.trackGoogleTagManagerRegistration();
        this.trackFacebookPixelRegistration();
    }

    /**
     * tracks a payment
     */
    public trackPayment(payment: PaymentTransactionInterface, additional?: {[key: string]: string | number}): void {
        this.trackGoogleTagManagerPayment(payment, additional);
        this.trackFacebookPixelPayment(payment);
    }

    /**
     * inits the tracker
     */
    private initalize(): void {
        this.initalizeGoogleTagManager();
        this.initalizeFacebookPixel();
    }

    /**
     * initalize google tag manager 
     */
    private initalizeGoogleTagManager(): void {
        return;
    }

    /**
     * initalize fcebook pixel
     */
    private initalizeFacebookPixel(): void {
        this.facebookPixelService.initialize();
    }

    /**
     * starts tracking by listen on router events
     */
    private subscribePageView(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const pageView = event.urlAfterRedirects;
                this.trackPageView(pageView);
            }
        });
    }

    /**
     * tracks a page view with google tag manager
     * 
     * @param page 
     * @returns 
     */
    private trackGoogleTagManagerPageView(page: string): void {
        this.pushGtag({
            event: 'Page View',
            page: page,
            data: null
        });
    }

    /**
     * tracks a page view via the facebook pixel
     */
    private trackFacebookPixelPageView(): void {
        this.facebookPixelService.track('PageView');
    }

    /**
     * tracks a conversion via google tag manager
     */
    private trackGoogleTagManagerRegistration(): void {
        this.pushGtag({
            event: 'Registration',
            data: null
            //send_to: 'AW-717261497/n0fWCLPCqLoBELmVgtYC'
        });
    }

    /**
     * tracks a conversion with the facebook pixel
     */
    private trackFacebookPixelRegistration(): void {
        this.facebookPixelService.track('CompleteRegistration');
    }

    /**
     * tracks a payment with the google tag manager
     * 
     * @param payment 
     * @param additional 
     */
    private trackGoogleTagManagerPayment(payment: PaymentTransactionInterface, additional?: {[key: string]: string | number}): void {
        // create gTag additional parameter
        const gTagData = {
            ...(additional || {}),
            value: payment.price,
            currency: payment.currency,
            transaction_id: payment.transactionId            
        }
        // lead tracking
        if(payment.isFirst){
            this.pushGtag({
                event: 'Lead', data: gTagData
            });
        }
        // payment tracking
        this.pushGtag({
            event: 'Purchase', data: gTagData
        });
    }

    /**
     * tracks a payment via facebook pixel
     * 
     * @param payment 
     */
    private trackFacebookPixelPayment(payment: PaymentTransactionInterface): void {
        // lead tracking
        if(payment.isFirst){
            this.facebookPixelService.track('Lead')
        }
        // payment tracking
        this.facebookPixelService.track('Purchase', {
            value: payment.price,
            currency: <any>payment.currency,
        });
    }

    /**
     * push a new gtag and ignore errors
     * 
     * @param gTag 
     */
    private async pushGtag(gTag: { event: string, [key: string]: any }): Promise<void> {
        try{
            // this could fail because of adblock, etc
            await this.googleTagManagerService.pushTag(gTag);
        }catch(e){}
    }
}
