<ng-template #modal>
    <div class="modal-header" [class]="data.headerClass">
        <button type="button" class="close" [class]="data.headerClass" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <span class="tiger-sprite huge-icon rank-1"></span>
    <div class="modal-body pb-5">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                <h5>
                    <ng-container *ngIf="isModalText(data.message); else elseMessageTranslation">{{ data.message.text | translate: data.message.parameter }}</ng-container>
                    <ng-template #elseMessageTranslation>{{ data.message | translate }}</ng-template>
                </h5>
                <p *ngIf="data.note">
                    <ng-container *ngIf="isModalText(data.note); else elseNoteTranslation">{{ data.note.text | translate: data.note.parameter }}</ng-container>
                    <ng-template #elseNoteTranslation>{{ data.note | translate }}</ng-template>
                </p>
            </div>
            <div class="col-12 col-sm-11 mx-auto" *ngIf="!data.hideButtons">
                <div class="row">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-info btn-block mx-auto text-uppercase tigerfont mt-4" (click)="modalButtonConfirm()">
                            <ng-container *ngIf="isModalText(data.buttonConfirm); else elseButtonConfirmTranslation">{{ data.buttonConfirm.text | translate: data.buttonConfirm.parameter }}</ng-container>
                            <ng-template #elseButtonConfirmTranslation>{{ data.buttonConfirm | translate }}</ng-template>
                        </button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-secondary btn-block mx-auto text-uppercase tigerfont mt-4" (click)="modalButtonCancel()">
                            <ng-container *ngIf="isModalText(data.buttonCancel); else elseButtonCancelTranslation">{{ data.buttonCancel.text | translate: data.buttonCancel.parameter }}</ng-container>
                            <ng-template #elseButtonCancelTranslation>{{ data.buttonCancel | translate }}</ng-template>
                        </button>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</ng-template>