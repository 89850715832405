import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { HttpService } from "src/app/core/http";
import { environment } from "src/environments/environment";
import { BettingGameParticipationInterface } from "../interface/betting-game-participation.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame/participation`;

/**
 * just to make the subject object work, to define the keys
 * as normal string (as done before) will end up in errors
 * while trying to pipe the subjects observeable
 */
enum SubjectTypeEnum {
    Participation = "participation",
    List = "list",
}

@Injectable({
    providedIn: "root",
})
export class BettingGameParticipationService {
    /**
     * the current subjects for the list and single participation
     */
    private subject: {
        [key in SubjectTypeEnum]: BehaviorSubject<
            BettingGameParticipationInterface | BettingGameParticipationInterface[]
        >;
    };

    /**
     * current loading states for the subjects
     */
    private isLoading: {
        [key in SubjectTypeEnum]: boolean;
    };

    /**
     * prepare the service
     */
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {
        this.subject = {
            [SubjectTypeEnum.List]: new BehaviorSubject<BettingGameParticipationInterface[]>(null),
            [SubjectTypeEnum.Participation]: new BehaviorSubject<BettingGameParticipationInterface>(null),
        };
        this.isLoading = {
            [SubjectTypeEnum.List]: false,
            [SubjectTypeEnum.Participation]: false,
        };
    }

    /**
     * It disconnects a member from a betting game.
     *
     * @param {string} uuid - The unique identifier of the betting game
     * @param {number} memberId - The id of the member that is disconnecting from the game.
     * @returns BettingGameParticipationInterface
     */
    public diconnect(uuid: string, memberId: number): Observable<BettingGameParticipationInterface> {
        const url = apiUrl + "/disconnect/" + uuid + "/" + memberId;
        return this.getApiRequest<BettingGameParticipationInterface>(url);
    }

    /**
     * It returns an observable of an array of BettingGameParticipationInterface objects.
     *
     * @param {string} uuid - The uuid of the betting game
     * @returns Observable<BettingGameParticipationInterface[]>
     */
    public loadList(uuid: string): Observable<BettingGameParticipationInterface[]> {
        const url = apiUrl + "/list/" + uuid;
        return <Observable<BettingGameParticipationInterface[]>>this.getData(SubjectTypeEnum.List, url);
    }

    /**
     * It returns an observable of type BettingGameParticipationInterface
     *
     * @param {string} uuid - The uuid of the betting game
     * @param {number} memberId - The id of the member
     * @returns Observable<BettingGameParticipationInterface>
     */
    public loadParticipation(uuid: string, memberId: number): Observable<BettingGameParticipationInterface> {
        const url = apiUrl + "/member/" + uuid + "/" + memberId;
        return <Observable<BettingGameParticipationInterface>>(
            this.getData(SubjectTypeEnum.Participation, url)
        );
    }

    /**
     * It clears all subjects
     */
    public clear(): void {
        this.clearParticipation();
        this.clearList();
    }

    /**
     * It clears the participation object in the subject
     */
    public clearParticipation(): void {
        this.subject.participation.next(null);
    }

    /**
     * The function clears the list by setting the list to null
     */
    public clearList(): void {
        this.subject.list.next(null);
    }

    /**
     * It returns an observable that emits the data of the given type, and if the data is not
     * loading, it starts a new loading request
     *
     * @param {SubjectTypeEnum} type - SubjectTypeEnum - The kind of data to load
     * @param {string} url - the url to fetch the data from
     * @returns An observable of the data.
     */
    private getData(
        type: SubjectTypeEnum,
        url: string
    ): Observable<BettingGameParticipationInterface | BettingGameParticipationInterface[]> {
        // start a new laoding request
        if (!this.isLoading[type]) {
            this.subject[type].next(null);
            this.loadSubject(type, url);
        }
        // transform current subject into an observable and return it
        return this.subject[type].asObservable().pipe(
            filter((data: any) => data !== null),
            first()
        );
    }

    /**
     * It loads data from a given url and puts it into a subject of a given type
     *
     * @param {SubjectTypeEnum} type - SubjectTypeEnum - The kind of data to load
     * @param {string} url - The url to fetch the data from.
     */
    private loadSubject(type: SubjectTypeEnum, url: string): void {
        this.isLoading[type] = true;
        this.httpService
            .get<BettingGameParticipationInterface | BettingGameParticipationInterface[]>(url)
            .pipe(
                first(),
                tap((data: any) => {
                    this.subject[type].next(data);
                    this.isLoading[type] = false;
                })
            )
            .subscribe();
    }

    /**
     * It returns an observable of type T, which is the type of the data returned from the API
     *
     * @param {string} url - The url to make the request to.
     * @returns Observable<T>
     */
    private getApiRequest<T>(url: string): Observable<T> {
        return this.httpService.get<T>(url).pipe(first());
    }
}
