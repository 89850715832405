import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-bet-wins",
    templateUrl: "./modal-bet-wins.component.html",
    styleUrls: ["./modal-bet-wins.component.scss"],
})
export class ModalBetWinsComponent extends ModalComponent {
    /**
     * a short summery of all won bets
     */
    public betSlipSummaries: Array<{ betSlipId: number; coinsWin: number; xpWin: number }>;

    /**
     * all betSlipIds as flat array
     */
    public betSlipIds: Array<number>;

    /**
     * won coins in total
     */
    public coins: number;

    /**
     * won xp in total
     */
    public xp: number;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected memberService: MemberService,
        protected translationService: TranslationService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ betSlipSummaries: [] });
        this.setBetSlipData();
        this.setWinAmounts();
    }

    /**
     * reload the member on win open
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    }

    /**
     * overwrite modal button to
     * inject a redirect option
     */
    public modalButton(): void {
        super.modalButton();
        this.modalController.getModalService().openBetSlipsDetail(this.betSlipIds, "win");
    }

    /**
     * sets the betSlip summaries and the betSlipIds
     */
    private setBetSlipData(): void {
        this.betSlipSummaries = this.data.betSlipSummaries;
        this.betSlipIds = this.betSlipSummaries.map((summary) => summary.betSlipId);
    }

    /**
     * add up the total xp and coins
     */
    private setWinAmounts(): void {
        this.xp = 0;
        this.coins = 0;
        for (const summary of this.betSlipSummaries) {
            this.coins += summary.coinsWin;
            this.xp += summary.xpWin;
        }
    }
}
