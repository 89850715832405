import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { BettingGameLeagueSelectInterface } from "../interface/betting-game-league-select.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame/leagues`;

@Injectable({
    providedIn: "root",
})
export class BettingGameLeagueService {
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * It returns an observable of type BettingGameLeagueSelectInterface
     *
     * @returns An observable of type BettingGameLeagueSelectInterface
     */
    public getSelectable(): Observable<BettingGameLeagueSelectInterface> {
        return this.httpService.get<BettingGameLeagueSelectInterface>(apiUrl);
    }
}
