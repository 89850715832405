import { ModalParameterInterface, ModalTextInterface } from "..";
import { BoosterTypeInterface } from "../../../core/booster";
import { BetSlipDetailInterface } from "../../../core/member";
import { CoinPackageInterface } from "../../../core/shop";
import { MobileComponent as BetSlipMobileComponent } from "../../../page/bet/betslip/mobile/mobile.component";
import { CreationComponent as BettingGameCreationComponent } from "../../../page/betting-game/dashboard/includes/creation/creation.component";
import { ModalComponent as BettingGameInvitationComponent } from "../../../page/betting-game/game/includes/invitation/modal/modal.component";
import { ModalOptionsInterface } from "../interface/modal-options.interface";
import { ModalInterface } from "../interface/modal.interface";
import { ModalType } from "./modal-type.model";

/**
 * just an undefined, generic modal
 */
export class Modal implements ModalInterface {
    public type: ModalType;
    public data?: any;
    public options?: ModalOptionsInterface;

    public constructor(type: ModalType, data?: any, options?: ModalOptionsInterface) {
        this.type = type;
        this.data = data;
        this.options = options;
    }
}

/**
 * Default Modal
 */
export class ModalDefault implements ModalInterface {
    public type = ModalType.Default;
    public data: { [key: string]: any };
    public options?: ModalOptionsInterface;

    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior?: "static" | "multi" | "multi-static"
    ) {
        this.data = parameter;
        this.options = { ...(options || {}), behavior: behavior || options?.behavior || "multi" };
    }
}

/**
 * Success Modal
 */
export class ModalSuccess extends ModalDefault {
    public type = ModalType.Success;
}

/**
 * Error Modal
 */
export class ModalError extends ModalDefault {
    public type = ModalType.Error;
}

/**
 * Confirm Modal
 */
export class ModalConfirm implements ModalInterface {
    public type = ModalType.Confirm;
    public data: { [key: string]: any };
    public options?: ModalOptionsInterface;

    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior: "static" | "multi" | "multi-static" = "multi"
    ) {
        this.data = parameter;
        this.options = {
            ...(options || {}),
            behavior: behavior,
            bsOptions: {
                ignoreBackdropClick: true,
                keyboard: false,
            },
        };
    }
}

/**
 * Login Modal
 */
export class ModalLogin extends ModalDefault {
    public type = ModalType.Login;

    /**
     * remove content parameter and change
     * the behavior option to 'static'
     */
    public constructor(options?: ModalOptionsInterface) {
        super(
            {},
            {
                ...options,
                bsOptions: {
                    ignoreBackdropClick: true,
                    keyboard: false,
                },
            },
            "static"
        );
    }
}

/**
 * Register Modal
 */
export class ModalRegister extends ModalLogin {
    public type = ModalType.Register;
}

/**
 * Password Reset Modal
 */
export class ModalPasswordResetReset extends ModalDefault {
    public type = ModalType.PasswordReset;

    /**
     * remove content parameter and change
     * the behavior option to 'static'
     */
    public constructor(options?: ModalOptionsInterface) {
        super({}, options, "static");
    }
}

/**
 * Tutorial Modal
 */
export class ModalTutorial extends ModalDefault {
    public type = ModalType.Tutorial;

    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior: "static" | "multi" | "multi-static" = "multi"
    ) {
        super(parameter, options, behavior);
        this.options = {
            ...(options || {}),
            behavior: "static",
            closeSame: true,
            bsOptions: {
                ignoreBackdropClick: true,
                keyboard: false,
            },
        };
    }
}

/**
 * Task Complete Modal (achievement / daily mission)
 */
export class ModalTaskComplete extends ModalDefault {
    public type = ModalType.TaskComplete;

    public constructor(
        parameter?: ModalParameterInterface,
        taskType: "achievement" | "arena" = "achievement"
    ) {
        super(parameter, {}, "multi");
        this.options = {
            behavior: "multi",
            bsOptions: {
                class: taskType,
            },
        };
    }
}

/**
 * Bet Win modal
 */
export class ModalBetWins extends ModalDefault {
    public type = ModalType.BetsWin;

    public constructor(
        betSlipSummaries: Array<{ betSlipId: number; coinsWin: number; xpWin: number }>,
        options?: ModalOptionsInterface
    ) {
        super({ betSlipSummaries: betSlipSummaries }, options);
        this.options = {
            ...(options || {}),
            behavior: "multi",
            bsOptions: {
                class: "modal-md won",
            },
        };
    }
}

/**
 * BetSlip best of modal
 */
export class ModalBetSlipBestOf extends ModalDefault {
    public type = ModalType.BetSlipBestOf;

    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior: "static" | "multi" | "multi-static" = "multi"
    ) {
        super(parameter, options, behavior);
        this.options = {
            ...(options || {}),
            behavior: behavior,
            bsOptions: {
                class: "arena",
            },
        };
    }
}

/**
 * season ranking modal
 */
export class ModalSeasonRanking extends ModalDefault {
    public type = ModalType.SeasonRanking;

    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior: "static" | "multi" | "multi-static" = "multi"
    ) {
        super(parameter, options, behavior);
        this.options = {
            ...(options || {}),
            behavior: behavior,
            bsOptions: {
                class: "arena",
            },
        };
    }
}

/**
 * season ranking modal
 */
export class ModalSeasonWinLadder extends ModalDefault {
    public type = ModalType.SeasonWinLadder;
    public constructor(
        parameter?: ModalParameterInterface,
        options?: ModalOptionsInterface,
        behavior: "static" | "multi" | "multi-static" = "multi"
    ) {
        super(parameter, options, behavior);
        this.options = {
            ...(options || {}),
            behavior: behavior,
            bsOptions: {
                class: "league " + (parameter?.result ? parameter.result : ""),
            },
        };
    }
}

/**
 * Payment Modal, which will load the
 * external payment url into an iFrame
 * and close all other payment modals.
 */
export class ModalPayment implements ModalInterface {
    public type = ModalType.Payment;
    public options?: ModalOptionsInterface;
    public data: { offer?: CoinPackageInterface | BoosterTypeInterface };

    public constructor(offer?: CoinPackageInterface | BoosterTypeInterface, options?: ModalOptionsInterface) {
        this.data = { offer: offer };
        this.options = {
            ...(options || {}),
            behavior: "multi",
            closeSame: true,
            bsOptions: {
                ignoreBackdropClick: true,
                keyboard: false,
            },
        };
    }
}

/**
 * Opens the current betslip (where u can add or remove bets from)
 */
export class ModalBetSlip extends ModalDefault {
    public type = ModalType.BetSlip;
    public constructor() {
        super(
            {
                component: BetSlipMobileComponent,
            },
            {
                openUniqe: true,
                bsClassOverwrite: true,
                bsOptions: {
                    class: "modal-dialog-slideout mobile-betslip",
                    ignoreBackdropClick: true,
                    keyboard: false,
                },
            }
        );
    }
}

/**
 * Details of the betslip or betslips (all bets)
 */
export class ModalBetSlipDetail implements ModalInterface {
    public type = ModalType.BetSlipDetail;
    public options?: ModalOptionsInterface;
    public data: { modalStatus: "win" | "lose" | "refund" | "mixed"; betSlipIds: number[] };

    public constructor(
        betSlipIds: number | number[],
        status: "win" | "lose" | "refund" | "mixed" = "mixed",
        options?: ModalOptionsInterface
    ) {
        const betSlipIdsArray = typeof betSlipIds === "number" ? [betSlipIds] : betSlipIds;
        this.data = { betSlipIds: betSlipIdsArray, modalStatus: status };
        this.options = {
            ...(options || {}),
            behavior: "multi",
            bsOptions: {
                class: "modal-xl betslip",
            },
        };
    }
}

/**
 * System Bet Modal, which will load the bet slip
 * and all system combi bets
 */
export class ModalSystemBet implements ModalInterface {
    public type = ModalType.SystemBet;
    public options?: ModalOptionsInterface;
    public data: { betSlip: BetSlipDetailInterface };

    public constructor(betSlip: BetSlipDetailInterface, options?: ModalOptionsInterface) {
        this.data = { betSlip: betSlip };
        this.options = {
            ...(options || {}),
            behavior: "multi",
            closeSame: true,
            bsOptions: {
                class: "modal-xl betslip",
            },
        };
    }
}

/**
 * VideoAd Modal, which will load the
 * external video ad url into an iFrame
 * and close all other Video Ad modals.
 */
export class ModalVideoAds implements ModalInterface {
    public type = ModalType.VideoAds;
    public options?: ModalOptionsInterface;

    public constructor(options?: ModalOptionsInterface) {
        this.options = {
            ...(options || {}),
            behavior: "multi",
            closeSame: true,
            bsOptions: {
                ignoreBackdropClick: true,
                keyboard: false,
            },
        };
    }
}

/**
 * Used to create new betting gammes
 */
export class ModalBettingGameCreation extends ModalDefault {
    public type = ModalType.InjectComponent;
    public constructor(parameter?: ModalParameterInterface) {
        super(
            {
                ...parameter,
                component: BettingGameCreationComponent,
            },
            {
                behavior: "multi",
                closeSame: true,
                bsOptions: {
                    class: "create",
                    ignoreBackdropClick: true,
                    keyboard: false,
                },
            }
        );
    }
}

/**
 * Used to invite a someone to a betting game
 */
export class ModalBettingGameInvitation extends ModalDefault {
    public type = ModalType.InjectComponent;
    public constructor(parameter?: ModalParameterInterface) {
        super(
            {
                ...parameter,
                component: BettingGameInvitationComponent,
            },
            {
                behavior: "multi",
                closeSame: true,
                bsOptions: {
                    class: "invite",
                    ignoreBackdropClick: true,
                    keyboard: false,
                },
            }
        );
    }
}

/**
 * Loading Modal, which will overlay the page and
 * shows a loading animation and the message. If
 * the message is not the loading animation is
 * centered and no text shown.
 */
export class ModalLoading extends ModalDefault {
    public type = ModalType.Loading;
    public options?: ModalOptionsInterface;

    public constructor(message?: string | ModalTextInterface, options?: ModalOptionsInterface) {
        super({ message: message }, {});
        this.options = {
            ...(options || {}),
            closeSame: true,
            behavior: "multi",
            bsOptions: {
                class: "justify-content-center",
                ignoreBackdropClick: true,
                keyboard: false,
            },
        };
    }
}
