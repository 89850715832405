import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { PaymentTransactionInterface } from "../../../../../tcp/core/payment";
import { LabelService } from "../../../../core/label";
import { MemberService } from "../../../../core/member";
import { PaymentService } from "../../../../core/shop";
import { TrackingService } from "../../../../core/tracking";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-payment",
    templateUrl: "./modal-payment.component.html",
    styleUrls: ["./modal-payment.component.scss"],
})
export class ModalPaymentComponent extends ModalComponent {
    /**
     * indicates the iFrame loading status
     */
    public isLoading = true;

    /**
     * indicate if the payment is complete
     */
    public isComplete = false;

    /**
     * url for the payment iFrame
     */
    public paymentUrl: string;

    /**
     * inject dependencies
     *
     * @param paymentService
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected trackingService: TrackingService,
        protected labelService: LabelService,
        protected memberService: MemberService,
        protected paymentService: PaymentService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        // sets a default payment id, so that the value is not empty
        // and loads the payment url from the api
        this.setDataFallback({ offer: { paymentOffer: { paymentOfferId: 0 } } });
        this.getPaymentUrl(this.data.offer.paymentOffer.paymentOfferId);
        // setTimeout(() => {
        //     this.isComplete = true;
        //     //this.paymentUrl = "http://localhost/shop/success?transactionID=BC1B0-5A0BD-34D22-08CAB";
        // }, 2000);
    }

    /**
     * true if we loading the payment iframe url
     *
     * @returns
     */
    public isPaymentLoading(): boolean {
        return (!this.paymentUrl || this.isLoading) && !this.isComplete;
    }

    /**
     * true if the payment is completed
     *
     * @returns
     */
    public isPaymentComplete(): boolean {
        return !this.isLoading && this.isComplete;
    }

    /**
     * true if the payment iFrame is ready so
     * we can show it
     *
     * @returns
     */
    public isPaymentReady(): boolean {
        return !this.isPaymentLoading() && !this.isPaymentComplete();
    }

    /**
     * checks if the current offer is a coinpackage
     *
     * @returns
     */
    public isCoinPackage(): boolean {
        return this.data.offer.coinPackageId ? true : false;
    }

    /**
     * checks if the current offer is a booster
     *
     * @returns
     */
    public isBooster(): boolean {
        return this.data.offer.boosterTypeId ? true : false;
    }

    /**
     * true if its a xp booster
     *
     * @returns
     */
    public isBoosterXp(): boolean {
        return this.data.offer.category === "XP";
    }

    /**
     * true if its a coin booster
     *
     * @returns
     */
    public isBoosterCoins(): boolean {
        return this.data.offer.category === "COINS";
    }

    /**
     * listen on the iFrame load event to handle
     * the loading and detect complete state
     *
     * @param iFrame
     */
    public onSrcLoad(iFrame: HTMLIFrameElement): void {
        // try to get iFrame document url and compare it with
        // expected url and parameters for a successfull payment
        try {
            const hostname = this.labelService.getLabelSnapshot().hostname;
            const iFrameContentUrl = iFrame.contentWindow.document.URL;
            const url = new URL(iFrameContentUrl);
            // check we use the same hostname as the iFrame and if not we are not ready
            if (!url.hostname.toLocaleLowerCase().includes(hostname.toLocaleLowerCase())) {
                return;
            }
            // check for success in the path
            // @Note: With this we could switch between success and error pages
            // but at the moment we only have a success page
            if (!url.pathname.toLocaleLowerCase().includes("success")) {
                return;
            }
            // lets check the query parameter for the transactionId
            const transactionId = url.searchParams.get("transactionID");
            if (!transactionId) {
                return;
            }
            // transaction seem to be complete, so we can try to validate and track that
            this.onPaymentComplete(transactionId);
        } catch (e) {
            // just catch any errors above, because "iFrame.contentWindow.document.URL" will
            // fail until the success page is shown
        }

        // set the loading state to false wich will show the iFrame
        if (this.isLoading) {
            Promise.resolve(null).then(() => (this.isLoading = false));
        }
    }

    /**
     * returns css classes depending on
     * the current payment state
     *
     * @returns
     */
    public getIFrameClasses(): string[] {
        const cssClasses: string[] = [];
        // if(!this.isComplete){
        //     cssClasses.push('mt-5');
        // }
        if (this.isLoading || this.isComplete) {
            cssClasses.push("hidden");
        }
        return cssClasses;
    }

    /**
     * get payment url for the iFrame
     *
     * @param paymentOfferId
     */
    private getPaymentUrl(paymentOfferId: number): void {
        this.paymentService.getPaymentUrl(paymentOfferId).subscribe((url: string) => {
            this.paymentUrl = url;
        });
    }

    /**
     * if the payment is complete we check the transaction and handle in the way
     * the check results. If the check failed an error modal opens up else we
     * refresh the member, show the success page and track the payment
     *
     * @param transactionId
     */
    private onPaymentComplete(transactionId: string): void {
        // get all information about the payment, to check if it exists and track it
        this.paymentService
            .getPaymentTransaction(transactionId)
            .subscribe((payment: PaymentTransactionInterface) => {
                // match the transactionId (this can also fail bause there is not payment found by the api)
                if (payment?.transactionId === transactionId) {
                    // update the complete status, reload the member and track the payment
                    this.setPaymentSuccessful(payment);
                }
                // validation error because the transaction id does not match
                else {
                    this.modalController.getModalService().openError();
                    this.close();
                }
            });
    }

    /**
     * handles a successful payment by changing the modal dialog css class
     * reload the member and also show the bought item
     *
     * @param payment
     */
    private setPaymentSuccessful(payment: PaymentTransactionInterface): void {
        // get the modal dialog and change it to success
        const modal = <HTMLElement>document.getElementById("payment-modal-header").parentNode.parentNode;
        if (modal.classList.contains("payment")) {
            modal.classList.add("success");
        }
        // set is complete, reload the member and track the payment
        Promise.resolve(null).then(() => (this.isComplete = true));
        //this.memberService.reload().subscribe();
        this.trackPayment(payment);
    }

    /**
     * tracks the payment and creates additinal tacking data
     *
     * @param payment
     */
    private trackPayment(payment: PaymentTransactionInterface): void {
        const additional: { [key: string]: string | number } = {};
        // set additional data for coin packages
        if (this.isCoinPackage()) {
            additional.type = "Coins";
            additional.coins = this.data.offer.coins;
        }
        // set additional data for booster packages
        if (this.isBooster()) {
            additional.type = "Booster";
            additional.category = this.data.offer.category;
            additional.percentage = this.data.offer.percentage;
            additional.duration = this.data.offer.duration;
        }
        // set the tracking data
        this.trackingService.trackPayment(payment, additional);
    }
}
