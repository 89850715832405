import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, filter, first, switchMap } from "rxjs/operators";
import { StatusCodes } from "http-status-codes";
import { ConnectionService } from "../service/connection.service";

@Injectable()
export class ConnectionInterceptor implements HttpInterceptor {
    public constructor(
        // inject dependencies
        private connectionService: ConnectionService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // handle the current request and pipe errors
        return next.handle(request).pipe(
            catchError((error) => {
                // handles the error
                this.handleError(error);
                // throw the error again, so other interceptors
                // or services can hadle it
                return throwError(error);
            })
        );
    }

    /**
     * handles the catched error
     *
     * @param error
     */
    private handleError(error: any): void {
        // only handle the error if its is an HttpErrorResonse
        // other errors will be just thrown again
        if (error instanceof HttpErrorResponse) {
            // if we enconter some of the following
            // status codes as error we let the
            // connection service handle the error
            switch (error.status) {
                // Error 40x
                //case StatusCodes.UNPROCESSABLE_ENTITY:

                // Error 50x
                // case StatusCodes.INTERNAL_SERVER_ERROR:
                // case StatusCodes.NOT_IMPLEMENTED:
                case StatusCodes.BAD_GATEWAY:
                case StatusCodes.SERVICE_UNAVAILABLE:
                case StatusCodes.GATEWAY_TIMEOUT:
                    this.connectionService.setStatus(false);
                    break;
            }
        }
    }
}
