import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subscription } from "rxjs";
import { filter, finalize, first } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { environment } from "../../../../environments/environment";
import { doOnSubscribe } from "../../../shared/rxjs";
import { MemberService } from "../../member";
import { AuthenticationInterface, AuthenticationService } from "../../security";
import { WebSocketDataEventInterface } from "../interface/web-socket-data-event.interface";
import { WebSocketEventEnum } from "../model/web-socket-event.enum";

@Injectable({
    providedIn: "root",
})
export class WebSocketService {
    /**
     * maximum connection retry (each try is delayed
     * for a second, so 60 reconnects would take (min)
     * 1 minute)
     */
    private readonly retryMax = 1800;

    /**
     * how often we tried to reconnect
     */
    private retryCounter: number;

    /**
     * indicates if the service should retry
     * the connection on lost. In some cases
     * its not the expected behavior. For
     * example if we tried to close the
     * connection before.
     */
    private tryToReconnect: boolean;

    /**
     * url to connect to
     */
    private webSocketUrl: string;

    /**
     * protocol to connect with
     */
    private webSocketProtocol: string;

    /**
     * the web socket data subscription
     */
    private webSocketSubscription: Subscription;

    /**
     * the web socket connection subject
     */
    private webSocketSubject: WebSocketSubject<unknown>;

    /**
     * contains the last received data
     */
    private dataSubject: BehaviorSubject<WebSocketDataEventInterface>;

    /**
     * number of active subcriber for the dataSubject
     * as observable
     */
    private dataSubscriberCount: number;

    /**
     * indicates if the web socket is connecting
     * or is connected
     */
    private isConnected: boolean;

    /**
     * indicates if the web socket is authenticated
     * or not
     */
    private isAuthenticated: boolean;

    public constructor(
        // inject dependencies
        private authenticationServive: AuthenticationService,
        private memberService: MemberService
    ) {
        this.configure();
        this.createSubject();
    }

    /**
     * The function initializes the WebSocket connection and starts to listen for the heartbeat.
     *
     * @returns Observable<boolean>
     */
    public async initialize(): Promise<void> {
        // start to listen for the heartbeat (thats nessesary, to avoid that we close the connection)
        this.getDataObservable(WebSocketEventEnum.Heartbeat).subscribe(
            (event: WebSocketDataEventInterface) => {}
        );
        // observe the member to handle the WebSocket authentication
        this.memberService
            .getMemberObservable()
            .subscribe((authentication: Partial<AuthenticationInterface>) =>
                authentication ? this.clientAuthentication() : this.clientRevokeAuthentication()
            );
    }

    /**
     * returns a observable for received web socket data,
     * which can be filtered for certain events. If nothing
     * passed to event, then no event filter will be applied
     *
     * @param events
     */
    public getDataObservable(events?: string | string[]): Observable<WebSocketDataEventInterface> {
        const dataObservable = this.dataSubject.pipe(
            filter((data: WebSocketDataEventInterface) => data !== null),
            doOnSubscribe(() => this.onDataSubscription()),
            finalize(() => this.onDataUnsubscription())
        );
        // if events no passed events we can return the observable
        if (!Array.isArray(events) && typeof events !== "string") {
            return dataObservable;
        }
        // append the event filter and return the observable
        const eventFilter = !Array.isArray(events) ? [events] : events;
        return dataObservable.pipe(
            filter((data: WebSocketDataEventInterface) => eventFilter.includes(data.event))
        );
    }

    /**
     * sets the inital variable values
     */
    private configure(): void {
        this.dataSubject = new BehaviorSubject<WebSocketDataEventInterface>(null);
        this.webSocketUrl = environment.websocket.url;
        this.webSocketProtocol = environment.websocket.protocol;
        this.isConnected = false;
        this.isAuthenticated = false;
        this.tryToReconnect = true;
        this.retryCounter = 0;
        this.dataSubscriberCount = 0;
    }

    /**
     * create the WebSocketSubject
     */
    private createSubject(): void {
        this.webSocketSubject = webSocket({
            url: this.webSocketUrl,
            protocol: this.webSocketProtocol,
        });
    }

    /**
     * opens a websocket connection and start to listen on messages (data)
     */
    private openConnection(): void {
        // mark connection as open
        this.isConnected = true;
        // open connection
        this.webSocketSubscription = this.webSocketSubject.asObservable().subscribe({
            error: (error) => this.handleConnectionError(error),
            next: (event: WebSocketDataEventInterface) => this.handleReceivedData(event),
        });
    }

    /**
     * close the web socket connection
     */
    private closeConnection(): void {
        this.unsubscribeConnection();
        this.isAuthenticated = false;
        this.isConnected = false;
    }

    /**
     * if the observable becomes subscribed,
     * we need to have a open connection or
     * open a new one
     */
    private onDataSubscription(): void {
        // we want to reconnect on any error or lost connection
        this.tryToReconnect = true;
        // increase the amount of subscriber
        this.dataSubscriberCount++;
        // check if we have an open connection
        // because of not we need to create
        if (!this.isConnected) {
            this.openConnection();
        }
    }

    /**
     * if a data observable becomes unsubscribed
     * we check if we can close the connection
     * if its open
     */
    private onDataUnsubscription(): void {
        // at first increase the amount of subscriber
        this.dataSubscriberCount--;
        // if there any open subscriptions left,
        // we dont need to do anything
        if (this.dataSubscriberCount > 0) {
            return;
        }
        // no subscribers left, so we dont want any
        // connection retries
        this.tryToReconnect = false;
        // and also we dont need the web socket
        // connection anymore
        this.closeConnection();
    }

    /**
     * if we receive any event, we just update the data subject
     * and reset the retryCounter, because we know that we
     * have an working connection
     *
     * @param data
     */
    private handleReceivedData(event: WebSocketDataEventInterface): void {
        this.retryCounter = 0;
        this.dataSubject.next(event);
    }

    /**
     * if any connection error occures, we set isConnected
     * to false and try to reconnect (if allowed through max
     * reconnect attemps and tryToReconnect)
     *
     * @param error
     */
    private handleConnectionError(error: object): void {
        // try to reconnect if allowed
        if (this.tryToReconnect && this.retryCounter <= this.retryMax) {
            // wait a second before retry to connect,
            // without the delay, the reconnection
            // wont work
            setTimeout(() => {
                this.retryCounter++;
                this.unsubscribeConnection();
                this.openConnection();
            }, 5000);
        }
        // else mark the connection as closed
        // and reset the retry counter
        else {
            this.retryCounter = 0;
            this.isConnected = false;
        }
    }

    /**
     * unsubscribes the web socket connection,
     * this close the connection but without
     * change the isConnected state
     */
    private unsubscribeConnection(): void {
        this.webSocketSubscription.unsubscribe();
    }

    /**
     * "I send a message to the server, and then I wait for the server to send me a message back, and
     * then I do something with that message."
     *
     * The problem is that I don't know how to wait for the server to send me a message back
     * @returns a boolean value.
     */
    public async clientAuthentication(): Promise<boolean> {
        if (this.isAuthenticated) {
            return true;
        }
        return new Promise<boolean>((resolve) => {
            // start to listen for the authentiaction answer
            this.getDataObservable(WebSocketEventEnum.ClientAuthentication)
                .pipe(first())
                .subscribe((event: WebSocketDataEventInterface) => {
                    this.isAuthenticated = event.data;
                    resolve(event.data);
                });
            // send the authentication request
            this.webSocketSubject.next({
                event: WebSocketEventEnum.ClientAuthentication,
                data: this.authenticationServive.getToken(),
            });
        });
    }

    /**
     * The function sends a message to the server to logout the client
     *
     * @returns The return type is a Promise of type boolean.
     */
    public async clientRevokeAuthentication(): Promise<boolean> {
        if (!this.isAuthenticated) {
            return true;
        }
        return new Promise<boolean>((resolve) => {
            // start to listen for the revoke answer
            this.getDataObservable(WebSocketEventEnum.ClientRevokeAuthentication)
                .pipe(first())
                .subscribe((event: WebSocketDataEventInterface) => {
                    this.isAuthenticated = !event.data;
                    resolve(event.data);
                });
            // send the revoke request
            this.webSocketSubject.next({
                event: WebSocketEventEnum.ClientRevokeAuthentication,
                data: null,
            });
        });
    }
}
