import { environment } from '../../../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationInterface, AuthenticationService } from '../../../../core/security';
import { HttpService } from '../../../../core/http';

/**
 * Basic Api Url for member actions
 */
const apiMemberUrl = `${environment.api.request}/member`;


@Injectable({
    providedIn: 'root'
})
export class PictureService {
    /**
     * inject dependencies
     * 
     * @param authService 
     * @param http 
     */
    constructor(
        private authService: AuthenticationService,
        private httpService: HttpService
    ) { }

    /**
     * upload new member picture
     * 
     * @param file 
     */
    public upload(file: File): Observable<string> {
        const uploadData = new FormData();
        uploadData.append('picture', file, file.name);
        // refresh the authentication before uploading, because in some cases if access token
        // is invalid we just receive an error 500 - some kind of angular bug because the error
        // message is just "Error occured while trying to proxy to: localhost:4200/api/member/picture/xyz"
        return this.authService.refresh().pipe(switchMap((data: AuthenticationInterface) => {
            return this.uploadRequest(this.authService.getAuthentication().id, uploadData);
        }));
    }

    /**
     * request the upload
     * 
     * @param memberId 
     * @param uploadData 
     */
    private uploadRequest(memberId: number, uploadData: FormData): Observable<string> {
        return this.httpService.post<{ picture: string }>(apiMemberUrl + '/picture/' + memberId, uploadData).pipe(
            map((response: { picture: string }) => response.picture)
        );
    }
}
