import { WinLadderService } from "../service/winladder.service";

/**
 * load all winladders on pageload
 */
export function WinLadderInitializer(winLadderService: WinLadderService) {
    return () =>
        new Promise<void>((resolve) => {
            winLadderService
                .onAppInitialization()
                .subscribe({ error: (e) => {} })
                .add(resolve);
        });
}
