import { Component } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "app-modal-tutorial",
    templateUrl: "./modal-tutorial.component.html",
    styleUrls: ["./modal-tutorial.component.scss"],
})
export class ModalTutorialComponent extends ModalComponent {
    /**
     * is true if there are no next slides left
     */
    public isAtEnd: boolean;

    /**
     * all slides
     */
    public readonly slides = [
        { image: "assets/images/tutorial-0.png", alt: "" },
        { image: "assets/images/tutorial-1.png", alt: "" },
        { image: "assets/images/tutorial-2.png", alt: "" },
        { image: "assets/images/tutorial-3.png", alt: "" },
    ];

    /**
     * checks on each slide change if the slider is at the end
     *
     * @param event
     */
    public onSlideChange(event: number) {
        this.isAtEnd = event + 1 === this.slides.length;
    }
}
