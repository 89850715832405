import { Component } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Modal } from '../../model/modal.model';
import { ModalController } from '../../model/modal-controller.model';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { MemberService } from '../../../../core/member';
import { ModalEventType } from '../../model/modal-event-type.model';

@Component({
    selector: 'modal-confirm',
    templateUrl: './modal-confirm.component.html',
    styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent extends ModalComponent {
    /**
     * inject dependencies
     * 
     * @param bsModalService 
     * @param modalController 
     * @param modal 
     */
    constructor(
        protected router: Router,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({
            title: '', message: '',
            buttonConfirm: 'modal.confirm.button.confirm',
            buttonCancel: 'modal.confirm.button.cancel',
            headerClass: 'bg-info'
        });
    }

    /**
     * confirm button 
     */
    public modalButtonConfirm(): void {
        super.modalButton();
        this.modalController.sendToParent(ModalEventType.Data, { confirmed: true });
        if (this.data.redirectTo) {
            this.router.navigate([this.data.redirectTo]);
        }
        if (this.data.memberReload) {
            this.memberService.reload().subscribe();
        }
    }

    /**
     * cancel button 
     */
    public modalButtonCancel(): void {
        super.modalButton();
        this.modalController.sendToParent(ModalEventType.Data, { confirmed: false });
    }

    /**
     * close button
     */
    public modalDismiss(): void {
        super.modalDismiss();
        this.modalController.sendToParent(ModalEventType.Data, { confirmed: false });
    }
}
