import { WebSocketService } from "../service/web-socket.service";

/**
 * provides an initializer which list on the member authentication
 * and authenticate the websocket connection or revoke it
 * 
 * @param webSocketService
 */
export function WebSocketInitializer(webSocketService: WebSocketService) {
    return () => new Promise(resolve => {
        webSocketService.initialize().then(resolve);
    });
}