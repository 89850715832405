import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { PaymentTransactionInterface } from '../../../../tcp/core/payment';


/**
 * Basic Api Url for payment actions
 */
const apiPaymentUrl = `${environment.api.request}/shop`;


@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        private httpService: HttpService
    ) { }

    /**
     * get payment url for the selected payment offer
     * 
     * @param paymentOfferId 
     * @returns 
     */
     public getPaymentUrl(paymentOfferId: number): Observable<string> {
        return this.requestShopApi<{ url: string }>('/prepare/' + paymentOfferId).pipe(
            map((data: { url: string }) => data.url)
        );
    }

    /**
     * loads a payment transaction from the api
     * 
     * @param transactionId 
     * @returns 
     */
    public getPaymentTransaction(transactionId: string): Observable<PaymentTransactionInterface> {
        return this.requestShopApi<PaymentTransactionInterface>('/transaction/' + transactionId);
    }

    /**
     * request the payment url from the backend api
     * 
     * @param url 
     * @returns 
     */
    private requestShopApi<Type>(url: string, params?: {[key: string]: any}): Observable<Type> {
        return this.httpService.get<Type>(`${apiPaymentUrl}` + url, params);
    }
}
