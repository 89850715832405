import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../environments/environment";
import { HttpService } from "../../../../core/http";
import { BettingGameBetInterface } from "../interface/betting-game-bet.interface";

/**
 * Basic api url
 */
const apiUrl = `${environment.api.request}/bettinggame/bet`;

@Injectable({
    providedIn: "root",
})
export class BettingGameBetService {
    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * This function gets the bets for a specific betting game
     *
     * @param {string} uuid - The unique identifier of the betting game.
     * @returns An array of BettingGameBetInterface objects.
     */
    public getBets(uuid: string): Observable<BettingGameBetInterface[]> {
        const url = apiUrl + "/get/" + uuid;
        return this.httpService.get<BettingGameBetInterface[]>(url);
    }

    /**
     * It sets the bets for the betting game
     *
     * @param {any} data - any
     * @returns An observable of type { success: boolean }
     */
    public setBets(data: any): Observable<{ success: boolean }> {
        const url = apiUrl + "/set";
        return this.httpService.post<{ success: boolean }>(url, data);
    }
}
