import { AffiliateService } from "../service/affiliate.service";

/**
 * provides an initializer which reads all affiliate query
 * parameter and stores them to cookie(s)
 * 
 * @param authService
 */
export function AfflilateInitializer(affiliateService: AffiliateService) {
    return () => new Promise<void>(resolve => {
        affiliateService.initialize();
        resolve();
    });
}