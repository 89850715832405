import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { MemberInterface } from '../interface/member.interface';
import { MemberService } from './member.service';
import { FavoriteLeagueInterface } from '../interface/favorite-league.interface';
import { ToastrService } from '../../../../shared/toastr';
import { HttpService } from '../../../../core/http';


/**
 * Basic api url for members favorite league requests
 */
const apiMemberFavoriteLeagueUrl = `${environment.api.request}/member/favorite/leagues`;

@Injectable({
    providedIn: 'root'
})
export class FavoriteLeagueListService {
    /**
     * current loading state
     */
    private isLoading: boolean = false;

    /**
     * currently loaded list
     */
    private favoriteLeagueList: BehaviorSubject<FavoriteLeagueInterface[]>;

        constructor(
        private httpService: HttpService,
        private memberService: MemberService,
        private toastrService: ToastrService
    ) {
        this.favoriteLeagueList = new BehaviorSubject<FavoriteLeagueInterface[]>(null);
        // subscribe member changes
        this.memberService.getMemberChangeObservable().subscribe((member: MemberInterface) => {
            // if member is set, reload the list
            if (member) {
                this.loadList().subscribe();
                return;
            }
            // clear the list
            this.clear();
        })
    }

    /**
     * returns the list subject as observable
     * 
     * @returns 
     */
    public getFavoriteLeagueListObservable(): Observable<FavoriteLeagueInterface[]> {
        return this.favoriteLeagueList.asObservable();
    }

    /**
     * returns the list subject as observable and loads new data
     * if the subject is null and a member is set
     * 
     * @returns 
     */
    public loadLeagueList(): Observable<FavoriteLeagueInterface[]> {
        if (this.memberService.getMemberSnapshot() !== null) {
            this.loadList().subscribe();
        }
        return this.getFavoriteLeagueListObservable().pipe(first(), filter((list: FavoriteLeagueInterface[]) => list !== null));;
    }

    /**
     * checks if the league is in the favorite leagues
     * 
     * @param leagueId 
     * @returns 
     */
     public isFavoriteLeague(leagueId: number): boolean {
        return (this.favoriteLeagueList.value)
            ? !!(this.favoriteLeagueList.value.filter((elem: FavoriteLeagueInterface) => elem.leagueId === leagueId).length)
            : false;
    }

    /**
     * toogles the favorite state of the league
     * 
     * @param leagueId 
     * @returns 
     */
    public toggleFavoriteLeague(leagueId: number): Observable<FavoriteLeagueInterface> {
        return this.postToApi({ leagueId: leagueId }).pipe(
            tap((league: FavoriteLeagueInterface) => {
                // we like to display automaticly a toaster notification after a toggle 
                // so here we go...
                const message = league.favorite ? 'bet.favorite.league.notification.added' : 'bet.favorite.league.notification.removed';
                const content = { message: message };
                const parameter = { league: league.leagueName };
                // show the message, success for adding, info for removing
                const tostr = (league.favorite)
                    ? this.toastrService.success(content, parameter)
                    : this.toastrService.info(content, parameter)
            })
        );
    }    

    /**
     * clears the current list
     */
    public clear(): void {
        this.favoriteLeagueList.next(null);
    }

    /**
     * loads the list from api
     */
     private loadList(): Observable<FavoriteLeagueInterface[]> {
        this.isLoading = true;
        const username = this.memberService.getMemberSnapshot().username;
        return this.httpService.get<FavoriteLeagueInterface[]>(`${apiMemberFavoriteLeagueUrl}/` + username, null, []).pipe(
            tap((list: FavoriteLeagueInterface[]) => {
                this.favoriteLeagueList.next(list);
                this.isLoading = false;
            }));
    }

    /**
     * loads the list from api
     */
    private postToApi(parameter: {[key: string]: any}): Observable<FavoriteLeagueInterface> {
        const username = this.memberService.getMemberSnapshot().username;
        return this.httpService.post<FavoriteLeagueInterface>(`${apiMemberFavoriteLeagueUrl}/` + username, parameter, []).pipe(
            tap((league: FavoriteLeagueInterface) => {
                this.loadLeagueList().subscribe();
            }));
    }    
}