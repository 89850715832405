import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-default",
    templateUrl: "./modal-default.component.html",
    styleUrls: ["./modal-default.component.scss"],
})
export class ModalDefaultComponent extends ModalComponent {
    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected router: Router,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({
            title: "",
            message: "",
            buttonConfirm: "modal.default.button",
        });
    }

    /**
     * overwrite modal button to
     * inject a redirect option
     */
    public modalButton(): void {
        super.modalButton();
        if (this.data.redirectTo) {
            this.router.navigate([this.data.redirectTo]);
        }
        if (this.data.memberReload) {
            this.memberService.reload().subscribe();
        }
    }
}
