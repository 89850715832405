import { CombinatorOptionsInterface } from "../interface/combinator-options.interface";

/**
 * Combine and count possibilities
 *
 * NOTE(S):
 * This class caused me a lot of headache. Ive
 * diesigned differnt kinds of algorithms that
 * pick x out of n. I know that there are more
 * elegant ways to approach this problem but
 * nothing was as fast as the current solution.
 *
 * IMPORTENT:
 * This is a copy of the server version!
 */
export class Combinator {
    /**
     * the array to pick combinations from
     */
    private array: Array<any>;

    /**
     * the amount of entries in the array we have
     * to pick elements from to combinate them
     */
    private arraySize: number;

    /**
     * set up the combinator
     *
     * @param array
     * @param options
     */
    public constructor(
        /**
         * the array to pick elements
         * for the combinations from
         * or the amount of elements
         * for the array should contain
         */
        array: Array<any> | number,

        /**
         * options to define the
         * combination behaviour
         */
        private options: CombinatorOptionsInterface
    ) {
        // setup the array to pick combinations from
        this.array =
            typeof array === "number"
                ? // if the array is a number we need to create
                  // a prefilled array to pick the combinations
                  // and information from
                  this.getPrefilledArray(array)
                : // if the passed array is an array
                  // we can use it as it is
                  array;
        // set the array length
        this.arraySize = this.array.length;
        // if the exact combination is not set, the check if we can set it
        // by our self, by checking the min and max combination length
        if (
            !this.options.exact &&
            this.options.min &&
            this.options.max &&
            this.options.min === this.options.max
        ) {
            this.options.exact = this.options.min;
        }
    }

    /**
     * returns the number of possible combinations
     * or throws an error
     *
     * @param array
     * @param options
     * @returns
     */
    public static getPossibilities(array: Array<any> | number, options: CombinatorOptionsInterface): number {
        return new Combinator(array, options).getPossibilities();
    }

    /**
     * returns the number of possible combinations
     * or throws an error
     */
    public getPossibilities(): number {
        return this.options.exact
            ? this.getExplicitCombinationPossibilities()
            : this.getRangeCombinationPossibilities();
    }

    /**
     * combinate the array elements
     * and return the result
     *
     * @returns
     */
    public static getCombinations(
        array: Array<any> | number,
        options: CombinatorOptionsInterface
    ): Array<any[]> {
        return new Combinator(array, options).getCombinations();
    }

    /**
     * combinate the array elements
     * and return the result
     *
     * @returns
     */
    public getCombinations(): Array<any[]> {
        // save the timestamp for debugging
        const timestamp = Date.now();
        // get cmbinations
        const combinations = this.options.exact ? this.getExcactCombinations() : this.getRangeCombinations();
        // if debug is enabled
        if (this.options?.debug) {
            const milliseconds = Date.now() - timestamp;
            console.log("Combinate: %i combinations generated", combinations.length);
            console.log("Combinate: combinated in %i ms", milliseconds);
            console.log("Combinate: result ", combinations);
        }
        // retun the combinations
        return combinations;
    }

    /**
     * returns a prefilled array where the value of the key
     * is the same as the key
     *
     * NOTE:
     * we cant use [...Array(+array).keys()]
     * on the server side, so we need to loop
     * to create the prefilled array
     *
     * @param arrayLength
     * @returns
     */
    private getPrefilledArray(arrayLength: number): Array<number> {
        const array: number[] = [];
        for (let value = 0; value < +arrayLength; value++) {
            array.push(value);
        }
        return array;
    }

    /**
     * returns the combinations result for exact combinations
     *
     * @returns
     */
    private getExcactCombinations(): Array<any[]> {
        // how many entities per combination should be found
        const pick = this.options.exact;
        // if the array length is the same as the amount to pick,
        // the only combination possible is the received one
        if (pick === this.arraySize) {
            return [this.array];
        }
        // if the pick is bigger then the total amount
        // or elements like 9 of 4 we cant combinate them
        if (pick > this.arraySize) {
            this.throwCombinationImpossible();
        }
        // start to combinate
        const nodes = this.getPrefilledArray(pick);
        const combinations: Array<any[]> = [];
        combinations.push(this.getExplicitCombination(nodes));
        if (this.options.debug) {
            console.log("NODES", nodes);
        }
        return this.handleExactCombinationNode(nodes, combinations);
    }

    /**
     * handles a combination node and returns the combinations
     *
     * @param nodes
     * @param combinations
     * @param nodeIndex
     * @returns
     */
    private handleExactCombinationNode(
        nodes: Array<number>,
        combinations: Array<any[]> = [],
        nodeIndex: number = 0
    ): Array<any[]> {
        // get the current node
        const node = this.getExactCombinationNode(nodeIndex, nodes);
        // if we are not at the end node, we move forward
        if (!node.isEndNode) {
            this.handleExactCombinationNode(nodes, combinations, node.neighbors.next.index);
        }
        // get a temp node value that we can increase without changing the origin value
        let tempNodeValue = node.value;
        while (true) {
            // just increase the temp value to move on
            // to the next combination
            ++tempNodeValue;
            // if we handle the end node, we can increase the
            // value and fetch the combination(s) until we
            // reched the limit and break the loop after that
            if (node.isEndNode) {
                if (tempNodeValue < this.arraySize) {
                    nodes[node.index] = tempNodeValue;
                    combinations.push(this.getExplicitCombination(nodes));
                    if (this.options.debug) {
                        console.log("NODES: ", nodes);
                    }
                    continue;
                }
                break;
            }
            // because we dont handle the end node we need also the next node
            const nextNode = this.getExactCombinationNode(node.neighbors.next.index, nodes);
            // check if we can increase the the current node value to the tempNodeValue without any conflicts
            if (
                tempNodeValue < this.arraySize &&
                tempNodeValue + 1 < this.arraySize &&
                tempNodeValue < nextNode.value
            ) {
                nodes[node.index] = tempNodeValue;
                nodes[node.neighbors.next.index] = tempNodeValue + 1;
                combinations.push(this.getExplicitCombination(nodes));
                this.handleExactCombinationNode(nodes, combinations, node.neighbors.next.index);
                continue;
            }
            // if its not the start node an we could not assign the tempNodeValue
            // without conflicts we need to shift all nodes
            if (!node.isStartNode) {
                this.shiftExactCombinationNodes(nodes, node.value + 1, node.index);
                break;
            }
            // just make sure there is always an exit
            break;
        }
        return combinations;
    }

    /**
     * returns a node with informations by the node index
     *
     * @param index
     * @param nodes
     */
    private getExactCombinationNode(
        index: number,
        nodes: Array<number>
    ): {
        index: number;
        value: number;
        isEndNode: boolean;
        isStartNode: boolean;
        neighbors: {
            previous: { index: number; value: number } | null;
            next: { index: number; value: number } | null;
        };
    } {
        // get some general information about the node
        const value = nodes[index];
        const nextIndex = index + 1;
        const nextValue = nodes[nextIndex];
        const previousIndex = index - 1;
        const previousValue = nodes[previousIndex];
        // create the default node object
        const node = {
            index: index,
            value: value,
            isEndNode: typeof nextValue === "undefined",
            isStartNode: typeof previousValue === "undefined",
            neighbors: {
                previous: null,
                next: null,
            },
        };
        // add the previous node if the current node
        // is not a start node
        if (!node.isStartNode) {
            node.neighbors.previous = {
                index: previousIndex,
                value: previousValue,
            };
        }
        // add the next node if the current node
        // is not a end node
        if (!node.isEndNode) {
            node.neighbors.next = {
                index: nextIndex,
                value: nextValue,
            };
        }
        // return the node
        return node;
    }

    /**
     * shift the combination nodes like
     * [0,1,2,3,6] becomes [0,2,3,4,5]
     *
     * @param nodes
     * @param value
     * @param index
     * @returns
     */
    private shiftExactCombinationNodes(nodes: any[], value: number, index: number = 0): boolean {
        const resetValue = value;
        //console.log(resetValue)
        const node = this.getExactCombinationNode(index, nodes);
        // if its an end node, we check if can set the given value
        if (node.isEndNode && value < this.arraySize) {
            nodes[node.index] = resetValue;
            return true;
        }
        // if the end node was changed and handle a lower node, we can reset the value
        if (
            !node.isEndNode &&
            this.shiftExactCombinationNodes(nodes, resetValue + 1, node.neighbors.next.index)
        ) {
            nodes[node.index] = resetValue;
            return true;
        }
        // cant reset any value
        return false;
    }

    /**
     * returns a combination of array elements
     *
     * @param pick
     * @returns
     */
    private getExplicitCombination(pick: number[]): Array<any> {
        const combination = [];
        for (const key of pick) {
            combination.push(this.array[key]);
        }
        return combination;
    }

    /**
     * calculates the amount of possible combinations.
     * If 2 of 4 (amount = 4, pick = 2), this function
     * will return 6
     *
     * NOTE:
     * This is a modified version of the combination script, w3resource provided
     * https://www.w3resource.com/javascript-exercises/javascript-math-exercise-42.php
     *
     * @returns
     */
    private getExplicitCombinationPossibilities(): number {
        // get pick from options
        let pick = this.options.exact;
        // if the amount is the same as the amount to pick
        // it can only be one combination
        if (this.arraySize === pick) {
            return 1;
        }
        // if we should pick more then the ammount
        // we cant combinate, 5 of 2 is not possible
        if (pick > this.arraySize) {
            this.throwCombinationImpossible();
        }
        pick = pick < this.arraySize - pick ? this.arraySize - pick : pick;
        return this.productRange(pick + 1, this.arraySize) / this.productRange(1, this.arraySize - pick);
    }

    /**
     * just a helper function to calc the product range
     * for getExplicitCombinationPossibilities
     *
     * @param a
     * @param b
     * @returns
     */
    private productRange(a: number, b: number): number {
        let prd = a;
        let i = a;
        while (i++ < b) {
            prd *= i;
        }
        return prd;
    }

    /**
     * returns combinations with no exact
     * amount of hits like in 2/3, instead
     * this can be used to get all other kind
     * of possible combinations. But in case
     * you need an exact match, avoid this
     * method, because its very cost intensive
     *
     * NOTE:
     * This is a improved version of a function that
     * was published at stackexchange:
     * https://codereview.stackexchange.com/a/39747
     *
     * @returns
     */
    private getRangeCombinations(): Array<any[]> {
        // combinations information
        const combinations: Array<any[]> = [];
        const combinationsCount = this.getRangeCombinationPossibilities();
        // get min and max length for combinations
        const minCombination = this.options.min || 0;
        const maxCombination = this.options.max || this.arraySize;

        // if debug is enabled, log the combination possibilities
        if (this.options.debug) {
            console.log("Combinate: %i possible combinations", combinationsCount);
        }
        // impossible because we try to pick a combination
        // like 10-20 of 2...
        if (minCombination > this.arraySize) {
            this.throwCombinationImpossible();
        }
        // begin to combinate
        for (let i = 1; i < combinationsCount; i++) {
            const combination = this.getRangeCombination(i, minCombination, maxCombination);
            if (combination) {
                combinations.push(combination);
                // check if we reached the result limit
                if (this.options.limit && this.options.limit > combinations.length) {
                    this.throwResultOverflow();
                }
            }
        }
        return combinations;
    }

    /**
     * returns a combination or null
     *
     * NOTE:
     * This is a improved version of a function that
     * was published at stackexchange:
     * https://codereview.stackexchange.com/a/39747
     *
     * @param index
     * @param minCombination
     * @param maxCombination
     * @returns
     */
    private getRangeCombination<Type>(
        index: number,
        minCombination: number,
        maxCombination: number
    ): Array<Type> | null {
        const combination: Array<Type> = [];
        for (let j = 0; j < this.arraySize; j++) {
            if (index & (1 << j)) {
                if (combination.length >= maxCombination) {
                    return null;
                }
                combination.push(this.array[j]);
            }
        }
        if (combination.length < minCombination) {
            return null;
        }
        return combination;
    }

    /**
     * returns the amount of combinations for
     * range combinations
     *
     * NOTE:
     * This is a improved version of a function that
     * was published at stackexchange:
     * https://codereview.stackexchange.com/a/39747
     *
     * @returns
     */
    private getRangeCombinationPossibilities(): number {
        const combinationCount = 1 << this.arraySize;
        // overflow...!
        if (combinationCount < this.arraySize) {
            this.throwCombinationOverflow();
        }
        return combinationCount;
    }

    /**
     * throws the combination impossible error
     */
    private throwCombinationImpossible(): void {
        throw {
            code: "COMBINATOR_COMBINATION_IMPOSSIBLE",
            message: "Less elements in the array as the min or exact combination settings",
        };
    }

    /**
     * throws the combination overflow error
     */
    private throwCombinationOverflow(): void {
        throw {
            code: "COMBINATOR_COMBINATION_OVERFLOW",
            message: "Too many possible combinations",
        };
    }

    /**
     * throws the result overflow error
     */
    private throwResultOverflow(): void {
        throw {
            code: "COMBINATOR_RESULT_OVERFLOW",
            message: "More combinations then allowed (due to the limit option)",
        };
    }
}
