import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { PageListEmpty } from '..';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { BalanceListInterface } from '../interface/balance-list.interface';
import { MemberService } from './member.service';



/**
 * Basic Api Url for balance
 */
const apiBalanceUrl = `${environment.api.request}/member/balance`;

@Injectable({
    providedIn: 'root'
})
export class BalanceService {
    /**
     * the current balance list (page) subject
     */
    private balanceListSubject: BehaviorSubject<BalanceListInterface>;

    /**
     * current loading state
     */
    private isLoading: boolean = false;


    /**
     * prepare the service
     * 
     * @param httpService 
     * @param memberService 
     */
    constructor(
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        this.balanceListSubject = new BehaviorSubject<BalanceListInterface>(null);
    }

    /**
     * returns the memebers balance
     */
    public getPage(page: number = 1): Observable<BalanceListInterface> {
        // if page is not currently loading
        if (!this.isLoading) {
            page = (page <= 0) ? 1 : page;
            this.balanceListSubject.next(null);
            return this.loadPage(page);
        }
        // transform current balance into an observable and return it
        return this.balanceListSubject.asObservable().pipe(
            filter(balance => balance != null), first()
        );
    }

    /**
     * clears the current balance
     */
    public clear(): void {
        this.balanceListSubject.next(null);
    }

    /**
     * loads member balance data from api
     */
    private loadPage(page: number): Observable<BalanceListInterface> {
        this.isLoading = true;
        const params = new HttpParams().set("page", '' + page).set("ts", '' + Date.now());
        const username = this.memberService.getMemberSnapshot().username;
        return this.httpService.get<BalanceListInterface>(`${apiBalanceUrl}/` + username, params, new PageListEmpty()).pipe(
            tap((balance) => {
                this.balanceListSubject.next(balance);
                this.isLoading = false;
            }));
    }
}
