import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { CoinPackageInterface } from '../interface/coin-package.interface';
import { FreeCoinPackageInterface } from '../interface/free-coin-package.interface';


/**
 * api url for coin packages requests
 */
const apiCoinPackagesUrl = `${environment.api.request}/shop/coins`;


@Injectable({
    providedIn: 'root'
})
export class CoinPackageService {
    /**
     * all coin packages
     */
    private coinsPackagesSubject: BehaviorSubject<CoinPackageInterface[]>;

    /**
     * subject for the free coin collection
     */
    private freeCoinPackageSubject: BehaviorSubject<FreeCoinPackageInterface>;

    /**
     * indicates if coin packages are cuurently loading from api
     */
    private isCoinPackagesLoading: boolean = false;

    /**
     * indicates if the free coin package is cuurently loading from api
     */
    private isFreeCoinPackageLoading: boolean = false;

    /**
     * note the last time we loaded the coin
     * packages to the server and if its to old,
     * coin packages become reloaded instead
     * of just returned on get/load
     */
    private lastCoinPackagesUpdate: number = 0;

    /**
     * defines how old the loaded coin packages
     * can become (in milliseconds) before
     * a reload is forced on get/load
     */
    private coinPackagesLifetime: number = 10000;


    /**
     * prepare the service
     * 
     * @param httpService
     */
    constructor(
        private httpService: HttpService
    ) {
        this.coinsPackagesSubject = new BehaviorSubject<CoinPackageInterface[]>(null);
        this.freeCoinPackageSubject = new BehaviorSubject<FreeCoinPackageInterface>(null);
        this.loadCoinPackages().subscribe();
        this.loadFreeCoinPackage().subscribe();
    }

    /**
     * returns the coinPackagesSubject as observable
     */
    public getCoinPackagesObservable(): Observable<CoinPackageInterface[]> {
        return this.coinsPackagesSubject.asObservable();
    }

    /**
     * returns the freeCoinPackageSubject as observable
     */
    public getFreeCoinPackageObservable(): Observable<FreeCoinPackageInterface> {
        return this.freeCoinPackageSubject.asObservable();
    }

    /**
     * load coin packages from server or return the coinsPackagesSubject as
     * observable which is piped to first and filter non null values
     */
    public getCoinPackages(): Observable<CoinPackageInterface[]> {
        return this.loadCoinPackages();
    }

    /**
     * load the free coin package from server or return the freeCoinPackageSubject as
     * observable which is piped to first and filter non null values
     */
    public getFreeCoinPackage(): Observable<FreeCoinPackageInterface> {
        return this.loadFreeCoinPackage();
    }

    /**
     * redeem free coin package at api
     */
    public redeemFreeCoinPackage(): Observable<number> {
        return this.httpService.post<{ coins: number }>(`${apiCoinPackagesUrl}/free`)
            .pipe(map((response: { coins: number }) => response.coins));
    }

    /**
     * load coin packages from server or return the coinsPackagesSubject as
     * observable which is piped to first and filter non null values
     */
    private loadCoinPackages(): Observable<CoinPackageInterface[]> {
        if (!this.isCoinPackagesLoading && (this.coinsPackagesSubject.value === null || this.isCoinPackagesOutdated())) {
            this.isCoinPackagesLoading = true;
            return this.loadCoinPackagesFromApi<CoinPackageInterface[]>('/packages', []).pipe(
                tap((coinPackages: CoinPackageInterface[]) => {
                    this.coinsPackagesSubject.next(coinPackages);
                    this.lastCoinPackagesUpdate = Date.now();
                    this.isCoinPackagesLoading = false;
                }));
        }
        return this.getCoinPackagesObservable().pipe(filter(coinPackages => coinPackages != null), first());
    }

    /**
     * load the free coin package from server or return the freeCoinPackageSubject as
     * observable which is piped to first and filter non null values
     */
    private loadFreeCoinPackage(): Observable<FreeCoinPackageInterface> {
        if (!this.isFreeCoinPackageLoading && (this.freeCoinPackageSubject.value === null || this.freeCoinPackageSubject.value.isAvailable)) {
            this.isFreeCoinPackageLoading = true;
            return this.loadCoinPackagesFromApi<FreeCoinPackageInterface>('/free', null).pipe(
                map((freeCoinPackage: FreeCoinPackageInterface) => {
                    freeCoinPackage.nextAvailableAt = new Date(freeCoinPackage.nextAvailableAt);
                    return freeCoinPackage;
                }),
                tap((freeCoinPackage: FreeCoinPackageInterface) => {
                    this.freeCoinPackageSubject.next(freeCoinPackage);
                    this.isFreeCoinPackageLoading = false;
                    this.setFreeCoinPackageAutoReload();
                }
                ));
        }
        return this.getFreeCoinPackageObservable().pipe(filter(freeCoinPackage => freeCoinPackage != null), first());
    }

    /**
     * checks if the current coin packages
     * are outdated
     * 
     * @returns 
     */
    private isCoinPackagesOutdated(): boolean {
        const timePassed = Date.now() - this.lastCoinPackagesUpdate;
        return timePassed > this.coinPackagesLifetime;
    }

    /**
     * loads coinpackages from api
     * 
     * @param url 
     */
    private loadCoinPackagesFromApi<Type>(url: string, fallback: any): Observable<Type> {
        return this.httpService.get<Type>(`${apiCoinPackagesUrl}` + url, null, fallback);
    }

    /**
     * reloads free coin package after a specific time
     */
    private setFreeCoinPackageAutoReload(): void {
        if (this.freeCoinPackageSubject.value.isAvailable) {
            return;
        }
        let nextAvailableAt = this.freeCoinPackageSubject.value.nextAvailableAt.getTime() - Date.now();
        nextAvailableAt = (nextAvailableAt > 0) ? nextAvailableAt : 10;
        setTimeout(() => {
            this.freeCoinPackageSubject.next(null);
            this.loadFreeCoinPackage().subscribe();
        }, nextAvailableAt);
    }
}
