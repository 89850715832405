import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * allows to load external urls without angular security check
 */
@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
    public constructor(
        private sanitizer: DomSanitizer
    ) { }

    transform(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
