import { SslService } from "../service/ssl.service";

/**
 * checks if we are connected via ssl (https)
 * and if not, we redirect directly to it
 *
 * @param sslService
 */
export function SslInitializer(sslService: SslService) {
    return () =>
        new Promise((resolve) => {
            // check for ssl and redirect if necessary
            sslService
                .forceSSL()
                .subscribe({ error: (e) => {} })
                .add(resolve);
        });
}
