<ng-template #modal>
    <div class="spinner-border text-light mr-3" role="status">
        <span class="sr-only">
            <ng-container *ngIf="isModalText(data.message); else elseMessageSrTranslation">{{ data.message.text | translate: data.message.parameter }}</ng-container>
            <ng-template #elseMessageSrTranslation>{{ data.message | translate }}</ng-template>
        </span>
    </div>
    <strong class="text-white">
        <ng-container *ngIf="isModalText(data.message); else elseMessageTranslation">{{ data.message.text | translate: data.message.parameter }}</ng-container>
        <ng-template #elseMessageTranslation>{{ data.message | translate }}</ng-template>
    </strong>
</ng-template>