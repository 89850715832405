import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { BetSlipInterface } from '../interface/bet-slip.interface';
import { BetSlipModeEnum } from '../model/bet-slip-mode.enum';
import { environment } from '../../../../../environments/environment';
import { BetSlipModesAvailableType } from '../model/bet-slip-modes-available.type';
import { RawOfferListInterface } from '../interface/raw-offer-list.interface';
import { MemberService } from '../../member';
import { OfferInterface } from '../interface/offer.interface';
import { GameInterface } from '../../game';
import { BoosterActiveGroupInterface, BoosterActiveInterface, BoosterService } from '../../booster';
import { BetSlipCombinationService } from './bet-slip-combination.service';
import { HttpService } from '../../../../core/http';



/**
 * url for bet requests
 */
const apiBetUrl = `${environment.api.request}/bet`;

@Injectable({
    providedIn: 'root'
})
export class BetSlipService {
    /**
     * limitations for betslip modes
     * 
     * NOTES:
     * we have to limitate the maximum of bets
     * for a system bet to XX. Why? Becuse of
     * the amount of possibilities.
     * 
     * With 20 bets there are:
     * 1.048.575 possible combinations
     * 
     * Okay, that are ALL possible combinations
     * but even if you pick a 10 out of 20 bet
     * the user will generate:
     * 184.756 combinations
     * 
     * Also, if I pick 10 out of 20, bet
     * I would win:
     * 24.961.793.448.428 Coins!
     * 
     * Same at 19 out of 20:
     * 463.588.729.178.001 Coins!
     * 
     * This is okay, because its a safe integer,
     * but way to much in my mind...
     */
    private readonly limitations = {
        'system': {
            betsMin: environment.betSlip.bets.system.betsMin,
            betsMax: environment.betSlip.bets.system.betsMax,
        },
        'combi': {
            betsMin: environment.betSlip.bets.combi.betsMin,
            betsMax: environment.betSlip.bets.combi.betsMax
        }
    };

    /**
     * the key we use to store betslip into locale storage
     */
    private localSorageKey: string = 'betslip';

    /**
     * the members current bet slip
     */
    private betSlipSubject: BehaviorSubject<BetSlipInterface>;

    /**
     * the subscription which will calculate and set the bet slip
     * to a valid one. Its a one time subscription, but we cancel
     * the subscription if a user change the bets before the 
     * validation is complete.
     */
    private setValidSubscription: Subscription;

    /**
     * currently active booster
     */
    private booster: BoosterActiveGroupInterface;


    public constructor(
        private betSlipCombinationService: BetSlipCombinationService,
        //private boosterService: BoosterService,
        private memberService: MemberService,
        private httpService: HttpService
    ) {
        this.betSlipSubject = new BehaviorSubject<BetSlipInterface>(this.load());
        //this.subscribeBooster();
        this.subscribeBetSlip();
    }

    /**
     * returns an observeable bet slip
     * 
     * @returns bet slip as observable
     */
    public getBetSlipObservable(): Observable<BetSlipInterface> {
        return this.betSlipSubject.asObservable();
    }

    /**
     * returns the current bet slip
     * 
     * @returns the current bet slip
     */
    public getBetSlipSnapshot(): BetSlipInterface {
        return this.betSlipSubject.value;
    }

    /**
     * set the coin stake for the bet or complete bet slip
     * 
     * @param coins 
     * @param offer 
     */
    public setCoins(coins: number, offer?: OfferInterface): void {
        const betSlip = this.getBetSlipSnapshot();
        const betCoins = this.getCoinsInRange(coins);
        switch (betSlip.mode) {
            case BetSlipModeEnum.Single:
                betSlip.coins.single[offer?.outcomeId || 0] = betCoins;
                break;
            case BetSlipModeEnum.Combi:
                betSlip.coins.combi = betCoins;
                break;
            case BetSlipModeEnum.System:
                betSlip.coins.system = betCoins;
                break;
        }
        this.setBetSlip(betSlip);
    }

    /**
     * set the system option (which kind of bet combination).
     * 2 means 2 of n, 3 means 3 of n, etc
     * 
     * @param coins 
     * @param offer 
     */
    public setSystemOption(option: number): void {
        const betSlip = this.getBetSlipSnapshot();
        betSlip.system = (option < 2 || option >= betSlip.count)
            ? (betSlip.count - 1)
            : option;
        this.setBetSlip(betSlip);
    }

    /**
     * add coins to the coin stake for the bet or complete bet slip
     * 
     * @param coins 
     * @param offer 
     */
    public addCoins(coins: number, offer?: OfferInterface): void {
        const betSlip = this.getBetSlipSnapshot();
        switch (betSlip.mode) {
            case BetSlipModeEnum.Single:
                let betCoins = betSlip.coins.single[offer?.outcomeId || 0] || 0;
                betSlip.coins.single[offer?.outcomeId || 0] = this.getCoinsInRange(betCoins + coins);
                break;
            case BetSlipModeEnum.Combi:
                betSlip.coins.combi = this.getCoinsInRange(betSlip.coins.combi + coins);
                break;
            case BetSlipModeEnum.System:
                betSlip.coins.system = this.getCoinsInRange(betSlip.coins.system + coins);
                break;
        }
        this.setBetSlip(betSlip);
    }

    /**
     * toggles a bet, which means add it if its not on the bet slip or
     * removes it from it if it is
     * 
     * @param game 
     * @param offer 
     */
    public toggle(game: GameInterface, offer: OfferInterface): void {
        (!this.isBetOnBetslip(offer))
            ? this.add(game, offer)
            : this.remove(offer);
    }

    /**
     * adds a bet to bet slip
     * 
     * @param game 
     * @param offer 
     */
    public add(game: GameInterface, offer: OfferInterface): void {
        // be sure the status is correct to pervent adding invalid bets
        if (offer.status > 1) {
            return;
        }
        const betSlip = this.getBetSlipSnapshot();
        betSlip.coins.single[offer.outcomeId] = environment.betSlip.coinsMin;
        betSlip.bets[offer.outcomeId] = {
            added: Date.now(),
            game: {
                eventId: game.eventId,
                homeTeamName: game.homeTeamName,
                awayTeamName: game.awayTeamName,
            },
            offer: {
                outcomeId: offer.outcomeId,
                baseLine: offer.baseLine,
                line: offer.line,
                marketName: offer.marketName,
                name: offer.name,
                status: offer.status,
                odds: offer.odds
            }
        };
        this.setBetSlip(betSlip);
    }
    
    /**
     * refresh the bet slip (recalculate, validation, etc)
     * 
     * @returns 
     */
    public refresh(): Observable<void> {
        return this.setValidBetSlip(this.getBetSlipSnapshot()).pipe(
            first(), map((valid: boolean) => {
                return;
            })
        );
    }

    /**
     * removes all bets and settings from bet slip
     */
    public removeAll(): void {
        this.setEmptyBetSlip();
    }

    /**
     * removes a bet from bet slip
     * 
     * @param offer
     */
    public remove(offer: OfferInterface): void {
        const betSlip = this.getBetSlipSnapshot();
        delete betSlip.bets[offer.outcomeId];
        delete betSlip.coins.single[offer.outcomeId];
        this.setBetSlip(betSlip);
    }

    /**
     * place bets via api request
     */
    public submit(): Observable<BetSlipInterface> {
        // cancel current subscription
        if (this.setValidSubscription) {
            this.setValidSubscription.unsubscribe();
        }
        // get the current bet slip
        const betSlipSnapshot = this.getBetSlipSnapshot();
        // at first, before we try to sub submit the bet slip,
        // we have to make sure that there is a logged member
        return this.memberService.requireLogin().pipe(
            // if the member request was successfully done...
            switchMap((data) => {
                // ...we switch the map to a validation bet slip request
                return this.setValidBetSlip(betSlipSnapshot).pipe(
                    first(), switchMap((valid: boolean) => {
                        // if this is an empty betslip - we cant submit it
                        // and also the member should not be able to trigger
                        // this...
                        if (valid === null) {
                            throw new Error("empty");
                        }
                        // if bet slip wasnt valid and corrected by us,
                        // we dont submit it and throw an corrected error
                        if (!valid) {
                            this.betSlipSubject.next(betSlipSnapshot);
                            throw new Error("corrected");
                        }
                        // validate that the user has enough coins to submit the bet slip
                        // and if not throw a coin error
                        if (this.memberService.getMemberSnapshot().progress.coins < betSlipSnapshot.coins.stake) {
                            throw new Error("coins");
                        }
                        // finally we switch the map again and submit the bet slip...
                        return this.requestApi<BetSlipInterface>('/slip', { betSlip: betSlipSnapshot }).pipe(
                            // ...if this was successfull, we clear the current bet slip
                            tap((betSlip: BetSlipInterface) => {
                                this.setEmptyBetSlip();
                            })
                        );
                    })
                );
            })
        );
    }

    /**
     * sets the bet slip to passed bet mode
     * 
     * @param mode 
     * @returns 
     */
    public setMode(mode: BetSlipModeEnum): void {
        if (!this.isModeAvailable(mode)) {
            return;
        }
        const betSlip = this.getBetSlipSnapshot();
        betSlip.mode = mode;
        this.setBetSlip(betSlip);
    }

    /**
     * get current available bet slip mode and the additional information
     * about if there are more then one bet on the same game
     * 
     * @returns 
     */
    public getModes(): BetSlipModesAvailableType {
        const betSlip = this.getBetSlipSnapshot();
        return {
            SINGLE: true,
            COMBI: (betSlip.unique && betSlip.count >= this.limitations.combi.betsMin),
            SYSTEM: (betSlip.unique && betSlip.count >= this.limitations.system.betsMin)
        };
    }

    /**
     * checks if the passed mode is available
     * by the current bet slip
     * 
     * @param mode 
     * @returns 
     */
    public isModeAvailable(mode: BetSlipModeEnum): boolean {
        return this.getModes()[mode];
    }

    /**
     * checks if the given bet offer is on the betslip
     * 
     * @param offer
     * @returns 
     */
    public isBetOnBetslip(offer: OfferInterface): boolean {
        const betSlip = this.getBetSlipSnapshot();
        const betSlipOfferIds = Object.keys(betSlip.bets);
        return betSlipOfferIds.includes(offer.outcomeId + '');
    }

    /**
     * checks if the bet slip contains more then one bet
     * on the same game
     * 
     * @returns 
     */
    public hasSameGameBets(): boolean {
        const bets = this.getBetSlipSnapshot().bets;
        const keys = Object.keys(bets);
        // here we will store game / event ids and set the value as true,
        // so we know all the games where is a bet set on and if it occures
        // twice we can set hasSameGameBet to true and break the loop
        const gameMap: { [key: number]: boolean } = {};
        let hasSameGameBet = false;
        for (const key of keys) {
            const bet = bets[key];
            if (gameMap[bet.game.eventId]) {
                hasSameGameBet = true;
                break;
            }
            gameMap[bet.game.eventId] = true;
        }
        return hasSameGameBet;
    }

    /**
     * returns the amount of bets for a system betslip.
     * Is 2 out of 4 is selected 6 bets are placed, so
     * this method will return 6.
     * 
     * @returns 
     */
    public getSystemBetsCount(betSlip?: BetSlipInterface): number {
        betSlip = betSlip || this.getBetSlipSnapshot();
        return this.betSlipCombinationService.getCombinationCount(Object.keys(betSlip.bets).length, betSlip.system);
    }

    /**
     * returns the count of bets on the bet slip
     */
    public getBetCount(): number {
        return Object.keys(this.getBetSlipSnapshot().bets).length;
    }

    /**
     * returns the allowed coin amount for a bet
     * in the specified range (not less then the
     * minimum or more then the maximum)
     * 
     * @param coins 
     * @returns 
     */
    private getCoinsInRange(coins: number): number {
        if (coins > environment.betSlip.coinsMax) {
            coins = environment.betSlip.coinsMax;
        } else if (coins < environment.betSlip.coinsMin) {
            coins = environment.betSlip.coinsMin;
        }
        return coins;
    }

    /**
     * returns all bets quotes for a system betslip. If its a 2 of 4 bet
     * for example, 6 bet quotes will be returned
     * 
     * @param betSlip 
     * @returns all bet quotes
     */
    private getSystemBetQuotes(betSlip: BetSlipInterface): number[] {
        // collect quotes form each bet
        const betQuotes: number[] = [];
        for (const key of Object.keys(betSlip.bets)) {
            const bet = betSlip.bets[key];
            betQuotes.push(bet.offer.odds);
        }
        // combinate the quotes
        const combiQuotes: number[] = [];
        for (const combination of this.betSlipCombinationService.getCombinations(betQuotes, betSlip.system)) {
            let quote = 1;
            for (const singleQuote of combination) {
                quote *= singleQuote;
            }
            combiQuotes.push(quote);            
        }
        return combiQuotes;
    }

    /**
     * returns an array of all bet offer ids, that are currently
     * places on the bet slip
     * 
     * @param betSlip 
     */
    private getBetIds(betSlip: BetSlipInterface): string[] {
        return Object.keys(betSlip.bets);
    }

    /**
     * sets or updates the current betslip. Before it will be changed,
     * this method will be completely calculate and validatate the
     * bet slip against current settings
     * 
     * @param betSlip 
     */
    private setBetSlip(betSlip: BetSlipInterface): void {
        // cancel current subscription
        if (this.setValidSubscription) {
            this.setValidSubscription.unsubscribe();
        }
        // and subscribe for a new valid bet slip
        this.setValidSubscription = this.setValidBetSlip(betSlip).subscribe((valid: boolean) => {
            if (valid !== null) {
                this.betSlipSubject.next(betSlip);
            }
        });
    }

    /**
     * validate the mode and bets of the betslip and correct them if nessesaray. Sets
     * information like bet count and if all bets are unique (not more then one bet
     * on a single game) and calculates the stake and max payouts. If the vaöidation
     * was skipped null will be returned to indicate there was no bet slip data 
     * to validate
     * 
     * @param betSlip 
     * @returns 
     */
    private setValidBetSlip(betSlip: BetSlipInterface): Observable<boolean | null> {
        // create empty messages
        betSlip.messages = [];
        // if there are no bets, just return an empty bet slip,
        // just set an empty bet slip and return null, to indicate
        // that this is not checkable because its empty
        if (this.getBetCount() === 0) {
            this.setEmptyBetSlip();
            return of(null);
        }

        return this.setValidBets(betSlip).pipe(first(), map((valid: boolean) => {
            this.setInformation(betSlip);
            valid = valid && this.setValidMode(betSlip)
            valid = valid && this.setValidSystemOption(betSlip);
            this.setCalculatedStake(betSlip);
            this.setCalculatedWin(betSlip);
            return valid;
        }));
    }

    /**
     * validates the bets on the bet slip, and if there is any bet which is not
     * betable anymore we remove it or if the quotes changed, we will add a note so
     * we can inform the member about it
     * 
     * @param betSlip 
     * @returns true if everything was fine, false we had to remove a bet or change the quote
     */
    private setValidBets(betSlip: BetSlipInterface): Observable<boolean> {
        const betIds = this.getBetIds(betSlip);
        return this.requestApi<RawOfferListInterface>('/offers', { bets: betIds }, {}).pipe(
            map((bets: RawOfferListInterface) => {
                let betRemoved = false;
                let oddChanged = false;
                // check current bets
                for (const index of Object.keys(betSlip.bets)) {
                    // remove invalid bet
                    if (!bets[+index] || bets[+index].outcomeStatus > 1) {
                        delete betSlip.bets[+index];
                        delete betSlip.coins.single[+index];
                        betRemoved = true;
                        continue;
                    }
                    // change odds if necessary
                    if (betSlip.bets[+index].offer.odds !== bets[+index].odds) {
                        betSlip.bets[+index].offer.odds = bets[+index].odds;
                        oddChanged = true;
                    }
                }
                // add messages
                if (betRemoved) {
                    this.addMessage(betSlip, 'bet.error.bet.removed');
                }
                if (oddChanged) {
                    this.addMessage(betSlip, 'bet.error.bet.changed');
                }
                // return status
                return (!betRemoved && !oddChanged);
            }));
    }

    /**
     * validates the current betslip mode and sets it to single if the current mode is invalid,
     * also we addd a note to the betslip, so that we can inform the member about it
     * 
     * @param betSlip 
     * @returns true if everything was fine, false we changed the mode
     */
    private setValidMode(betSlip: BetSlipInterface): boolean {
        const modes = this.getModes();
        const limitations = this.limitations[betSlip.mode.toLocaleLowerCase()];
        // not a valid mode because if not unique game bets
        if (!modes[betSlip.mode] && !betSlip.unique) {
            this.addMessage(betSlip, 'bet.error.mode.notUnique');
            betSlip.mode = BetSlipModeEnum.Single;
            return false;
        }
        // more then the max allowed bets
        if (betSlip.mode !== BetSlipModeEnum.Single && betSlip.count > limitations.betsMax) {
            this.addMessage(betSlip, 'bet.error.mode.' + betSlip.mode.toLowerCase() + '.max', { max: limitations.betsMax });
            betSlip.mode = BetSlipModeEnum.Single;
            return false;
        }
        // less then the required bets
        if (!modes[betSlip.mode]) {
            this.addMessage(betSlip, 'bet.error.mode.' + betSlip.mode.toLowerCase() + '.min', { min: limitations.betsMin });
            betSlip.mode = BetSlipModeEnum.Single;
            return false;
        }
        return true;
    }

    /**
     * validates the current system option (2 of n, etc) and change
     * it to the deault value (n-1 of n) if the option is not valid
     * 
     * @param betSlip 
     * @returns true if system option is valid, false if we changed the option
     */
    public setValidSystemOption(betSlip: BetSlipInterface): boolean {
        if (betSlip.mode === BetSlipModeEnum.System && !this.betSlipCombinationService.isPossibleOption(betSlip.system, betSlip.count)) {
            betSlip.system = betSlip.count - 1;
            this.addMessage(betSlip, 'bet.error.system.option');
            return false;
        }
        return true;
    }

    /**
     * set bet slip informations as bet count and if the
     * bet slip only contains unique bets (not more then
     * one bet on a game)
     * 
     * @param betSlip 
     */
    private setInformation(betSlip: BetSlipInterface): void {
        betSlip.unique = !this.hasSameGameBets();
        betSlip.count = this.getBetCount();
    }

    /**
     * calculate the bet slip stake
     * 
     * @param betSlip 
     */
    private setCalculatedStake(betSlip: BetSlipInterface): void {
        let stake = 0;
        switch (betSlip.mode) {
            case BetSlipModeEnum.Single:
                for (const key of Object.keys(betSlip.coins.single)) {
                    stake += betSlip.coins.single[key];
                }
                break;
            case BetSlipModeEnum.System:
                stake = this.getSystemBetsCount(betSlip) * betSlip.coins.system;
                break;
            case BetSlipModeEnum.Combi:
                stake = betSlip.coins.combi;
                break;
        }
        betSlip.coins.stake = stake;
    }

    /**
     * calculate the quote and the max wins depending
     * on the bet slip mode
     * 
     * @param betSlip 
     */
    private setCalculatedWin(betSlip: BetSlipInterface): void {
        switch (betSlip.mode) {
            case BetSlipModeEnum.Single:
                this.setSingleWin(betSlip);
                break;
            case BetSlipModeEnum.Combi:
                this.setCombiWin(betSlip);
                break;
            case BetSlipModeEnum.System:
                this.setSystemWin(betSlip);
                break;
        }
    }

    /**
     * sets the win values (coins, xp, quote) for an
     * bet slip in SINGLE mode
     * 
     * @param betSlip 
     */
    private setSingleWin(betSlip: BetSlipInterface): void {
        let quote = 0;
        let coins = 0;
        let xp = 0;
        for (const key of Object.keys(betSlip.bets)) {
            // get bet offer quote and the bet stake
            const offerQuote = betSlip.bets[key].offer.odds;
            const stake = betSlip.coins.single[key];
            // get win coins and xp
            const winCoins = this.calculateCoinsWin(stake, offerQuote, this.booster.coin);
            const winXp = this.calculateXpWin(winCoins, offerQuote, this.booster.xp);
            // add wins
            quote += offerQuote;
            coins += winCoins
            xp += winXp
        }
        // set final win values 
        this.setWin(betSlip, coins, xp, quote);
    }

    /**
     * sets the win values (coins, xp, quote) for an
     * bet slip in COMBI mode
     * 
     * @param betSlip 
     */
    private setCombiWin(betSlip: BetSlipInterface): void {
        // get combi quote
        let quote = 1;
        for (const key of Object.keys(betSlip.bets)) {
            const bet = betSlip.bets[key];
            quote *= bet.offer.odds;
        }
        // get coins and xp
        let coins = this.calculateCoinsWin(betSlip.coins.combi, quote, this.booster.coin);
        let xp = this.calculateXpWin(coins, quote, this.booster.xp);
        // set final win values 
        this.setWin(betSlip, coins, xp, quote);
    }

    /**
     * sets the win values (coins, xp, quote) for an
     * bet slip in SYSTEM mode
     * 
     * @param betSlip 
     */
    private setSystemWin(betSlip: BetSlipInterface): void {
        // get system quote
        let quote = 0;
        for (const addQuote of this.getSystemBetQuotes(betSlip)) {
            quote += addQuote;
        }
        // get coins and xp
        let coins = this.calculateCoinsWin(betSlip.coins.system, quote, this.booster.coin);
        let xp = this.calculateXpWin(coins, quote, this.booster.xp);
        // set final win values 
        this.setWin(betSlip, coins, xp, quote);
    }

    /**
     * finalize win values by round and set them to the bet slip
     * 
     * @param betSlip 
     * @param coins 
     * @param xp 
     * @param quote 
     */
    private setWin(betSlip: BetSlipInterface, coins: number, xp: number, quote: number): void {
        // get final xp, coins and quote
        xp = Math.round(xp);
        coins = Math.round(coins);
        quote = Math.round(quote * 100) / 100;
        // update bet slip
        betSlip.win.xp = xp;
        betSlip.win.coins = coins;
        betSlip.quote = quote;
    }

    /**
     * calcculates the oin win by the stake
     * quote and an optional active coin booster
     * 
     * @param stake 
     * @param quote 
     * @param booster 
     * @returns 
     */
    private calculateCoinsWin(stake: number, quote: number, booster?: BoosterActiveInterface): number {
        let coins = stake * quote;
        if (booster?.boosterId) {
            coins += Math.round((coins / 100) * booster.boosterType.percentage);
        }
        return coins;
    }

    /**
     * caclculates the xp win by the amount of coins the bet (slip)
     * can win, the quoute of the bet (slip) and an optinal active
     * xp booster
     * 
     * @param coinsWin 
     * @param quote 
     * @param booster 
     * @returns 
     */
    private calculateXpWin(coinsWin: number, quote: number, booster?: BoosterActiveInterface): number {
        //$_maxXP = round((($betOutcomeWagers[$_betOutcomeID] * $_odds) * (pow(min(9, $_odds), 0.4)) / 100));
        let xp = Math.round(coinsWin * (Math.pow(Math.min(9, quote), 0.4) / 100));
        // add booster xp to win xp
        if (booster?.boosterId) {
            xp += Math.round((xp / 100) * booster.boosterType.percentage);
        }
        return xp;
    }

    /**
     * adds a message to the betslip
     * 
     * @param betSlip 
     * @param message 
     * @param parameter 
     */
    private addMessage(betSlip: BetSlipInterface, message: string, parameter?: {[key: string]: string | number}): void {
        betSlip.messages.push({ message: message, parameter: parameter });
    }

    /**
     * sets a fresh and empty betslip
     */
    public setEmptyBetSlip(): void {
        const betSlip = this.getEmptyBetSlip();
        this.betSlipSubject.next(betSlip);
        //this.setBetSlip(betSlip);
    }

    /**
     * just returns an empty bet slip, we use as default, before
     * any bet is set to the betslip
     * 
     * @returns empty bet slip
     */
    private getEmptyBetSlip(): BetSlipInterface {
        return {
            mode: BetSlipModeEnum.Single,
            system: 2,
            quote: 0,
            unique: true,
            count: 0,
            win: { coins: 0, xp: 0 },
            bets: {},
            coins: {
                single: {},
                combi: environment.betSlip.coinsMin,
                system: environment.betSlip.coinsMin,
                stake: 0
            },
            messages: []
        }
    }

    /**
     * stores the bet slip in localStorage
     * 
     * @param betSlip 
     */
    private store(betSlip: BetSlipInterface): void {
        localStorage.setItem(this.localSorageKey, JSON.stringify(betSlip));
    }

    /**
     * loads the bet slip from locale storage and returns
     * it - if there is no betslip this will return an empty
     * bet slip
     */
    private load(): BetSlipInterface {
        const item = localStorage.getItem(this.localSorageKey);
        return (item) ? JSON.parse(item) : this.getEmptyBetSlip();
    }

    /**
     * request bet data from api
     * 
     * @param url 
     * @param params 
     */
    private requestApi<Type>(url: string, params?: { [key: string]: any }, fallback?: any): Observable<Type> {
        return this.httpService.post<Type>(apiBetUrl + url, params, fallback);
    }

    /**
     * we subscribe the betslip to store any bet slip hanges in locale storage
     */
    private subscribeBetSlip(): void {
        // init booster...
        this.booster = { xp: null, coin: null };
        // ...and subscribe active booster
        this.getBetSlipObservable()
            // just to be save we filter null values
            .pipe(filter((betSlip: BetSlipInterface) => betSlip !== null))
            .subscribe((betSlip: BetSlipInterface) => {
                this.store(betSlip);
            });
    }

    /**
     * subscribe active boosters
     */
    private subscribeBooster(): void {
        // this.boosterService.getActiveObservable().pipe(
        //     // just skip null booster groups
        //     filter((booster: BoosterActiveGroupInterface) => booster !== null)
        // ).subscribe((booster: BoosterActiveGroupInterface) => {
        //     // if active boosters changed, we need to save them and recalculate the bet slip
        //     if ((this.booster?.coin?.boosterId !== booster.coin?.boosterId) || (this.booster?.xp?.boosterId !== booster.xp?.boosterId)) {
        //         this.booster = booster;
        //         this.refresh().subscribe();
        //     }
        // });
    }
}