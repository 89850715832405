import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../core/translation';
import { DatePipe } from '../../translation';
import moment from 'moment';


@Pipe({
    name: 'todayDate'
})
export class TodayDatePipe implements PipeTransform {
    constructor(
        private translationService: TranslationService
    ) { }

    public transform(value: any, pattern: string = 'mediumDate'): any {
        // dates to handle
        const testDate = moment();
        const handleDate = moment(value);
        // today?! return today
        if (handleDate.format("YYYYMMDD") === testDate.format("YYYYMMDD")) {
            return "today";
        }
        // yesterday?! return it
        if (handleDate.format("YYYYMMDD") === testDate.clone().subtract(1, 'day').format("YYYYMMDD")) {
            return "yesterday";
        }
        // tomorrow?! return it
        if (handleDate.format("YYYYMMDD") === testDate.clone().add(1, 'day').format("YYYYMMDD")) {
            return "tomorrow";
        }
        // return date as translated string
        const ngPipe = new DatePipe(this.translationService);
        return ngPipe.transform(handleDate.toDate(), pattern);
    }
}
