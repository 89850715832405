import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { VideoAdsLockInterface } from '../interface/video-ads-lock';
import moment from 'moment';


/**
 * Basic Api Url for video ads actions
 */
const apiVideoAdsUrl = `${environment.api.request}/ads/video`;


@Injectable({
    providedIn: 'root'
})
export class VideoAdsService {
    /**
     * indicates whether video ads are currently locked for the member
     * and what date the lock will end
     */
    private lockSubject: BehaviorSubject<VideoAdsLockInterface>;

    /**
     * true while lock is loading from server
     */
    private isLockLoading: boolean = false;

    constructor(
        private httpService: HttpService
    ) {
        this.lockSubject = new BehaviorSubject<VideoAdsLockInterface>({ isLocked: false, lockedUntil: new Date() });
        this.getVideoAdLock().subscribe();
    }

    /**
     * returnlsLoedSubject as observable
     */
    public getLockedObservable(): Observable<VideoAdsLockInterface> {
        return this.lockSubject.asObservable().pipe(filter((lock: any) => lock !== null));
    }

    /**
     * gets video ad lock (reload will also request the api if member is allready
     * locked, normally not necessary because of the auto unlock)
     * 
     * @param reload 
     */
    public getVideoAdLock(reload: boolean = false): Observable<VideoAdsLockInterface> {
        if (!this.isLockLoading && (this.lockSubject.value === null || !this.lockSubject.value.isLocked || reload)) {
            this.lockSubject.next(null);
            return this.getVideoAd<VideoAdsLockInterface>('/lock', this.getSafeLock()).pipe(
                map((lock: VideoAdsLockInterface) => this.getSafeLock(lock)),
                tap((lock: VideoAdsLockInterface) => this.setLock(lock))
            );
        }
        return this.lockSubject.asObservable().pipe(
            filter((lock: VideoAdsLockInterface) => lock !== null), first()
        );
    }

    /**
     * gets the video ad url from api
     */
    public getVideoAdUrl(): Observable<string> {
        return this.getVideoAd<{ url: string}>('/url').pipe(map((data: { url: string}) => {
            return data.url;
        }));
    }

    /**
     * post success information to api so we can give the member a reward
     * the return value is the amount of coins he received
     * 
     * @param hash 
     * @param rd 
     * @param origin 
     */
    public postSuccess(rd: string, hash: string, origin: string): Observable<number> {
        return this.postVideoAd<{ coins: number}>('/reward', { hash: hash, rd: rd, origin: origin }).pipe(map((data: { coins: number}) => {
            return data.coins
        }));
    }

    /**
     * post error information to api
     */
    public postError(status: string): Observable<string> {
        return this.postVideoAd<{ status: string }>('/error', { status: status }).pipe(map((data: { status: string }) => {
            return data.status
        }));
    }

    /**
     * request video ad at api
     * 
     * @param url 
     */
    private getVideoAd<Type>(url: string, fallback?: any): Observable<Type> {
        return this.httpService.get(`${apiVideoAdsUrl}` + url, null, fallback);
    }

    /**
     * post to video ad at api
     * 
     * @param url 
     */
    private postVideoAd<Type>(url: string, data: any, fallback?: any): Observable<Type> {
        return this.httpService.post<Type>(`${apiVideoAdsUrl}` + url, data, fallback);
    }

    /**
     * sets the lock and automaticly unlock, after
     * 
     * 
     * @param lock 
     */
    private setLock(lock: VideoAdsLockInterface): void {
        this.lockSubject.next(lock);
        // set auto unlock if lock is active
        if (lock.isLocked) {
            const timeout = lock.lockedUntil.getTime() - Date.now();
            setTimeout(() => {
                this.getVideoAdLock(true).subscribe();
            }, (timeout > 0) ? timeout : 10);
        }
    }

    /**
     * returns a preset video lick based on the passed one
     * 
     * @param lock 
     * @returns 
     */
    private getSafeLock(lock?: VideoAdsLockInterface): VideoAdsLockInterface {
        return {
            isLocked: lock?.isLocked || false,
            lockedUntil: (lock?.lockedUntil)
                ? moment(lock.lockedUntil).toDate()
                : moment().add(10, 'seconds').toDate()
        };
    }
}
