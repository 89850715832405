<ng-template #modal>
    <div class="modal-content">
        <div class="modal-body">
            <carousel [noWrap]="true" [isAnimated]="true" (activeSlideChange)="onSlideChange($event)">
                <slide *ngFor="let slide of slides">
                    <img [src]="slide.image" [alt]="slide.alt" style="display: block; width: 100%;">
                </slide>
            </carousel>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="modalDismiss()">
                {{ (isAtEnd ? 'close' : 'skip') | translate }}
            </button>
        </div>
    </div>
</ng-template>