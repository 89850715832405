import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * checks if the password and passwordConfirm controls match
 * 
 * @param control 
 */
export const PasswordConfirmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const passwordConfirm = control.get('passwordConfirm');

    if (password && passwordConfirm && password.value !== passwordConfirm.value) {
        passwordConfirm.setErrors({ mustMatch: true });
        return { mustMatch: true };
    }
    return null;
};