import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { ModalEventInterface } from "../interface/modal-event.interface";
import { ModalOptionsInterface } from "../interface/modal-options.interface";
import { ModalInterface } from "../interface/modal.interface";
import { ModalService } from "../service/modal.service";
import { ModalEventType } from "./modal-event-type.model";
import { ModalType } from "./modal-type.model";

export class ModalController {
    /**
     * modal data
     */
    private readonly modal: ModalInterface;

    /**
     * modal service
     */
    private readonly service: ModalService;

    /**
     * observable for the modal component (service to modal)
     */
    private readonly toModalObservable$: Observable<ModalEventInterface>;

    /**
     * observable for the parent service / component (modal to parent(s))
     */
    private readonly toParentObservable$: Observable<ModalEventInterface>;

    /**
     * subject used to send data to modal
     */
    private readonly toModal: BehaviorSubject<ModalEventInterface>;

    /**
     * subject used to send data from modal to service
     */
    private readonly toParent: BehaviorSubject<ModalEventInterface>;

    /**
     * create subjects and observables
     */
    constructor(modal: ModalInterface, modalService: ModalService) {
        // store modal and service
        this.modal = modal;
        this.service = modalService;
        // create to modal "communication"
        this.toModal = new BehaviorSubject<ModalEventInterface>(null);
        this.toModalObservable$ = this.toModal.asObservable();
        // create to parent "communication"
        this.toParent = new BehaviorSubject<ModalEventInterface>(null);
        this.toParentObservable$ = this.toParent.asObservable();
    }

    /**
     * returns the modal servie
     */
    public getModalService(): ModalService {
        return this.service;
    }

    /**
     * returns the modal type
     */
    public getModalType(): ModalType {
        return this.modal.type;
    }

    /**
     * returns modal options
     */
    public getModalOptions(): ModalOptionsInterface | null {
        return this.modal.options || null;
    }

    /**
     * returns the observable to which the modal should subscribe
     */
    public onModalObservable(): Observable<ModalEventInterface> {
        return this.toModalObservable$;
    }

    /**
     * Returns the observable that a parent (component) can subscribe to.
     * Used to send feedback from modal to parent
     */
    public onParentObservable(): Observable<ModalEventInterface> {
        return this.toParentObservable$;
    }

    /**
     * returns an observable for a specific EventType send by the modal (to parent)
     *
     * @param eventType
     */
    public onModalEvent(eventType: ModalEventType): Observable<ModalEventInterface> {
        return this.toParentObservable$.pipe(
            filter((event: ModalEventInterface) => event && event.type === eventType)
        );
    }

    /**
     * returns an observable for a specific EventType send by the parent (to modal)
     *
     * @param eventType
     */
    public onParentEvent(eventType: ModalEventType): Observable<ModalEventInterface> {
        return this.toModalObservable$.pipe(filter((event: ModalEventInterface) => event.type === eventType));
    }

    /**
     * send an open event to modal
     *
     * @param data
     */
    public openModal(data?: any): ModalController {
        return this.sendToModal(ModalEventType.Open, data);
    }

    /**
     * send an close event to modal
     *
     * @param data
     */
    public closeModal(data?: any): ModalController {
        return this.sendToModal(ModalEventType.Close, data);
    }

    /**
     * send an "event" to modal
     *
     * @param type
     * @param data
     */
    public sendToModal(type: ModalEventType, data?: any): ModalController {
        this.toModal.next({ type: type, data: data });
        return this;
    }

    /**
     * send an "event" to Parent
     *
     * @param type
     * @param data
     */
    public sendToParent(type: ModalEventType, data?: any): ModalController {
        this.toParent.next({ type: type, data: data });
        return this;
    }
}
