import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "app-modal-bet-slip-best-of",
    templateUrl: "./modal-bet-slip-best-of.component.html",
    styleUrls: ["./modal-bet-slip-best-of.component.scss"],
})
export class ModalBetSlipBestOfComponent extends ModalComponent {
    /**
     * betslip ids from the received modal data
     */
    public betSlipId: number;

    /**
     * tracks if the bonus is ready for count up
     */
    private showBonus: boolean;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected translationService: TranslationService,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ betSlipId: 0 });
        this.betSlipId = this.data.betSlipId;
    }

    /**
     * reload member on opn
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    }

    /**
     * overwrite modal button to
     * inject a redirect option
     */
    public modalButton(): void {
        super.modalButton();
        this.modalController.getModalService().openBetSlipsDetail(this.betSlipId);
    }
}
