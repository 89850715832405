import { Component, OnInit } from '@angular/core';
import { ConnectionService } from './core/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    /**
     * page title
     */
    public title = 'Loading...';

    /**
     * indicates the api connection
     * defined by resonse answers
     * like 500, etc
     */
    public isApiConnected: boolean;


    constructor(
        private connectionService: ConnectionService
    ) { }


    public ngOnInit(): void {
        this.isApiConnected = true;
        this.subscribeApiConnectionState();
    }

    /**
     * subscribe for the api connection state
     */
    private subscribeApiConnectionState(): void {
        this.connectionService.getStatusObservable().subscribe((status: boolean) => {
            this.isApiConnected = status;
        });
    }
}
