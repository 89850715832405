import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { StatusEnum } from "../../../../core/bet";
import { BetDetailInterface, BetSlipDetailInterface } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-system-bet",
    templateUrl: "./modal-system-bet.component.html",
    styleUrls: ["./modal-system-bet.component.scss"],
})
export class ModalSystemBetComponent extends ModalComponent {
    /**
     * bet slip to display
     */
    public betSlip: BetSlipDetailInterface;

    /**
     * all bet / bet slip status
     */
    public status = StatusEnum;

    /**
     * bets mapped to outcomeIds
     */
    private bets: {
        [key: number]: BetDetailInterface;
    };

    /**
     * bet combination status
     */
    private combinationStatus: {
        [key: number]: StatusEnum;
    };

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        // set default data
        this.setDataFallback({ betSlip: null });
        this.betSlip = this.data.betSlip;
        // map and combinate
        this.mapBets();
        this.setCombinationStatus();
    }

    /**
     * returns a bet by its outcome id
     *
     * @param outcomeId
     * @returns
     */
    public getBet(outcomeId: number): BetDetailInterface {
        return this.bets[outcomeId];
    }

    /**
     * returns the bet status as string
     *
     * @param outcomeId
     * @returns
     */
    public getBetStatus(outcomeId: number): string {
        return this.getBet(outcomeId).status;
    }

    /**
     * returns bets finish status
     *
     * @param outcomeId
     * @returns
     */
    public isEventFinished(outcomeId: number): boolean {
        return this.getBet(outcomeId).lsportsEvent.isFinished;
    }

    /**
     * return the status for the current bet combination
     *
     * @param combiKey
     * @returns
     */
    public getCombinationStatus(combiKey: number): string {
        return this.combinationStatus[combiKey];
    }

    /**
     * set combination status for each bet combination
     */
    private setCombinationStatus(): void {
        this.combinationStatus = {};
        for (const combiKey of Object.keys(this.betSlip.systemBets)) {
            // stores all status for the bet combinations
            let statusSet: string[] = [];
            // collect data
            const combi = this.betSlip.systemBets[combiKey];
            for (const outcomeKey of Object.keys(combi.outcomeIds)) {
                const betOutcomeId = combi.outcomeIds[outcomeKey];
                statusSet.push(this.getBetStatus(betOutcomeId));
            }
            // find the summary status
            let status = StatusEnum.Win;
            if (
                statusSet.includes(StatusEnum.Void) ||
                statusSet.includes(StatusEnum.Cancel) ||
                statusSet.includes(StatusEnum.Delete)
            ) {
                status = StatusEnum.Void;
            } else if (statusSet.includes(StatusEnum.Lose)) {
                status = StatusEnum.Lose;
            } else if (statusSet.includes(StatusEnum.Pending)) {
                status = StatusEnum.Pending;
            }
            // store the result
            this.combinationStatus[combiKey] = status;
        }
    }

    /**
     * map bets to outcome ids
     */
    private mapBets(): void {
        this.bets = {};
        for (const key of Object.keys(this.betSlip.bets)) {
            const bet = this.betSlip.bets[key];
            const outcomeId = +bet.lsportsBetOutcome.lsportsBetOutcomeId;
            this.bets[outcomeId] = bet;
        }
    }
}
