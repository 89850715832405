import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-task-complete",
    templateUrl: "./modal-task-complete.component.html",
    styleUrls: ["./modal-task-complete.component.scss"],
})
export class ModalTaskCompleteComponent extends ModalComponent {
    public level: number;
    public bonus: {
        message?: string;
        xp?: number;
        coins?: number;
        booster?: number;
    };

    /**
     * tracks if the bonus is ready for count up
     */
    private showBonus: boolean;

    /**
     * inject dependencies
     *
     * @param translationService
     * @param bsModalService
     * @param modalController
     * @param memberService
     * @param modal
     */
    constructor(
        protected translationService: TranslationService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected memberService: MemberService,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({
            header: "",
            title: "",
            message: "",
            buttonConfirm: "modal.default.button",
        });
        // short access for data
        this.level = this.data.level;
        this.bonus = this.data?.bonus;
    }

    /**
     * refresh the member on open
     */
    public onOpen(): void {
        this.memberService.reload().subscribe();
    }

    /**
     * checks if the task has a bonus
     *
     * @returns
     */
    public hasBonus(): boolean {
        return this.bonus?.coins || this.bonus?.xp || this.bonus?.booster ? true : false;
    }

    /**
     * returns the animation delay depending on
     * if there is a bonus set
     *
     * @returns
     */
    public getButtonAnimationDelay(): string {
        return this.hasBonus() ? "animate__delay-2500ms" : "animate__delay-1500ms";
    }
}
