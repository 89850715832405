import { AbstractControl, ValidatorFn } from "@angular/forms";
import moment from 'moment';

export function DateValidator(options?: {
    // if true, null will not throw an error
    allowNull?: boolean,
    // default value to set if input is invalid (by default: "Invalid date")
    // also allowNull is automaticly set to true if the default value is set
    // to null
    onInvalid?: null | Date | 'currentDate' | 'default'
    // min allowed date
    minDate?: Date,
    // max allowed date
    maxDate?: Date
}): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        // if null and null is allowed we can exit here without any failures
        if ((options?.allowNull || options?.onInvalid === null) && control.value === null) {
            return null;
        }
        // get moment date from input field
        const momentDate = moment(control.value);
        // change "Invalid date" message behavior if onInvalid is set
        if ((options?.onInvalid || options?.onInvalid === null) && options?.onInvalid !== 'default' && momentDate.format("YYYY-MM-DD") === 'Invalid date') {
            control.setValue((options.onInvalid === 'currentDate') ? new Date() : options.onInvalid);
        }
        // date is not a valid date (or null) we return the invalid error
        if (control.value !== null && momentDate.format("YYYY-MM-DD") === 'Invalid date') {
            return { dateInvalid: { value: control.value } };
        }
        // is a min date is set, check it
        if (options?.minDate && options.minDate.getTime() > momentDate.toDate().getTime()) {
            return { dateMin: { value: control.value } };
        }
        // is a max date is set, check it
        if (options?.maxDate && options.maxDate.getTime() < momentDate.toDate().getTime()) {
            return { dateMax: { value: control.value } };
        }
        // everything is fine
        return null;
    };
}