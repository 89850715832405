import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { environment } from "../../../../../../../../environments/environment";
import { ResponseErrorInterface, ResponseInterface } from "../../../../../../../core/http";
import { BettingGameInterface, BettingGameInvitationService, BettingGameService } from "../../../../../../core/betting-game";
import { MemberInterface } from "../../../../../../core/member";
import { ModalService } from "../../../../../../shared/modal";

@Component({
    selector: "app-bettinggame-game-includes-invitation-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy {
    /**
     * trakcs if the invite is currently saving
     */
    public isSaving: boolean;

    /**
     * tracks if the autocomplete is loading
     */
    public isLoading: boolean;

    /**
     * the keyword autocomplete use to
     * mark and filter with (this is not
     * the search term!)
     */
    public keyword: string;

    /**
     * the selected member or email
     */
    public selected: MemberInterface | string;

    /**
     * the list of member to select from
     */
    public membersToSelect: MemberInterface[];

    /**
     * the current search string
     */
    public search: string;

    /**
     * the previous search to avoid to seach
     * again if there are no changes
     */
    private previousSearch: string;

    /**
     * the modal containing this component
     */
    private parentModal: ModalComponent;

    /**
     * the current betting game
     */
    public bettingGame: BettingGameInterface;

    /**
     * betting game subscription
     */
    private bettingGameSubscription: Subscription;

    /**
     * temp autocomplete subscription, we use
     * to store the api request and cancel it
     * before starting a new one
     */
    private autocompleteSubscription: Subscription;

    constructor(
        // inject dependencies
        private modalService: ModalService,
        private bettingGameService: BettingGameService,
        private inviteService: BettingGameInvitationService
    ) {}

    public ngOnInit(): void {
        this.search = "";
        this.keyword = "username";
        this.subscribeBettingGame();
    }

    public ngOnDestroy(): void {
        this.unsubscribeBettingGame();
    }

    /**
     * This function sets the parent modal of the current modal
     *
     * @param {ModalComponent} modal - ModalComponent - This is the modal component that will be used
     * to open the modal.
     */
    public setParentModal(modal: ModalComponent): void {
        this.parentModal = modal;
    }

    /**
     * close the parent modal
     */
    public close(): void {
        this.parentModal.close();
    }

    /**
     * The function sends an invite to the server and handles
     * the api response
     *
     * @returns The response from the server.
     */
    public invite(): void {
        // only send the invite if its possible
        if (!this.canInvite()) {
            return;
        }
        // get the value to send for the invite which is
        // either the email address or the memberId
        const toInvite = this.isEmailSelected()
            ? this.selected + ""
            : (<MemberInterface>this.selected).memberId;
        // send the invite to the server and handle the response
        this.isSaving = true;
        this.inviteService.invite(this.bettingGame.uuid, toInvite).subscribe({
            next: (success: boolean) => this.onInviteSuccess(),
            error: (response: ResponseErrorInterface) => this.onInviteError(response.error),
        });
    }

    /**
     * If the selected property is truthy and the isSaving property is falsey, then return true
     *
     * @returns A boolean value.
     */
    public canInvite(): boolean {
        return !!this.selected && !this.isSaving;
    }

    /**
     * If the selected member is not a string, then it is a member object and the member is selected
     *
     * @returns A boolean value.
     */
    public isMemberSelected(): boolean {
        return this.selected && typeof this.selected !== "string";
    }

    /**
     * If the selected value is a string and it's an email, then return true
     *
     * @returns A boolean value.
     */
    public isEmailSelected(): boolean {
        return typeof this.selected === "string" && this.isEmail(this.selected);
    }

    /**
     * If the search string is an email address, return true
     *
     * @returns A boolean value.
     */
    public isEmailSearch(): boolean {
        return this.isEmail(this.search);
    }

    /**
     * If the user hasn't selected a member from the autocomplete list, then select the first member in
     * the list or the search value if it is an email address
     */
    public onSearchSubmit(): void {
        // with a short delay to avoid to mess up with the autocomplete select
        setTimeout(() => this.autoSelect(), 100);
    }

    /**
     * The function is called when the user types in the search box. It sets the search variable to the
     * value of the search box and then calls the autocomplete function
     *
     * @param {string} search - The search string
     */
    public onSearch(search: string): void {
        this.search = search;
        this.autocomplete();
    }

    /**
     * The function is request the autocomplete data when the user focuses
     * on the input field.
     *
     * @param {Event} event - Event - The event that triggered the focus.
     */
    public onFocused(event: Event): void {
        this.autocomplete();
    }

    /**
     * The onCleared function is called when the user clicks the clear button
     * on the search bar and removes the current search and the selected item
     */
    public onCleared() {
        this.search = "";
        this.selected = null;
        this.autocomplete();
    }

    /**
     * Set the selected member from the autocomplete as the selected value
     *
     * @param {MemberInterface} member - MemberInterface - this is the type of the parameter that is
     * passed in.
     */
    public onSelected(member: MemberInterface): void {
        this.selected = member;
    }

    /**
     * It returns the path to the member's picture, with a timestamp appended to the end of the path to
     * prevent caching
     *
     * @param {MemberInterface} member - MemberInterface - this is the member object that is passed to
     * the function.
     * @returns A string that is the url of the picture.
     */
    public getPicture(member: MemberInterface): string {
        const picturePath = member.picture;
        const ts = member.changeDate ? new Date(member.changeDate).getTime() : "";
        return picturePath ? "url(" + environment.api.public + picturePath + "?t=" + ts + ")" : "";
    }

    /**
     * We check if the search term has changed, if it has we cancel the previous search, we update the
     * previous search term, we set the loading flag to true, we start a new search and we set the
     * loading flag to false
     *
     * @param {boolean} [force] - boolean - if true, the autocomplete will be triggered even if the
     * search string hasn't changed
     * @returns An array of members
     */
    private autocomplete(force?: boolean): void {
        // no changes? No search ;)
        if (this.search === this.previousSearch && !force) {
            return;
        }
        // update previous search an cancel autocomplete
        // subscriptions so we dont end with wrong data
        // also we reset the selected item
        this.selected = null;
        this.previousSearch = this.search;
        if (this.autocompleteSubscription) {
            this.autocompleteSubscription.unsubscribe();
            this.isLoading = false;
        }
        // load the suggestions
        this.isLoading = true;
        this.autocompleteSubscription = this.inviteService
            .autocomplete(this.bettingGame.uuid, this.search)
            .subscribe({
                error: (response: ResponseErrorInterface) => (this.isLoading = false),
                next: (members: MemberInterface[]) => {
                    this.membersToSelect = members;
                    this.isLoading = false;
                    this.preSelect();
                },
            });
    }

    /**
     * If there are no members selected, and there are members to select, select the first member
     */
    private autoSelect(): void {
        if (!this.selected && this.membersToSelect && this.membersToSelect.length > 0) {
            const selected = this.membersToSelect[0];
            this.selected = selected;
        } else if (!this.selected && this.isEmailSearch()) {
            this.selected = this.search;
        }
    }

    /**
     * If the search string is an email address, then call the autoSelect function
     */
    private preSelect(): void {
        if (this.isEmailSearch()) {
            this.autoSelect();
        }
    }

    /**
     * The function is called when the invite was successfull and opens
     * the default modal with a success message containing the member
     * who was invited
     */
    private onInviteSuccess(): void {
        this.modalService.openDefault({
            title: {
                text: "bettingGame.invitation.open.success",
                parameter: {
                    member: this.getInviteMember(),
                },
            },
        });
        this.onInviteHandled();
    }

    /**
     * This function is called when the invite fails and displays an error message
     *
     * @param {ResponseInterface} error - ResponseInterface
     */
    private onInviteError(error: ResponseInterface): void {
        const [message, limit] = (error.data + "").split("|");
        this.modalService.openError({
            title: {
                text: "bettingGame.invitation.open.error." + message,
                parameter: {
                    limit: limit,
                    member: this.getInviteMember(),
                },
            },
        });
        this.onInviteHandled();
    }

    /**
     * If the invite request was done either successfull or not
     * this function will reset the selection, resets the loading
     * state and reloads the the suggestion list
     */
    public onInviteHandled(): void {
        this.isSaving = false;
        this.selected = null;
        this.autocomplete(true);
    }

    /**
     * If the user selected an email address, return the email address as a string. Otherwise, return
     * the username of the selected member
     *
     * @returns The username of the selected member.
     */
    private getInviteMember(): string {
        return this.isEmailSelected() ? this.selected + "" : (<MemberInterface>this.selected).username;
    }

    /**
     * If the string to test is a valid email address, return true, otherwise return false
     *
     * @param {string} toTest - The string to test.
     * @returns A boolean value.
     */
    private isEmail(toTest: string): boolean {
        const regEx = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        const result = toTest.toLowerCase().match(regEx);
        return !!result;
    }

    /**
     * This function subscribes to the bettingGameService and sets the bettingGame variable to the
     * value of the bettingGameService.
     */
    private subscribeBettingGame(): void {
        this.bettingGameSubscription = this.bettingGameService.getObservable().subscribe({
            next: (bettingGame: BettingGameInterface) => (this.bettingGame = bettingGame),
        });
    }

    /**
     * This function unsubscribes from the betting game subscription
     */
    private unsubscribeBettingGame(): void {
        if (this.bettingGameSubscription) {
            this.bettingGameSubscription.unsubscribe();
        }
    }
}
