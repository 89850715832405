import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { RolePriorityEnum } from "../model/role-priority.enum";
import { AuthenticationService } from "../service/authentication.service";

@Injectable({
    providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
    public constructor(
        // inject dependencies
        private router: Router,
        private authService: AuthenticationService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // detect if we handle a tcp route
        const isControlPanel = state.url.match(new RegExp(/^\/tcp[\/]?/i)) !== null;
        // not logged in so redirect to unauthenticated page
        if (!this.authService.hasAuthentication()) {
            const unauthenticatedRedirect = isControlPanel ? ["/tcp/login"] : ["/"];
            this.router.navigate(unauthenticatedRedirect);
            // with return url - perhaps a login page should be created
            //this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
            return false;
        }
        // the route needs a spaciel role but the member dont have that role,
        // redirect to the unauthorised page
        if (typeof route.data.role !== "undefined" && !this.authService.hasRoleAccess(route.data.role)) {
            const isAdministrator = this.authService.hasRoleAccess(RolePriorityEnum.Admin);
            const unauthorisedRedirect = isControlPanel && isAdministrator ? ["/tcp/unauthorised"] : ["/"];
            this.router.navigate(unauthorisedRedirect);
            return false;
        }
        // authorized, so return true
        return true;
    }
}
