import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { Combinator } from "../../../../shared/combinator";

@Injectable({
    providedIn: "root",
})
export class BetSlipCombinationService {
    /**
     * to avoid unnsessesaray
     * recalculations we cache
     * some results
     */
    private cache: {
        // the key defines the amount of games
        [key: number]: {
            // the subkey defines system bet option
            // and the value the amount of
            // combinations
            [key: number]: number;
        };
    } = {};

    constructor() {}

    /**
     * returns all possible combinations
     *
     * @param toCombinate
     * @param pick
     * @returns
     */
    public getCombinations(toCombinate: Array<any>, pick: number): Array<any[]> {
        return Combinator.getCombinations(toCombinate, { exact: pick });
    }

    /**
     * retruns the amount of combinations
     *
     * @param amount
     * @param pick
     */
    public getCombinationCount(amount: number, pick: number): number {
        return Combinator.getPossibilities(amount, { exact: pick });
    }

    /**
     * get all possible system options for the bet amount
     *
     * @param betsAmount
     * @returns
     */
    public getCombinationOptions(betsAmount: number): { [key: number]: number } {
        // get the possibilities from the cache
        if (this.cache[betsAmount]) {
            return this.cache[betsAmount];
        }
        // calculate the allowed possibilities and return them
        const possibilities = this.calcPossibilities(betsAmount);
        return possibilities;
    }

    /**
     * validats if the option can be used
     * or if there are more combinations
     * then allowed
     *
     * @param systemOption
     * @param betsTotal
     * @returns
     */
    public isPossibleOption(systemOption: number, betsTotal: number): boolean {
        const cached = this.cache[betsTotal];
        if (typeof cached !== "undefined") {
            return !!cached[systemOption];
        }
        // if not cached we cache the possibillities and check again
        const possibilities = this.calcPossibilities(betsTotal);
        return this.isPossibleOption(systemOption, betsTotal);
    }

    /**
     * calculate all allowed possibilities
     * 
     * @param betAmount 
     * @returns 
     */
    private calcPossibilities(betAmount: number): { [key: number]: number } {
        // get all possible options from the start "2/12, 3/12, 4/12" etc...
        let result = this.calcPossibilitiesLoop(betAmount, 2, 1);
        // lets check if we allready have the max amount of possibilities
        // and if not we load extra the possibilities from the end
        if (Object.keys(result).length !== betAmount - 2) {
            // add all allowed possibilities from the end like "11/12, 10/12, 9/12" etc
            result = { ...result, ...this.calcPossibilitiesLoop(betAmount, (betAmount - 1), -1) };
        }
        this.cache[betAmount] = result;
        return result;
    }

    /**
     * get all possible allowed possibilities
     *
     * @param betAmount
     * @returns
     */
    private calcPossibilitiesLoop(
        betAmount: number,
        start: number = 1,
        add: number = 1
    ): { [key: number]: number } {
        const combinationLimit = environment.betSlip.bets.system.combinationsMax;
        const allowed: { [key: number]: number } = {};
        for (let pick = start; pick < betAmount && pick > 1; pick += add) {
            const count = this.getCombinationCount(betAmount, pick);
            if (count > combinationLimit) {
                break;
            }
            allowed[pick] = count;
        }
        return allowed;
    }
}
