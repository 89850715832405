<ng-template #modal>
    <div class="modal-header" *ngIf="!betSlips">
        <h5 class="tigerfont" *ngIf="isLoading">{{ 'account.bets.slip.loading.active' | translate }}</h5> 
        <h5 class="tigerfont" *ngIf="!isLoading">{{ 'account.bets.slip.loading.error' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-header" *ngIf="betSlips">
        <h5 class="tigerfont">{{ 'account.bets.slip.result.'+modalStatus | translate:{ betSlips: getBetSlipCount() } }}</h5>        
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" (click)="modalDismiss()">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="betSlips">
        <div class="row">
            <div class="col-12">
                <div class="list-box my-bets shadow">

                    <div class="legend d-none d-xl-flex">
                        <div class="cell col-2 text-left">{{ 'account.bets.header.bet' | translate }}</div>
                        <div class="cell col-5 text-left">{{ 'account.bets.header.match' | translate }}</div>
                        <div class="options d-flex col-lg-4 p-0">
                            <div class="cell col-4 text-center">{{ 'account.bets.header.stake' | translate }}</div>
                            <div class="cell col-4 text-center">{{ 'account.bets.header.quote' | translate }}</div>
                            <div class="cell col-4 text-center">{{ 'account.bets.header.result' | translate }}</div>
                        </div>
                        <div class="cell col-1 text-center">{{ 'account.bets.header.state' | translate }}</div>
                    </div>

                    <div class="entry d-flex flex-column flex-lg-row" *ngFor="let betSlip of betSlips | keyvalue" title="Ticket ID: {{ betSlip.value.ticketId }}"  [class]="getBetCss(betSlip.value.mode)">
                        <div class="cell bet col-md-12 col-lg-2">
                            <!-- <span class="tiger-sprite medium-icon betslip d-none d-xl-flex"></span> -->
                            <div class="bet-infos tigerfont">
                                <span>
                                    {{ 'account.bets.mode.'+betSlip.value.mode | translate }}
                                    <span *ngIf="betSlip.value.mode === betSlipMode.System">
                                       {{ betSlip.value.systemOption }}/{{ betSlip.value.betCount }}
                                    </span>
                                </span>
                                <span class="bet-time">{{ betSlip.value.insertDate | todayDate | translate }}, {{ betSlip.value.insertDate | date: 'HH:mm:ss' }}</span>
                            </div>
                        </div>
                        <div class="cell encounter col-md-12 col-lg-5 text-left">
                            <div *ngFor="let bet of betSlip.value.bets | keyvalue: keepOrder" class="game" [class]="bet.value.status | lowercase">
                                <div class="time">{{ bet.value.lsportsEvent.startDate | date }} {{ bet.value.lsportsEvent.startDate | date: "HH:mm" }}</div>
                                <div class="opponents">{{ bet.value.lsportsEvent.homeTeamName }} - {{ bet.value.lsportsEvent.awayTeamName }}</div>
                                <div class="your-bet">
                                    {{ 'account.bets.tip' | translate }}: {{ 'bet.offer.'+bet.value.marketName+'.title' | translate }}: 
                                    {{ ('bet.offer.'+bet.value.marketName+'.detail.'+bet.value.lsportsBetOutcome.name | translate) === 'bet.offer.'+bet.value.marketName+'.detail.'+bet.value.lsportsBetOutcome.name
                                        ? bet.value.lsportsBetOutcome.name
                                        : ('bet.offer.'+bet.value.marketName+'.detail.'+bet.value.lsportsBetOutcome.name | translate)
                                    }} {{ bet.value.lsportsBetOutcome.line }}
                                </div>

                                <div class="result" *ngIf="bet.value.lsportsEvent.isFinished">
                                    {{ 'account.bets.result' | translate }}: {{ bet.value.lsportsEvent.scoreboardResult?.final[0] || 0 }}:{{ bet.value.lsportsEvent.scoreboardResult?.final[1] || 0 }}
                                </div>
                            </div>                                                  
                        </div>
                        <div class="options d-flex col-lg-4">
                            <div class="cell bet-amount col-4 tigerfont">
                                <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.stake' | translate }}:</div>
                                <div class="coin-use">{{ betSlip.value.coinsTotal | number: '1.0' }} <span>{{ 'coins' | translate }}</span></div>
                            </div>
                            <div class="cell quote col-4 tigerfont">
                                <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.quote' | translate }}:</div>
                                {{ ((betSlip.value.status === status.Pending || betSlip.value.oddsWin === 0) ? betSlip.value.oddsMax : betSlip.value.oddsWin) | number: '1.0' }}
                            </div>
                            <div class="cell result col-4 tigerfont">
                                <div class="d-flex d-xl-none mobile-legend">{{ 'account.bets.header.result' | translate }}:</div>
                                {{ (betSlip.value.status === status.Pending ? betSlip.value.payoutMax : betSlip.value.payoutWin) | number: '1.0' }} {{ 'coins' | translate }}<br>
                                {{ (betSlip.value.status === status.Pending ? betSlip.value.xpMax : betSlip.value.xpWin) | number: '1.0' }} {{ 'xp' | translate }}
                                <div>
                                    <img src="/assets/images/booster-coins-gray-small.png" class="betBoosterImg m-2" *ngIf="betSlip.value.payoutBoost && betSlip.value.payoutBoost > 0" tooltip="{{ 'booster.coins.title' | translate }}: {{ betSlip.value.payoutBoost }}%" />
                                    <img src="/assets/images/booster-xp-gray-small.png" class="betBoosterImg m-2" *ngIf="betSlip.value.xpBoost && betSlip.value.xpBoost > 0" tooltip="{{ 'booster.xp.title' | translate }}: {{ betSlip.value.xpBoost }}%" />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="betSlip.value.status === status.Pending" class="cell status col-md-4 col-lg-1 pending"><i class="fa fa-hourglass-half"></i></div>
                        <div *ngIf="betSlip.value.status === status.Win" class="cell status col-md-4 col-lg-1 result-win"><i class="fa fa-check"></i></div>
                        <div *ngIf="betSlip.value.status === status.Lose" class="cell status col-md-4 col-lg-1 result-loss"><i class="fa fa-times"></i></div>
                        <div *ngIf="betSlip.value.status === status.Void || betSlip.value.status === status.Cancel || betSlip.value.status === status.Delete" class="cell status col-md-4 col-lg-1 result-void"><i class="fa fa-times"></i></div>
                    </div>  

                </div>
            </div>
        </div>
    </div>
</ng-template>