/**
 * reduce values to 0 -  so the always 1 day bug is gone
 * https://github.com/cipchk/ngx-countdown/issues/49#issuecomment-561989905
 * 
 * @param param0 
 */
export function countdownReducer({ date, formatStr, timezone }) {
    const CountdownTimeUnits: Array<[string, number]> = [
        ['Y', 1000 * 60 * 60 * 24 * 365], // years
        ['M', 1000 * 60 * 60 * 24 * 30], // months
        ['D', 1000 * 60 * 60 * 24], // days
        ['H', 1000 * 60 * 60], // hours
        ['m', 1000 * 60], // minutes
        ['s', 1000], // seconds
        ['S', 1] // million seconds
    ];
    let duration = Number(date || 0);

    return CountdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                return v.toString().padStart(match.length, '0');
            });
        }
        return current;
    }, formatStr);
};