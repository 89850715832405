import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../http";
import { WinLadderInerface } from "../interface/winladder.interface";

/**
 * Basic Api Url for WinLadder
 */
const apiWinladderUrl = `${environment.api.request}/winladder`;

@Injectable({
    providedIn: "root",
})
export class WinLadderService {
    /**
     * all winladders
     */
    public winLadders: Array<WinLadderInerface>;

    public constructor(
        // inject dependencies
        private httpService: HttpService
    ) {}

    /**
     * loads all winladders on app init
     */
    public onAppInitialization(): Observable<WinLadderInerface[]> {
        return this.load();
    }

    /**
     * returns the loaded winladders
     *
     * @returns
     */
    public getWinLadders(): WinLadderInerface[] {
        return this.winLadders;
    }

    /**
     * returns a winladder by id
     *
     * @param winLadderId
     * @returns
     */
    public getById(winLadderId: number): WinLadderInerface {
        // we use slice here to avoid mutating the original array
        const winLadders = this.winLadders.slice().reverse();
        const result = winLadders.find((wl) => wl.winLadderId === winLadderId);
        return result ? result : this.winLadders[0];
    }

    /**
     * returns the previous winladder, depending on the promotion value
     *
     * @param winLadder
     */
    public getPrevious(winLadder: WinLadderInerface): WinLadderInerface {
        // we use slice here to avoid mutating the original array
        const winLadders = this.winLadders.slice().reverse();
        const result = winLadders.find((wl) => wl.holding < winLadder.holding);
        return result ? result : this.winLadders[0];
    }

    /**
     * returns the next winladder, depending on the holding value
     * (not the promotion value, because its null on the last one)
     *
     * @param winLadder
     */
    public getNext(winLadder: WinLadderInerface): WinLadderInerface {
        const result = this.winLadders.find((wl) => wl.holding > winLadder.holding);
        return result || this.winLadders[this.winLadders.length - 1];
    }

    /**
     * laods the winladders from api
     */
    public load(): Observable<WinLadderInerface[]> {
        return this.httpService.get<WinLadderInerface[]>(`${apiWinladderUrl}`).pipe(
            tap((winLadders: WinLadderInerface[]) => {
                this.winLadders = winLadders;
            })
        );
    }
}
