import { MemberService } from "../../../front/core/member";
import { TranslationService } from "../service/translation.service";

/**
 * sets up the language to use for translations on app start
 */
export function TranslationInitializer(translationService: TranslationService, memberService: MemberService) {
    return () =>
        new Promise<void>((resolve) => {
            translationService.onAppInitialization(memberService).then(() => {
                resolve();
            });
        });
}
