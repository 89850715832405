import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, first, switchMap, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { AuthenticationInterface, AuthenticationService } from "../../security";
import { MemberInterface } from "../interface/member.interface";
import { LoginInterface } from "../interface/login.interface";
import { CookieService } from "ngx-cookie-service";
import { HttpService } from "../../http";
import moment from "moment";

@Injectable({
    providedIn: "root",
})
export class MemberService {
    /**
     * the current authentication (static to be sure we only load this once
     * even if the class is inherited)
     */
    protected static memberSubject: BehaviorSubject<MemberInterface>;

    /**
     * observable member genereted from memberSubject (static to be sure we
     * only load this once even if the class is inherited)
     */
    protected static memberObservable: Observable<MemberInterface>;

    /**
     * indicaties if the member is refreshing or loading at the moment
     */
    protected static isRequesting: { refresh?: boolean; load?: boolean } = {};

    /**
     * the last time we refreshed or loaded the member
     */
    protected static lastRequestAt: { refresh?: Date; load?: Date } = {};

    /**
     * to avoid unnecessary requests to the api we
     * limit the refresh requests to 1 per x milliseconds.
     */
    protected static readonly requestDelay = { refresh: 50, load: 500 };

    /**
     * setup dependencies, init variables and subscripbe to events
     *
     * @param httpService
     * @param cookieService
     * @param authService
     */
    constructor(
        // inject dependencies
        protected httpService: HttpService,
        protected cookieService: CookieService,
        protected authService: AuthenticationService
    ) {
        this.initService();
    }

    /**
     * returns the api url to request
     *
     * @returns
     */
    public getApiUrl(): string {
        return environment.api.request + "/member";
    }

    /**
     * check if the serivce has currently member data loaded
     *
     */
    public hasMember(): boolean {
        return MemberService.memberSubject.value !== null;
    }

    /**
     * returns the current value of the member subject
     */
    public getMemberSnapshot(): MemberInterface {
        return MemberService.memberSubject.value;
    }

    /**
     * returns an observable "member" object
     */
    public getMemberObservable(): Observable<MemberInterface> {
        return MemberService.memberObservable;
    }

    /**
     * returns an observable "member" object, wich will only
     * fire if the new value is null or not the same member
     * as before
     */
    public getMemberChangeObservable(): Observable<MemberInterface> {
        let currentMemberId = this.getMemberSnapshot()?.memberId;
        return MemberService.memberObservable.pipe(
            filter((member: MemberInterface) => currentMemberId !== member?.memberId),
            tap((member: MemberInterface) => (currentMemberId = member?.memberId))
        );
    }

    /**
     * returns true if there was any login
     * with this browser before
     *
     * @returns
     */
    public hasPreviousLogin(): boolean {
        return this.getLoginCookie().loggedIn;
    }

    /**
     * returns true if the member logged out
     * via the logout method (the real logout)
     * and will stay false if the member login
     * just timed out
     *
     * @returns
     */
    public hasLoggedOut(): boolean {
        return this.getLoginCookie().loggedOut;
    }

    /**
     * returns the last login username, but
     * if there is none this will just return
     * an empty string
     *
     * @returns
     */
    public getPreviousLoginUsername(): string {
        return this.getLoginCookie().username || "";
    }

    /**
     * login a member with credentials at the api and load the user object
     *
     * @param email
     * @param password
     */
    public login(email: string, password: string): Observable<MemberInterface> {
        return this.authService.login(email, password).pipe(
            switchMap((authentication: AuthenticationInterface) => {
                return this.load();
            })
        );
    }

    /**
     * refresh the member authentification and member data
     */
    public requireLogin(): Observable<MemberInterface> {
        return this.loginRefresh();
    }

    /**
     * refresh the member authentification and member data
     */
    public loginRefresh(): Observable<MemberInterface> {
        const requestType = "refresh";
        if (!this.isRequestLocked(requestType)) {
            this.setRequestLoading(requestType);
            return this.authService.refresh().pipe(
                switchMap((authentication: AuthenticationInterface) => {
                    this.setLastRequestInfo(requestType);
                    return this.load();
                })
            );
        }
        return this.getMemberObservable().pipe(
            filter((member: MemberInterface) => member !== null),
            first()
        );
    }

    /**
     * reloads logged member from api
     *
     * @alias load
     */
    public reload(): Observable<MemberInterface> {
        return this.load();
    }

    /**
     * removes memberdata and log out at api
     */
    public logout(): Observable<{ success: boolean }> {
        this.setLoginCookieOnLogout();
        return this.authService.logout();
    }

    /**
     * send passwords (current, new and confirm) to api to save the new member password
     *
     * @param passwords
     */
    public savePassword(passwords: { [key: string]: string }): Observable<{ success: boolean }> {
        return this.httpService.post<{ success: boolean }>(
            this.getApiUrl() + "/password/new/" + this.getMemberSnapshot().memberId,
            { passwords: passwords }
        );
    }

    /**
     * loads logged member from api
     */
    protected load(): Observable<MemberInterface | null> {
        const requestType = "load";
        if (!this.isRequestLocked(requestType)) {
            this.setRequestLoading(requestType);
            if (this.authService.hasAuthentication()) {
                return this.requestTokenMember().pipe(
                    tap((member: MemberInterface) => {
                        this.setLastRequestInfo(requestType);
                    })
                );
            }
            return of(null);
        }
        return this.getMemberObservable().pipe(
            filter((member: MemberInterface) => member !== null),
            first()
        );
    }

    /**
     * sets member
     *
     * @param member
     */
    protected setMember(member?: MemberInterface): void {
        if (member) {
            member.changeDate = new Date(member.changeDate || Date.now());
        }
        MemberService.memberSubject.next(member || null);
    }

    /**
     * unsets member
     */
    protected removeMember(): void {
        MemberService.memberSubject.next(null);
    }

    /**
     * request member from api
     *
     * @param url
     * @param params
     */
    protected requestTokenMember(): Observable<MemberInterface> {
        const memberId = this.authService.getAuthentication().id;
        return this.httpService.get<MemberInterface>(this.getApiUrl() + "/" + memberId, null, null).pipe(
            tap((member: MemberInterface) => {
                if (member !== null) {
                    this.setLoginCookieOnLogin(member?.username);
                }
                this.setMember(member);
            })
        );
    }

    /**
     * sets the loading state to true
     *
     * @param type
     */
    protected setRequestLoading(type: "refresh" | "load"): void {
        MemberService.isRequesting[type] = true;
    }

    /**
     * set last request info
     */
    protected setLastRequestInfo(type: "refresh" | "load"): void {
        MemberService.isRequesting[type] = false;
        MemberService.lastRequestAt[type] = new Date();
    }

    /**
     * returns true if the request type is locked
     *
     * @returns
     */
    protected isRequestLocked(type: "refresh" | "load"): boolean {
        if (!MemberService.isRequesting[type]) {
            return false;
        }
        if (!MemberService.lastRequestAt[type]) {
            return false;
        }
        const lockedUntil = moment(MemberService.lastRequestAt[type])
            .add(MemberService.requestDelay[type], "milliseconds")
            .toDate();
        return lockedUntil.getTime() >= Date.now();
    }

    /**
     * set the login information to the login cookie
     *
     * @param username
     */
    protected setLoginCookieOnLogin(username: string): void {
        this.setLoginCookie(username);
    }

    /**
     * set the login cookie information on logout
     */
    protected setLoginCookieOnLogout(): void {
        this.setLoginCookie();
    }

    /**
     * set the login data as cookie
     *
     * @param username
     */
    protected setLoginCookie(username?: string): void {
        const expires = moment().add(1, "year").toDate();
        const login: LoginInterface = {
            loggedIn: true,
            loggedOut: !username,
            username: username,
        };
        this.cookieService.set(environment.cookies.login, JSON.stringify(login), expires);
    }

    /**
     * returns the parsed cookie data
     * or an default object
     *
     * @returns
     */
    protected getLoginCookie(): LoginInterface {
        const cookieData = this.cookieService.get(environment.cookies.login);
        return cookieData ? JSON.parse(cookieData) : { hasLogin: false };
    }

    /**
     * removes the login cookie
     */
    protected removeLoginCookie(): void {
        this.cookieService.delete(environment.cookies.login);
    }

    /**
     * initialize the service and because
     * this class can be inherited and there
     * are static vars (and a subscription)
     * we check if the subject is allready set
     * so we know if the constructor was called
     * before and everything is allready set
     *
     * @returns
     */
    protected initService(): void {
        // allready initialized
        if (typeof MemberService.memberSubject !== "undefined") {
            return;
        }
        // init the service
        MemberService.memberSubject = new BehaviorSubject<MemberInterface>(null);
        MemberService.memberObservable = MemberService.memberSubject.asObservable();
        // listen on auth changes to remove the member on auth lost
        this.authService
            .getAuthenticationObservable()
            .pipe(
                tap((auth: AuthenticationInterface) => {
                    if (!this.authService.hasAuthentication() && this.getMemberSnapshot() !== null) {
                        this.removeMember();
                    }
                })
            )
            .subscribe();
    }
}
