<ng-template #modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="glamour-container animate__animated animate__flipInX">
            <div class="starrays"></div>
            <span class="tiger-sprite huge-icon" [class]="'league-'+(winLadder.name | replace: ' ': '-' | lowercase)"></span>
        </div>
    </div>
    <!-- <span class="tiger-sprite huge-icon rank-1"></span> -->
    <div class="modal-body pb-5">
        <div class="row">
            <div class="col-12 col-sm-11 mx-auto text-center">
                <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">{{ 'season.winLadder.title' | translate }}</h1>
            </div>
            <div class="col-12 col-sm-10 mx-auto text-center">
                <p class="subline mb-1 animate__animated animate__fadeInUp animate__delay-1s">
                    {{ 'season.winLadder.date' | translate: { result: data.result, date: (data.eventDate | todayDate) } }}
                </p>
                <span class="placement-highlight-large animate__animated animate__fadeInUp animate__delay-1s mb-4">{{ 'season.winLadder.league' | translate: { winLadder: winLadder.name } }}</span>
                <!-- <div class="rank-element rank-icon animate__animated animate__fadeInUp animate__delay-1s">
                    <span class="tiger-sprite huge-icon rank-1 position-relative float-none" style="top: 0px;" [class]="'rank-'+(winLadder.name | replace: ' ': '' | lowercase)+'-big'"></span>
                </div>
                <h2 class="tigerfont">{{ 'season.winLadder.result.'+data.result | translate }}</h2> -->
                <div class="animate__animated animate__fadeInUp animate__delay-2s" *ngIf="data?.bonus?.coins">
                    <p class="subline mb-3">{{ 'season.winLadder.bonus' | translate }}</p>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png">
                                    +<app-shared-countup [to]="data.bonus.coins" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'coins' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
            <div class="col-12 col-sm-11 mx-auto text-center">
                <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-5 animate__animated animate__fadeInUp" [class]="getButtonAnimationDelay()" (click)="modalDismiss()">{{ 'close' | translate }}</button>
            </div>
        </div>
    </div>
</ng-template>