export class Affiliate {
    ref: string = '';
    cbid: string = '';
    aid: string = '';
    utmCampaign: string = '';
    utmSource: string = '';
    utmTerm: string = '';
    utmPub: string = '';
    utmMedium: string = '';
    utmContent: string = '';
    refMemberId: string = '';
    affiliateId: string = '';
    referrer: string = '';
}

/**
 * maps Affliate properties query parameter (GET) keys
 */
export const AffiliateMap: Affiliate = {
    ref: 'ref',
    cbid: 'cbid',
    aid: 'aid',
    utmCampaign: 'utm_campaign',
    utmContent: 'utm_content',
    utmMedium: 'utm_medium',
    utmPub: 'utm_pub',
    utmSource: 'utm_source',
    utmTerm: 'utm_term',
    // could not find any declaration
    refMemberId: '',
    affiliateId: '',
    // referer is not mapped because its not a query parameter
    referrer: ''
}