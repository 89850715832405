import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LabelInterface } from '../interface/label.interface';
import { environment } from '../../../../../environments/environment';
import { filter, first, map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Title } from '@angular/platform-browser';
import { HttpService } from '../../../../core/http';
import moment from 'moment';


// api url
const apiUrl = `${environment.api.request}/label`;

@Injectable({
    providedIn: 'root'
})
export class LabelService {
    /**
     * the current label information loaded by the hostname
     */
    private labelSubject: BehaviorSubject<LabelInterface>

    /**
     * indicates the loading status
     */
    private isLoading = false;

    /**
     * cookie name
     */
    private cookieName = "label";


    constructor(
        private httpService: HttpService,
        private titleService: Title,
        private cookieService: CookieService
    ){
        this.labelSubject = new BehaviorSubject<LabelInterface>(null);
        this.cookieName = environment.cookies.label || this.cookieName;
    }

    /**
     * detects and sets up the label configuration,
     * and redirects to the received label if the
     * current hostname does not match
     * 
     * @param redirect 
     * @returns 
     */
    public initialaize(redirect: boolean = true): Observable<LabelInterface> {
        return this.configurateLabel().pipe(
            tap((label: LabelInterface) => {
                // if redirect is true and the lable hostname does not match
                // with current hostname, we redirect to the labels host
                if(redirect && label?.hostname !== this.getHostname()){
                    window.location.href = 'https://'+(label?.hostname || environment.host)
                }
            })
        );
    }

    /**
     * returns the current label or detect it
     * and returns the result
     * 
     * @returns 
     */
    public getLabel(): Observable<LabelInterface> {
        if(this.labelSubject.value === null){
            return this.detect();
        }
        return this.getLabelObservable().pipe(first());
    }

    /**
     * returns the currently detected label
     * 
     * @returns 
     */
    public getLabelSnapshot(): LabelInterface {
        return this.labelSubject.value;
    }

    /**
     * returns the label subject as observable
     * 
     * @returns 
     */
    public getLabelObservable(): Observable<LabelInterface> {
        return this.labelSubject.asObservable();
    }

    /**
     * detect the label by the hostname
     * 
     * @returns 
     */
    private detect(): Observable<LabelInterface> {
        const hostname = this.getHostname();
        return this.loadLabel(hostname);
    }

    /**
     * detects and sets up the label configuration
     * 
     * @returns 
     */
    private configurateLabel(): Observable<LabelInterface> {
        return this.detect().pipe(
            tap((label: LabelInterface) => {
                this.setTitle(label);
                this.setCookie(label);
            })
        );
    }    

    /**
     * loads the label by the passed hostname from api
     * 
     * @param hostname 
     * @returns 
     */
    private loadLabel(hostname: string): Observable<LabelInterface> {
        // if the lable is loading we return a onetime observable
        // for the loading result
        if(this.isLoading){
            return this.getLabelObservable().pipe(
                filter((label: LabelInterface) => label !== null),
                first()
            );
        }
        // encode hostname to base64 and setup the request to load
        // load the label with the encoded hostname
        const base64 = btoa(hostname);
        const defaultLabel = { name: environment.title, title: environment.title, short: 'none', hostname: environment.host, useCss: false, useJs: false };
        return this.requestApi(base64).pipe(
            tap((receivedLabel: LabelInterface) => {
                // setupt the lable received label object or the default if non received
                const label: LabelInterface = (receivedLabel === null)
                    ? defaultLabel
                    : receivedLabel;
                // store the label and return it
                this.labelSubject.next(label);
            }));
    }

    /**
     * returns the current hostname without www.
     * 
     * @returns 
     */
    private getHostname(): string {
        return window.location.hostname.replace('www.', '');
    }

    /**
     * sets the page title to the label title
     * 
     * @param label 
     */
    private setTitle(label: LabelInterface): void {
        const title = label.title || environment.title;
        this.titleService.setTitle(title);
    }

    /**
     * sets the label short name to a cookie
     * 
     * @param label
     */
    private setCookie(label: LabelInterface): void {
        const expires = moment().add(30, 'days').toDate();
        this.cookieService.set(this.cookieName, label.short, expires);
    }

    /**
     * request the api
     * 
     * @param url 
     * @param params 
     * @returns 
     */
    protected requestApi(url: string = ''): Observable<LabelInterface> {
        this.isLoading = true;
        this.labelSubject.next(null);
        return this.httpService.get<LabelInterface>(apiUrl + '/' + url, null, null).pipe(
            map((label: LabelInterface) => {
                this.isLoading = false;
                return label;
            }));
    }    
}
