import { SocialAuthenticationService } from "../service/social-authentication.service";

/**
 * starts to listen on social authentications
 */
export function SocialAuthenticationInitializer(socialAuthentication: SocialAuthenticationService) {
    return () => new Promise<void>(resolve => {
        socialAuthentication.onAppInitialization().then(() => {
            resolve();
        });
    });
}