import {
    AfterViewInit,
    Component,
    ComponentRef,
    EmbeddedViewRef,
    OnDestroy,
    Type,
    ViewContainerRef,
} from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { Modal, ModalController } from "../..";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "app-modal-inject-component",
    templateUrl: "./modal-inject-component.component.html",
    styleUrls: ["./modal-inject-component.component.scss"],
})
export class ModalInjectComponentComponent extends ModalComponent implements AfterViewInit, OnDestroy {
    /**
     * the element with the id becomes replaced
     * with the content of the component
     */
    public replacementId: string;

    /**
     * component to create
     */
    private component: Type<any>;

    /**
     * component reference of the created component
     */
    private componentRef: ComponentRef<any>;

    constructor(
        protected viewContainerRef: ViewContainerRef,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({
            component: null,
            replacementId: "shared-modal-injection-container",
        });
        this.replacementId = this.data.replacementId;
        this.component = this.data.component || null;
        this.componentRef = null;
    }

    /**
     * inject the component after init
     */
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        Promise.resolve(null).then(() => this.injectComponent());
    }

    /**
     * destroy the component
     */
    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.destroyComponent();
    }

    /**
     * injects the component to the modal
     */
    private injectComponent(): void {
        // check if the component is set
        if (this.component !== null) {
            // inject component into app and pass this modal to it
            const componentRef = this.viewContainerRef.createComponent(this.component);
            componentRef.instance.bar = this;
            if(componentRef.instance.setParentModal){
                componentRef.instance.setParentModal(this);
            }
            // incect component into DOM wile replace the element with the replacementId
            const replaceElement = document.getElementById(this.replacementId);
            const componentDom = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            replaceElement.parentNode.replaceChild(componentDom, replaceElement);
            // store the created component
            this.componentRef = componentRef;
        }
    }

    /**
     * destroy the component
     */
    private destroyComponent(): void {
        if (this.componentRef !== null) {
            this.componentRef.destroy();
        }
    }
}
