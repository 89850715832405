import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";
import { TranslationService } from "../../../../../core/translation";
import { FromHelper } from "../../../../../shared/form";
import { LabelService } from "../../../../core/label";
import { MemberService, RegistrationService } from "../../../../core/member";
import { SocialLoginService } from "../../../../core/social";
import { ModalController } from "../../model/modal-controller.model";
import { ModalEventType } from "../../model/modal-event-type.model";
import { Modal, ModalTutorial } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-register",
    templateUrl: "./modal-register.component.html",
    styleUrls: ["./modal-register.component.scss"],
})
export class ModalRegisterComponent extends ModalComponent implements OnInit {
    /**
     * register formular
     */
    public registerFormular: UntypedFormGroup;

    /**
     * error string to display in modal on
     * validation error
     */
    public error: string;

    /**
     * while we wait for the login response
     * isLoading is true otherwise false
     */
    public isLoading: boolean;

    /**
     * inject dependencies
     *
     * @param SocialLoginService
     * @param toastrService
     * @param registrationService
     * @param translationService
     * @param memberService
     * @param labelService
     * @param formBuilder
     * @param router
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected socialLoginService: SocialLoginService,
        protected registrationService: RegistrationService,
        protected translationService: TranslationService,
        protected memberService: MemberService,
        protected labelService: LabelService,
        protected formBuilder: UntypedFormBuilder,
        protected router: Router,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
    }

    public ngOnInit(): void {
        this.buildFormular();
    }

    /**
     * on submit formular
     */
    public onSubmit(): boolean {
        if (this.registerFormular.invalid) {
            this.handleFormErrors();
            return false;
        }
        this.register();
        return false;
    }

    /**
     * opens the login modal
     */
    public onLogin(): boolean {
        this.close();
        this.modalController.getModalService().openLogin();
        return false;
    }

    /**
     * opens the facebook login
     */
    public onGoogle(): boolean {
        this.socialLoginService.loginByGoogle();
        return false;
    }

    /**
     * opens the facebook login
     */
    public onFacebook(): boolean {
        this.socialLoginService.loginByFacebook();
        return false;
    }

    /**
     * close this modal and redirect to terms
     *
     * @returns
     */
    public toTerms(): boolean {
        this.close();
        this.router.navigate(["/terms"]);
        return false;
    }

    /**
     * builds the register formular
     */
    private buildFormular(): void {
        this.registerFormular = this.formBuilder.group({
            username: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(32),
                    Validators.pattern(/^[\p{L}0-9]+[\p{L}0-9\-\._]+$/iu),
                ],
            ],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(32)]],
            terms: [null, [Validators.required, Validators.requiredTrue]],
            newsletter: [null],
        });
    }

    /**
     * try to register user via backend request
     */
    private register(): void {
        this.isLoading = true;
        let memberdata = FromHelper.getFormValues(this.registerFormular);
        this.registrationService.register(memberdata).subscribe({
            next: (response: { token: string; appname: string; bonus: string }) => {
                //const label = this.labelService.getLabelSnapshot();
                this.initMember();
                this.resetRegister();
                this.openRegisterSuccess(response.appname, +response.bonus);
            },
            error: (e) => {
                this.isLoading = false;
                this.error = typeof e?.error !== "string" ? e?.error?.data?.error || "" : e.error;
            },
        });
    }

    /**
     * handles form errors
     */
    private handleFormErrors(): void {
        const e = FromHelper.getFirstValidationError(this.registerFormular);
        this.error = "register.error." + e.control + "." + e.error;
    }

    /**
     * init the member by reloading the member
     * data via the memberService
     */
    private initMember(): void {
        this.memberService.reload().subscribe();
    }

    /**
     * resets the register formular to erase the
     * the input data, reset the errors, set the
     * loading state to false and close the modal
     */
    private resetRegister(): void {
        this.registerFormular.reset();
        this.isLoading = false;
        this.error = "";
        this.close();
    }

    /**
     * open the register success modal and connect the
     * closing of this modal with the tutorial modal
     *
     * @param appname
     * @param bonus
     */
    public openRegisterSuccess(appname: string, bonus: number): void {
        // open the success modal
        const defaultController = this.modalController.getModalService().openDefault({
            title: { text: "register.success.title", parameter: { app: appname } },
            message: {
                text: "register.success.message",
                parameter: { coins: this.translationService.formatNumber(bonus) },
            },
            buttonConfirm: "register.success.button",
            memberReload: true,
        });
        // connect the close event to open the tutorial modal
        defaultController
            .onModalEvent(ModalEventType.Closed)
            .pipe(first())
            .subscribe(() => this.openTutorial());
    }

    /**
     * opens the tutorial modal
     */
    public openTutorial(): void {
        this.modalController.getModalService().open(new ModalTutorial());
    }

    /**
     * shortcut to use form contols
     */
    get fc(): any {
        return this.registerFormular.controls;
    }
}
