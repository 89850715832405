<ng-template #modal>
    <div class="modal-header" id="payment-modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pb-5" *ngIf="isPaymentLoading()">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                {{ 'shop.payment.prepare.loading' | translate }} 
            </div>
        </div>
    </div>
    <div class="modal-body pb-5 text-center" *ngIf="isPaymentComplete()">
        <h2 class="tigerfont text-uppercase mb-3"> {{ 'shop.payment.success.title' | translate }} </h2>

        <div class="row">
            <div class="col-12 mx-auto" *ngIf="isCoinPackage()">
                <div class="tigercard">
                    <div class="card-element rank-icon">
                        <span class="tiger-sprite huge-icon" [class]="data.offer.icon"></span>
                    </div>
                    <div class="card-element card-name tigerfont">{{ data.offer.title | translate }}</div>
                    <div class="card-element card-reward">
                        <div class="card-reward-amount">{{ data.offer.coins | number: "1.0" }} <span>{{ 'coins' | translate }}</span></div>
                    </div>
                </div>
            </div>

            <div class="col-12 mx-auto" *ngIf="isBooster()">
                <div class="tigercard">
                    <div class="card-element rank-icon">
                        <span class="tiger-sprite huge-icon" [class]="data.offer.imageClass+'-big'"></span>
                    </div>                    
                    <div class="card-element card-reward disabled">
                        <div class="card-reward-amount">
                            {{ data.offer.percentage }}%
                            <span *ngIf="isBoosterXp()">{{ 'booster.xp.title' | translate }}</span>
                            <span *ngIf="isBoosterCoins()">{{ 'booster.coins.title' | translate }}</span>
                        </div>
                        <div class="card-reward-amount" [innerHTML]="'booster.duration' | translate: { duration: data.offer.duration }"></div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4" (click)="modalButton()">
                    {{ 'modal.default.button' | translate }}
                </button>
            </div>
        </div>
    </div>    
    <iframe class="payment-iframe" [class]="getIFrameClasses()" (load)="onSrcLoad(iFrame)" (beforeprint)="onSrcChange(iFrame)" *ngIf="paymentUrl" [src]="paymentUrl | safeUrl" #iFrame></iframe>
</ng-template>