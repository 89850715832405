import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, first, map, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../http";
import { CountryInterface } from "../interface/country.interface";

/**
 * Basic Api Url for country
 */
const apiCountyUrl = `${environment.api.request}/country`;

@Injectable({
    providedIn: "root",
})
export class CountryService {
    /**
     * the current countries subject
     */
    private countriesSubject: BehaviorSubject<CountryInterface[]>;

    /**
     * current loading state
     */
    private isLoading: boolean = false;

    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {
        // set countries subject
        this.countriesSubject = new BehaviorSubject<CountryInterface[]>(null);
    }

    /**
     * returns an on-time countries observable
     */
    public getCountries(): Observable<CountryInterface[]> {
        // start a new laoding request and return it
        if (!this.isLoading && this.countriesSubject.value === null) {
            this.countriesSubject.next(null);
            return this.loadCountries();
        }
        // transform current countries into an observable and return it
        return this.getCountriesObservable().pipe(
            filter((countries) => countries != null),
            first()
        );
    }

    /**
     * returns the countries subject as observable
     *
     * @returns
     */
    public getCountriesObservable(): Observable<CountryInterface[]> {
        return this.countriesSubject.asObservable();
    }

    /**
     * loads member countries data from api
     */
    private loadCountries(): Observable<CountryInterface[]> {
        this.isLoading = true;
        return this.httpService.get<CountryInterface[]>(`${apiCountyUrl}`).pipe(
            catchError((e: any) => {
                this.isLoading = false;
                return throwError(e);
            }),
            tap((countries) => {
                this.countriesSubject.next(countries);
                this.isLoading = false;
            })
        );
    }
}
