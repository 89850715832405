import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { TranslationService } from "../../../../../core/translation";
import { WinLadderInerface, WinLadderService } from "../../../../../core/winladder";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "app-modal-season-win-ladder",
    templateUrl: "./modal-season-win-ladder.component.html",
    styleUrls: ["./modal-season-win-ladder.component.scss"],
})
export class ModalSeasonWinLadderComponent extends ModalComponent {
    /**
     * the involved winladder
     */
    public winLadder: WinLadderInerface;

    /**
     * tracks if the bonus is ready for count up
     */
    private showBonus: boolean;

    /**
     * inject dependencies
     *
     * @param translationService
     * @param winLadderService
     * @param memberService
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected translationService: TranslationService,
        protected winLadderService: WinLadderService,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ winLadderId: 0, result: "hold" });
        this.winLadder = this.winLadderService.getById(this.data.winLadderId);
    }

    /**
     * reload member on open
     *
     * @param data
     */
    public onOpen(data?: any): void {
        this.memberService.reload().subscribe();
    }

    /**
     * returns the button fadein animation delay
     * depending on the possible win
     *
     * @returns
     */
    public getButtonAnimationDelay(): string {
        return this.data?.bonus?.coins ? "animate__delay-2500ms" : "animate__delay-1500ms";
    }
}
