import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ParticipatedOfferInterface } from '../interface/participated-offer.interface';
import { StatusEnum } from '../../bet/model/status.enum'; // circular injection if not explicit?
import { MemberInterface } from '../interface/member.interface';
import { MemberService } from './member.service';
import { HttpService } from '../../../../core/http';


/**
 * Basic api url for members participated bet offers requests
 */
const apiMemberParticipatedOffersUrl = `${environment.api.request}/member/participated/offers`;

@Injectable({
    providedIn: 'root'
})
export class ParticipatedOfferListService {
    /**
     * current loading state
     */
    private isLoading: boolean = false;

    /**
     * currently loaded list
     */
    private betOfferListSubject: BehaviorSubject<ParticipatedOfferInterface[]>;

    /**
     * prepare the service
     * 
     * @param httpService 
     * @param memberService 
     */
    constructor(
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        this.betOfferListSubject = new BehaviorSubject<ParticipatedOfferInterface[]>(null);
        // subscribe member changes
        this.memberService.getMemberObservable().subscribe((member: MemberInterface) => {
            // if member is set, reload the list
            if (member) {
                this.loadList().subscribe();
            }
            // if not, clear the list
            else{
                this.clear();
            }
        });
    }

    /**
     * returns the list subject as observable
     * 
     * @returns 
     */
    public getOfferListObservable(): Observable<ParticipatedOfferInterface[]> {
        return this.betOfferListSubject.asObservable();
    }

    /**
     * reloads the betOfferListSubject
     * 
     * @returns 
     */
    public refresh(): Observable<ParticipatedOfferInterface[]> {
        if(!this.memberService.getMemberSnapshot() || this.isLoading){
            return this.getOfferListObservable().pipe(first());
        }
        // return load list
        return this.loadList();
    }

    /**
     * clears the current list
     */
    public clear(): void {
        this.betOfferListSubject.next(null);
    }

    /**
     * loads the list from api
     */
    private loadList(): Observable<ParticipatedOfferInterface[]> {
        this.isLoading = true;
        const params = new HttpParams().set("status", StatusEnum.Pending);
        const username = this.memberService.getMemberSnapshot().username;
        return this.httpService.get<ParticipatedOfferInterface[]>(`${apiMemberParticipatedOffersUrl}/` + username, params, []).pipe(
            tap((list) => {
                this.betOfferListSubject.next(list);
                this.isLoading = false;
            }));
    }
}