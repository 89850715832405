import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, filter, first, map, tap } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpService } from "../../http";

// system check url
const apiUrl = `${environment.api.request}/system/status`;

@Injectable({
    providedIn: "root",
})
export class ConnectionService {
    /**
     * true if everything is fine,
     * false if we had some troubles
     * while requesting necessary
     * data or receiving a down messgae
     */
    public statusSubject: BehaviorSubject<boolean>;

    /**
     * indicates tcp status
     */
    public tcpStatusSubject: BehaviorSubject<boolean>;

    /**
     * retry interval if isApiConnected is
     * set to false
     */
    private connectionTestInterval: ReturnType<typeof setInterval> | null;

    /**
     * reload time in ms (in dev it will be overwritten with 5000ms)
     */
    private realoadTime: number = 10000;

    constructor(
        // inject dependencies
        private httpService: HttpService
    ) {
        this.statusSubject = new BehaviorSubject<boolean>(true);
        this.tcpStatusSubject = new BehaviorSubject<boolean>(true);
        this.connectionTestInterval = null;
        this.realoadTime = environment.env !== "dev" ? this.realoadTime || 60000 : 5000;
    }

    /**
     * returns the api connection status as observable
     *
     * @returns
     */
    public getStatusObservable(): Observable<boolean> {
        return this.statusSubject.asObservable();
    }

    /**
     * returns the tcp status as observable
     *
     * @returns
     */
    public getTcpStatusObservable(): Observable<boolean> {
        return this.tcpStatusSubject.asObservable();
    }

    /**
     * test the current connection
     * state and handles the error
     * if the response was not as
     * as expected
     */
    public test(): Observable<boolean> {
        return this.testConnection().pipe(
            tap((status: boolean) => {
                // at first next the current state
                if (this.statusSubject.value !== status) {
                    this.statusSubject.next(status);
                }
                // handle the current status which means
                // start or stop the connection tests
                this.handleCurrentStatus();
            })
        );
    }

    /**
     * set the status to the passed if its
     * not the same as the current state
     * and handles the new status by
     * starting or stopping the test
     * interval
     *
     * @param status
     */
    public setStatus(status: boolean): void {
        // at first next the current state
        if (this.statusSubject.value !== status) {
            this.statusSubject.next(status);
        }
        // handle the current status which means
        // start or stop the connection tests
        this.handleCurrentStatus();
    }

    /**
     * handles the current status
     */
    private handleCurrentStatus(): void {
        // if api connected and and an interval is running, we just clear it
        if (this.statusSubject.value && this.connectionTestInterval !== null) {
            clearInterval(this.connectionTestInterval);
            this.connectionTestInterval = null;
        }
        // if not connected and no interval is running, we start it
        if (!this.statusSubject.value && this.connectionTestInterval === null) {
            this.connectionTestInterval = setInterval(() => {
                // check for the current status and if its okay, we reload the
                // page. Wo do this because we want to force a complete reload
                // of every currently loaded data, just to be sure everything
                // is up to date.
                this.testConnection()
                    .pipe(filter((success: boolean) => success === true))
                    .subscribe((success: boolean) => {
                        location.reload();
                    });
            }, this.realoadTime);
        }
    }

    /**
     * test if we can connect to the api server and receive
     * the correct result
     */
    private testConnection(): Observable<boolean> {
        return this.requestApi().pipe(
            // return false on any error
            catchError((err) => of(false)),
            // check for a new tcp state
            tap((response: any) => {
                const tcpStatus = !!response.tcp;
                if (tcpStatus !== this.tcpStatusSubject.value) {
                    this.tcpStatusSubject.next(tcpStatus);
                }
            }),
            // return true if the expected response status text was received
            map((response: any) => (response.status || "ERROR") === "RUNNING")
        );
    }

    /**
     * request the api
     *
     * @param url
     * @param params
     * @returns
     */
    private requestApi(url: string = "", params?: { [key: string]: string }): Observable<string> {
        return this.httpService.get(apiUrl + url, params);
    }
}
