import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { HttpService } from "../../../../core/http";
import { MemberService as CoreMemberService } from "../../../../core/member";
import { AuthenticationService } from "../../../../core/security";
import { WebSocketDataEventInterface, WebSocketEventEnum, WebSocketService } from "../../../../core/websocket";

@Injectable({
    providedIn: "root",
})
export class MemberService extends CoreMemberService {
    /**
     * setup the service
     *
     * @param httpService
     * @param cookieService
     * @param authService
     * @param webSocketService
     */
    constructor(
        protected httpService: HttpService,
        protected cookieService: CookieService,
        protected authService: AuthenticationService,
        protected webSocketService: WebSocketService
    ) {
        super(httpService, cookieService, authService);
        this.observeWallet();
    }

    /**
     * This function subscribes to a websocket event and updates the member's coins when the event is
     * triggered.
     */
    private observeWallet(): void {
        this.webSocketService.getDataObservable(
            WebSocketEventEnum.MemberWalletUpdate
        ).subscribe((event: WebSocketDataEventInterface) => {
            if(this.hasMember()){
                const member = this.getMemberSnapshot();
                member.progress.coins = +event.data.coins;
                this.setMember(member);
            }
        });
    }
}
