import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {
    constructor() { }

    public transform(value: any, inSeconds: boolean = false): any {
        const date = new Date(value);
        const ts = date.getTime();
        return (inSeconds) ? Math.round(ts / 1000) : ts;
    }
}
