import { environment } from "../../../../../environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, first, map, tap } from "rxjs/operators";
import { MemberService } from "../../member";
import { SeasonRankingInterface } from "../interface/season-ranking.interface";
import { SeasonInterface } from "../interface/season.interface";
import { HttpService } from "../../../../core/http";
import moment from "moment";

/**
 * Basic Api Url for Season loading
 */
const apiSeasonUrl = `${environment.api.request}/season`;

@Injectable({
    providedIn: "root",
})
export class SeasonService {
    /**
     * currently running season
     */
    private seasonSubject: BehaviorSubject<SeasonInterface>;

    /**
     * currently running season
     */
    private rankingSubject: BehaviorSubject<SeasonRankingInterface>;

    /**
     * indicatates if the season is loading from api
     */
    private isSeasonLoading: boolean = false;

    /**
     * indicatates if ranking is loading from api
     */
    private isRankingLoading: boolean = false;

    /**
     * timeout to reload the season (and member)
     */
    private reloadTimeout: ReturnType<typeof setTimeout> | null;

    constructor(private httpService: HttpService, private memberService: MemberService) {
        this.seasonSubject = new BehaviorSubject<SeasonInterface>(null);
        this.rankingSubject = new BehaviorSubject<SeasonRankingInterface>(null);
        this.reloadTimeout = null;
    }

    /**
     * returns the current season
     *
     * @param reload
     */
    public getSeason(reload: boolean = false): Observable<SeasonInterface> {
        if (!this.isSeasonLoading && (this.seasonSubject.value === null || reload)) {
            this.isSeasonLoading = true;
            this.seasonSubject.next(null);
            return this.requestSeason().pipe(
                tap((season: SeasonInterface) => {
                    this.isSeasonLoading = false;
                })
            );
        }
        return this.getSeasonObeservable().pipe(
            filter((season) => season != null),
            first()
        );
    }

    /**
     * returns the season subject as observable
     */
    public getSeasonObeservable(): Observable<SeasonInterface> {
        return this.seasonSubject.asObservable();
    }

    /**
     * returns the current ranking
     *
     * @param reload
     */
    public getRanking(winLadderName: string): Observable<SeasonRankingInterface> {
        if (!this.isRankingLoading) {
            this.isRankingLoading = true;
            this.rankingSubject.next(null);
            return this.requestRanking(winLadderName).pipe(
                tap((ranking: SeasonRankingInterface) => {
                    this.isRankingLoading = false;
                })
            );
        }
        return this.getRankingObeservable().pipe(
            filter((ranking) => ranking != null),
            first()
        );
    }

    /**
     * returns the ranking subject as observable
     */
    public getRankingObeservable(): Observable<SeasonRankingInterface> {
        return this.rankingSubject.asObservable();
    }

    /**
     * request season data from api and start the
     * reload timeout
     */
    private requestSeason(): Observable<SeasonInterface> {
        const fallback: SeasonInterface = {
            seasonId: 0,
            startDate: moment().toDate(),
            endDate: moment().add(2, "hours").toDate(),
        };
        return this.httpService.get<SeasonInterface>(apiSeasonUrl, [], fallback).pipe(
            map((season: SeasonInterface) => {
                if(season?.seasonId){
                    season.startDate = new Date(season.startDate);
                    season.endDate = new Date(season.endDate);
                    return season;
                }
                return fallback;
            }),
            tap((season: SeasonInterface) => {
                this.seasonSubject.next(season);
                this.setReloadTimeout(season);
            })
        );
    }

    /**
     * request ranking data from api
     *
     * @param winLadderName
     */
    private requestRanking(winLadderName: string): Observable<SeasonRankingInterface> {
        const emptyRanking: SeasonRankingInterface = { xp: [], coins: [], quote: [] };
        return this.httpService
            .get<SeasonRankingInterface>(apiSeasonUrl + "/ranking/" + winLadderName, null, emptyRanking)
            .pipe(
                tap((ranking: SeasonRankingInterface) => {
                    this.rankingSubject.next(ranking);
                })
            );
    }

    /**
     * reloads the season
     *
     * @param season
     */
    private setReloadTimeout(season: SeasonInterface): void {
        // clear timer if its running
        if (this.reloadTimeout !== null) {
            clearTimeout(this.reloadTimeout);
        }
        // calc and start the reload timeout (one second after end,
        // because the endtime is also in the current season)
        const timeout = season.endDate.getTime() - Date.now() + 1000;
        setTimeout(
            () => {
                this.requestSeason().subscribe((season: SeasonInterface) => {
                    // wait before reloading the user
                    setTimeout(() => this.memberService.reload().subscribe(), 2000);
                });
            },
            timeout > 0 ? timeout : 0
        );
    }
}
