import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import {
    BetSlipLoaderService,
    BetSlipModeEnum,
    BetSlipResponseInterface,
    StatusEnum,
} from "../../../../core/bet";
import { MemberService } from "../../../../core/member";
import { ModalController } from "../../model/modal-controller.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "modal-bet-slip-detail",
    templateUrl: "./modal-bet-slip-detail.component.html",
    styleUrls: ["./modal-bet-slip-detail.component.scss"],
})
export class ModalBetSlipDetailComponent extends ModalComponent {
    /**
     * bet slips to display
     */
    public betSlips: BetSlipResponseInterface[];

    /**
     * all bet slip modes
     */
    public betSlipMode = BetSlipModeEnum;

    /**
     * all bet / bet slip status
     */
    public status = StatusEnum;

    /**
     * modal status (which kind of headline like win, lose, void and mixed)
     */
    public modalStatus: string;

    /**
     * indicates if the modal is loading
     */
    public isLoading = true;

    /**
     * inject dependencies
     *
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected betSlipLoaderService: BetSlipLoaderService,
        protected memberService: MemberService,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
        this.setDataFallback({ betSlipIds: [], modalStatus: "mixed" });
        this.loadBetSlips(this.data.betSlipIds);
        this.modalStatus = this.data.modalStatus;
    }

    /**
     * refresh member on open modal
     */
    public onOpen(): void {
        this.memberService.reload().subscribe();
    }

    /**
     * returns css classes for different bet modes
     *
     * @param mode
     * @returns
     */
    public getBetCss(mode: string): string[] {
        const cssClass: string[] = [];
        if (mode === BetSlipModeEnum.System || mode === BetSlipModeEnum.Combi) {
            cssClass.push("combination");
        }
        return cssClass;
    }

    /**
     * returns the amount of betslips
     *
     * @returns
     */
    public getBetSlipCount(): number {
        return Object.keys(this.betSlips).length;
    }

    /**
     * load bet slips via api
     *
     * @param betSlipId
     */
    private loadBetSlips(betSlipIds: number[]): void {
        this.betSlipLoaderService.loadByIds(betSlipIds).subscribe({
            error: () => {
                this.modalController.getModalService().openError("account.bets.slip.loading.error");
                (this.isLoading = false), this.close({ delay: 300 });
            },
            next: (betSlips: BetSlipResponseInterface[]) => {
                this.isLoading = false;
                this.betSlips = betSlips;
            },
        });
    }
}
