import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BsModalService } from "ngx-bootstrap/modal";
import { first } from "rxjs/operators";
import { TranslationService } from "../../../../../core/translation";
import { ToastrService } from "../../../../../shared/toastr";
import { LabelService } from "../../../../core/label";
import { MemberService, RegistrationService } from "../../../../core/member";
import { SocialLoginService } from "../../../../core/social";
import { ModalEventInterface } from "../../interface/modal-event.interface";
import { ModalController } from "../../model/modal-controller.model";
import { ModalEventType } from "../../model/modal-event-type.model";
import { Modal } from "../../model/modal.model";
import { ModalComponent } from "../modal/modal.component";

@Component({
    selector: "app-modal-login",
    templateUrl: "./modal-login.component.html",
    styleUrls: ["./modal-login.component.scss"],
})
export class ModalLoginComponent extends ModalComponent implements OnInit {
    /**
     * the username input field in dom
     */
    public readonly inputUsernameId: string = "loginInputUsernameId";

    /**
     * the password input field in dom
     */
    public readonly inputPasswordId: string = "loginInputPasswordId";

    /**
     * login formular
     */
    public loginFormular: UntypedFormGroup;

    /**
     * while we wait for the login response
     * isLoading  is true otherwise false
     */
    public isLoading: boolean;

    /**
     * error string to display in modal on
     * validation error
     */
    public error: string;

    /**
     * inject dependencies
     *
     * @param socialLoginService
     * @param toastrService
     * @param registrationService
     * @param translationService
     * @param memberService
     * @param labelService
     * @param formBuilder
     * @param bsModalService
     * @param modalController
     * @param modal
     */
    constructor(
        protected socialLoginService: SocialLoginService,
        protected toastrService: ToastrService,
        protected registrationService: RegistrationService,
        protected translationService: TranslationService,
        protected memberService: MemberService,
        protected labelService: LabelService,
        protected formBuilder: UntypedFormBuilder,
        protected bsModalService: BsModalService,
        protected modalController: ModalController,
        protected modal: Modal
    ) {
        super(bsModalService, modalController, modal);
    }

    public ngOnInit(): void {
        this.buildLoginFormular();
    }

    /**
     * auto set the username value
     *
     * @param data
     */
    public onOpen(data?: any): void {
        const username = this.memberService.getPreviousLoginUsername();
        // if the username is known, we set to input id we want to
        // focus to the password input id or else to the username
        // input id
        let toFocusId: string;
        if (username) {
            this.fc.username.value = username;
            toFocusId = this.inputPasswordId;
        } else {
            toFocusId = this.inputUsernameId;
        }
        // wait a little bit and then try to focus the element
        setTimeout(() => {
            const toFocus = document.getElementById(toFocusId);
            if (toFocus) {
                toFocus.focus();
            }
        }, 250);
    }

    /**
     * on submit login formular
     */
    public onSubmit(): boolean {
        if (this.isLoading) {
            return false;
        }
        if (this.loginFormular.invalid) {
            this.handleFormErrors();
            return false;
        }
        this.login();
        return false;
    }

    /**
     * opens the password reset modal
     */
    public onPassword(): boolean {
        this.close();
        const controller = this.modalController.getModalService().openPasswordReset();
        controller
            .onModalEvent(ModalEventType.Success)
            .pipe(first())
            .subscribe((event: ModalEventInterface) => {
                this.modalController.getModalService().openSuccess(event.data);
            });
        return false;
    }

    /**
     * opens the register modal
     */
    public onRegister(): boolean {
        this.close();
        this.modalController.getModalService().openRegister();
        return false;
    }

    /**
     * opens the facebook login
     */
    public onGoogle(): boolean {
        this.socialLoginService.loginByGoogle();
        return false;
    }

    /**
     * opens the facebook login
     */
    public onFacebook(): boolean {
        this.socialLoginService.loginByFacebook();
        return false;
    }

    /**
     * builds the loginformular
     */
    private buildLoginFormular(): void {
        this.loginFormular = this.formBuilder.group({
            username: [null, [Validators.required]],
            password: [null, Validators.required],
        });
    }

    /**
     * try to login user via backend request
     */
    private login(): void {
        this.isLoading = true;
        this.memberService.login(this.fc.username.value, this.fc.password.value).subscribe({
            next: () => {
                this.error = "";
                this.isLoading = false;
                this.loginFormular.reset();
                this.loginSuccessNotify();
                this.close();
            },
            error: (error) => {
                this.isLoading = false;
                this.handleServerError();
            },
        });
    }

    /**
     * just set the error message to login failed
     */
    private handleServerError(): void {
        this.error = "login.error.failed";
    }

    /**
     * handles form errors
     */
    private handleFormErrors(): void {
        let error: string;
        if (this.fc.username.errors) {
            error = "login.error.username";
        } else if (this.fc.password.errors) {
            error = "login.error.password";
        }
        this.error = error;
    }

    /**
     * shows the login success toastr notification
     */
    private loginSuccessNotify(): void {
        setTimeout(
            () =>
                this.toastrService.success({
                    title: "login.success.title",
                    message: "login.success.message",
                }),
            250
        );
    }

    /**
     * shortcut to use form contols
     */
    get fc(): any {
        return this.loginFormular.controls;
    }
}
