<form [formGroup]="creationFormular" (submit)="onSubmit()">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'bettingGame.dashboard.creation.headline' | translate }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <fieldset class="mb-4">
            <div class="form-group">
                <label for="betting-game-league" class="font-weight-bold">{{ 'bettingGame.default.league.label' | translate }}</label>
                <select id="betting-game-league" class="form-control" name="league" formControlName="league">
                    <ng-container *ngIf="!leaguesSelect; else leagueOptions">
                        <option value="0">{{ 'loading' | translate }}</option>
                    </ng-container>
                    <ng-template #leagueOptions>
                        <!-- ALL TOP LEAGUES -->
                        <optgroup label="TOP">
                            <option *ngFor="let league of leaguesSelect.popular | keyvalue: orderOrigin" value="{{ league.value.leagueId }}">
                                {{ league.value.leagueName }}
                            </option>
                        </optgroup>
                        <!-- ALL LEAGUES GROUPED BY SPORT AND LOCATION -->
                        <ng-container *ngFor="let sport of leaguesSelect.sports | keyvalue: orderOrigin">
                            <optgroup *ngFor="let location of sport.value.locations | keyvalue: orderByName" label="{{ sport.value.sportName }} - {{ location.value.locationName }}">
                                <option *ngFor="let league of location.value.leagues | keyvalue: orderByName" value="{{ league.value.leagueId }}">
                                    {{ league.value.leagueName }}
                                </option>
                            </optgroup>
                        </ng-container>
                    </ng-template>
                </select>
            </div>
        </fieldset>
        <fieldset>
            <div class="form-group">
                <label class="font-weight-bold" for="betting-game-name">{{ 'bettingGame.default.name.label' | translate }}</label>
                <input type="text" class="form-control" id="betting-game-name" formControlName="name">
            </div>
        </fieldset>
        <fieldset>
            <div class="form-group">
                <label class="font-weight-bold" for="betting-game-decsription">{{ 'bettingGame.default.description.label' | translate }}</label>
                <textarea class="form-control" id="betting-game-description" formControlName="description"></textarea>
            </div>
        </fieldset>        
        <div *ngIf="error" class="text-center">{{ error|translate }}</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">{{ 'bettingGame.dashboard.creation.button.cancel' | translate }}</button>
        <button type="submit" class="btn btn-primary" [disabled]="!canSubmit()">{{ 'bettingGame.dashboard.creation.button.save' | translate }}</button>
    </div>
</form>