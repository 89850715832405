<ng-template #modal>
    <div class="modal-header" [class]="data.headerClass">
        <button type="button" class="close" [class]="data.headerClass" aria-label="Close" (click)="modalDismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="glamour-container animate__animated animate__flipInX">
        <div class="starrays gold"></div>
        <span class="tiger-sprite huge-icon achievement-badge"></span>
        </div>
    </div>
    <!-- <span class="tiger-sprite huge-icon rank-1"></span> -->
    <div class="modal-body pb-5">
        <div class="row">
            <div class="col-12 col-sm-10 mx-auto text-center">
                <h1 class="tigerfont text-uppercase mb-4 animate__animated animate__fadeInUp animate__delay-500ms">
                    
                    <ng-container *ngIf="isModalText(data.header); else elseHeaderTranslation">{{ data.header.text | translate: data.header.parameter }}</ng-container>
                    <ng-template #elseHeaderTranslation>{{ data.header | translate }}</ng-template>

                </h1>
            </div>
            <div class="col-12 col-sm-10 mx-auto text-center">
                <p class="subline mb-4 animate__animated animate__fadeInUp animate__delay-1s">
                    <span class="placement-highlight-medium">
                        <ng-container *ngIf="isModalText(data.title); else elseTitleTranslation">{{ data.title.text | translate: data.title.parameter }}</ng-container>
                        <ng-template #elseTitleTranslation>{{ data.title | translate }}</ng-template><span *ngIf="level">: {{ 'level' | translate }} {{ level }}</span>
                    </span>
                </p>
                <div class="animate__animated animate__fadeInUp animate__delay-2s" *ngIf="hasBonus()">
                    <p class="subline mb-3">{{ bonus.message | translate }}</p>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="bonus?.coins">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png">
                                    +<app-shared-countup [to]="bonus.coins" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'coins' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>                                   
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="bonus?.xp">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/xp-1.png">
                                    +<app-shared-countup [to]="bonus.xp" [duration]="2" [delay]="2"></app-shared-countup>
                                    {{ 'xp' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card gain single animate__animated animate__fadeInUp animate__delay-500ms mb-1" *ngIf="bonus?.booster">
                        <div class="row">
                            <div class="col-12 col-sm-12">
                                <span class="gain-label"><img src="/assets/images/coin-1.png"> 1x {{ 'booster.'+bonus.booster+'.title' | translate }}</span>
                            </div>
                        </div>
                    </div>                                        
                </div>

            </div>
            <div class="col-12 col-sm-11 mx-auto text-center">
                <button type="button" class="btn btn-primary btn-lg mx-auto text-uppercase tigerfont mt-4 animate__animated animate__fadeInUp" [class]="getButtonAnimationDelay()" (click)="modalButton()">
                    <ng-container *ngIf="isModalText(data.buttonConfirm); else elseButtonConfirmTranslation">{{ data.buttonConfirm.text | translate: data.buttonConfirm.parameter }}</ng-container>
                    <ng-template #elseButtonConfirmTranslation>{{ data.buttonConfirm | translate }}</ng-template>
                </button>
            </div>
        </div>
    </div>    
    <!-- <p>{{ message | translate }}</p> -->
    <!-- <p class="font-italic" *ngIf="note">{{ note | translate }}</p> -->
</ng-template>