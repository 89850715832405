import { AbstractControl, FormGroup, UntypedFormGroup, ValidationErrors } from "@angular/forms";

export class FromHelper {
    /**
     * get all formvalues as sendable object
     *
     * @param form
     */
    public static getFormValues(form: FormGroup | UntypedFormGroup): { [key: string]: any } {
        const result = {};
        Object.keys(form.controls).forEach((key) => {
            const controlValue: any = form.get(key).value;
            result[key] = controlValue;
        });
        return result;
    }

    /**
	 * It returns the first validation error in a form group.
	 * 
	 * @param {FormGroup | UntypedFormGroup} form - FormGroup | UntypedFormGroup
	 * @returns A single error
	 */
	public static getFirstValidationError(form: FormGroup | UntypedFormGroup): any {
        const errors = FromHelper.getFormValidationErrorsArray(form);
        return errors.length > 0 ? errors[0] : null;
    }

    /**
     * It returns all formular errors as an object of objects that contain the error key, the control
     * key, the value of the error, and the origin of the error.
     *
     * @param {FormGroup | UntypedFormGroup} form - FormGroup | UntypedFormGroup
     * @returns An object with all form errors
     */
    public static getFormValidationErrorsObject(form: FormGroup | UntypedFormGroup): {} {
        return FromHelper._getFormValidationErrorsObject(form);
    }

    /**
     * It returns all formular errors as an array of objects that contain the error key, the control
     * key, the value of the error, and the origin of the error.
     *
     * @param {FormGroup | UntypedFormGroup} form - FormGroup | UntypedFormGroup
     * @returns An array with all form errors
     */
    public static getFormValidationErrorsArray(form: FormGroup | UntypedFormGroup): any[] {
        return FromHelper._getFormValidationErrorsArray(form);
    }

    /**
     * It returns all formular errors as an object of objects that contain the error key, the control
     * key, the value of the error, and the origin of the error.
     *
     * @param {FormGroup | UntypedFormGroup} form - FormGroup | UntypedFormGroup
     * @param {string} [flatKey] - string = ""
     * @returns An object with all form errors
     */
    private static _getFormValidationErrorsObject(
        form: FormGroup | UntypedFormGroup,
        flatKey: string = ""
    ): {} {
        const errors = {};
        for (const key of Object.keys(form.controls)) {
            const abstractControl: AbstractControl<any, any> = form.get(key);
            if (abstractControl instanceof FormGroup) {
                errors[key] = FromHelper.getFormValidationErrorsObject(abstractControl);
            } else if (abstractControl.errors) {
                const controlErrors = abstractControl.errors;
                for (const errorKey of Object.keys(controlErrors)) {
                    const controlPath = flatKey + key;
                    errors[key] = {
                        controlPath: controlPath,
                        control: key,
                        error: errorKey,
                        value: controlErrors[errorKey],
                    };
                }
            }
        }
        return errors;
    }

    /**
     * It returns all formular errors as an array of objects that contain the control path, control name,
     * error name, and error value.
     *
     * @param {FormGroup | UntypedFormGroup} form - FormGroup | UntypedFormGroup - The form group to get
     * the errors from.
     * @param {string} [flatKey] - string = ""
     * @param {any[]} errors - any[] = []
     * @returns An array of objects.
     */
    private static _getFormValidationErrorsArray(
        form: FormGroup | UntypedFormGroup,
        flatKey: string = "",
        errors: any[] = []
    ): any[] {
        for (const key of Object.keys(form.controls)) {
            const abstractControl: AbstractControl<any, any> = form.get(key);
            if (abstractControl instanceof FormGroup) {
                FromHelper._getFormValidationErrorsArray(abstractControl, flatKey + key + ".", errors);
            } else if (abstractControl.errors) {
                const controlErrors = abstractControl.errors;
                for (const errorKey of Object.keys(controlErrors)) {
                    const controlPath = flatKey + key;
                    errors.push({
                        controlPath: controlPath,
                        control: key,
                        error: errorKey,
                        value: controlErrors[errorKey],
                    });
                }
            }
        }
        return errors;
    }
}
