import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BetSlipResponseInterface } from '../interface/bet-slip-response.interface';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';


/**
 * api url for bet requests
 */
const apiBetUrl = `${environment.api.request}/bet`;

@Injectable({
    providedIn: 'root'
})
export class BetSlipLoaderService {
    /**
     * prepare the service
     * 
     * @param http
     */
    constructor(
        private httpService: HttpService
    ) { }

    /**
     * loads a bet slip by id
     * 
     * @param betSlipId 
     * @returns 
     */
    public loadById(betSlipId: number): Observable<BetSlipResponseInterface> {
        const params = new HttpParams().set("betSlipId", '' + betSlipId);
        return this.httpService.get<BetSlipResponseInterface>(`${apiBetUrl}/slip`, params);
    }

    /**
     * loads bet slips by ids
     * 
     * @param betSlipId 
     * @returns 
     */
    public loadByIds(betSlipIds: number[]): Observable<BetSlipResponseInterface[]> {
        const params = new HttpParams().set("betSlipIds", betSlipIds.join(','));
        return this.httpService.get<BetSlipResponseInterface[]>(`${apiBetUrl}/slips`, params)
    }
}