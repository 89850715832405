import { environment } from '../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CountUpModule } from 'ngx-countup';
import { CountdownModule } from 'ngx-countdown';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { GoogleLoginOptions, FacebookLoginOptions } from './front/core/social';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { PixelModule } from 'ngx-pixel';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}



@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CountUpModule,
        CountdownModule,
        ButtonsModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        CollapseModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        PaginationModule.forRoot(),
        CarouselModule.forRoot(),
        TranslateModule.forRoot({
            // translation loader
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            // compiler configuration
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        // Social Login 
        SocialLoginModule,
        // import facebook pixel without auto enabling
        PixelModule.forRoot({ enabled: false, pixelId: environment.facebook.pixel.id }),
        // import core module
        CoreModule
    ],
    providers: [
        // provide the tagmanager id for @angular-google-tag-manager
        { provide: 'googleTagManagerId', useValue: environment.google.tagManager.id },
        // provide config for @angularx-social-login
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    { id: GoogleLoginProvider.PROVIDER_ID, provider: new GoogleLoginProvider(environment.google.login.clientId, GoogleLoginOptions) },
                    { id: FacebookLoginProvider.PROVIDER_ID, provider: new FacebookLoginProvider(environment.facebook.login.appId, FacebookLoginOptions) }
                ],
                onError: (err) => {
                    if(!environment.production){
                        console.error(err);
                    }
                }
            } as SocialAuthServiceConfig,
        }


    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
