import { environment } from "../../../../../environments/environment";

/**
 * define the facebook login options
 * https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11
 */
 export const FacebookLoginOptions = {
    scope: environment.facebook.login.scope,
    enable_profile_selector: true,
    return_scopes: true,
}