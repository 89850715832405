import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService as OriginToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { ContentInterface } from '../interface/content.interface';
import merge from 'deepmerge';


@Injectable({
    providedIn: 'root'
})
export class ToastrService {
    private options: Partial<IndividualConfig> = {
        closeButton: true,
        progressBar: true,
        newestOnTop: false,
        tapToDismiss: false,
        positionClass: 'toast-bottom-left'
    }

    constructor(
        private toastr: OriginToastrService,
        private translateService: TranslateService
    ) { }

    /**
     * show successful toast
     * 
     * @param content 
     * @param parameter 
     * @param overwrite 
     * @returns 
     */
    public async success(content: ContentInterface, parameter?: {[key: string]: string | number}, overwrite?: Partial<IndividualConfig>): Promise<ActiveToast<any>> {
        return this.show('success', content.message, content.title, parameter, overwrite);
    }

    /**
     * show info toast
     * 
     * @param content 
     * @param parameter 
     * @param overwrite 
     * @returns 
     */
    public async info(content: ContentInterface, parameter?: {[key: string]: string | number}, overwrite?: Partial<IndividualConfig>): Promise<ActiveToast<any>> {
        return this.show('info', content.message, content.title, parameter, overwrite);
    }

    /**
     * show warning toast
     * 
     * @param content 
     * @param parameter 
     * @param overwrite 
     * @returns 
     */
    public async warning(content: ContentInterface, parameter?: {[key: string]: string | number}, overwrite?: Partial<IndividualConfig>): Promise<ActiveToast<any>> {
        return this.show('warning', content.message, content.title, parameter, overwrite);
    }

    /**
     * show error toast
     * 
     * @param content 
     * @param parameter 
     * @param overwrite 
     * @returns 
     */
    public async error(content: ContentInterface, parameter?: {[key: string]: string | number}, overwrite?: Partial<IndividualConfig>): Promise<ActiveToast<any>> {
        return this.show('error', content.message, content.title, parameter, overwrite);
    }

    /**
     * shows a toastr
     * 
     * @param type 
     * @param message 
     * @param title 
     * @param parameter 
     * @param overwrite 
     * @returns 
     */
    public async show(type: 'success' | 'info' | 'warning' | 'error', message?: string, title?: string, parameter?: {[key: string]: string | number}, overwrite?: Partial<IndividualConfig>): Promise<ActiveToast<any>> {
        return new Promise<ActiveToast<any>>((resolve) => {
            this.translate(message, title, parameter).then((translations) => {
                // toster options
                const toastrOptions = merge(this.options, overwrite || {});
                // toastr reference
                let toastr: ActiveToast<any>;
                // check which kind of toaster we need to open
                switch (type) {
                    case 'success':
                        toastr = this.toastr.success(translations.message, translations.title, toastrOptions);
                        break;
                    case 'warning':
                        toastr = this.toastr.warning(translations.message, translations.title, toastrOptions);
                        break;
                    case 'error':
                        toastr = this.toastr.error(translations.message, translations.title, toastrOptions);
                        break;
                    case 'info':
                    default:
                        toastr = this.toastr.info(translations.message, translations.title, toastrOptions);
                        break;
                }
                resolve(toastr);
            });
        });
    }


    /**
     * translate the message and title for the toastr notify
     * 
     * @param message 
     * @param title 
     * @returns 
     */
    private async translate(message?: string, title?: string, parameter?: {[key: string]: string | number}): Promise<{ message?: string, title?: string }> {
        // nothing to do here...
        if (!message && !title) {
            return { message: message, title: title };
        }
        // define translation paramerter
        const params = parameter || {};
        // translate message if set
        if (message) {
            message = await this.translateService.get(message, params).pipe(take(1)).toPromise();
        }
        // translate title if set
        if (title) {
            title = await this.translateService.get(title, params).pipe(take(1)).toPromise();
        }
        // return the result
        return { message: message, title: title };
    }
}
