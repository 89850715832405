import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: 'tcp', loadChildren: () =>  import('./tcp/tcp.module').then(m => m.TcpModule) },
    { path: '', loadChildren: () =>  import('./front/front.module').then(m => m.FrontModule) },
    // Fallback when no prior routes is matched
    {   path: '**', redirectTo: '', pathMatch: 'full'  }  
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }