import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/http';
import { MemberInterface, MemberSettingInterface } from '../interface/member.interface';
import { MemberService } from './member.service';


/**
 * Basic Api Url for setting actions
 */
const apiSettingUrl = `${environment.api.request}/member/settings`;

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    /**
     * member
     */
    private member: MemberInterface;

    /**
     * the current settings subject
     */
    private settingsSubject: BehaviorSubject<MemberSettingInterface>;

    /**
     * current loading state
     */
    private isLoading: boolean = false;


    /**
     * prepare the service
     * 
     * @param httpService 
     * @param memberService 
     */
    constructor(
        private httpService: HttpService,
        private memberService: MemberService
    ) {
        // set settings subject
        this.settingsSubject = new BehaviorSubject<MemberSettingInterface>(null);
        // listen on member changes to remove the settings for example
        this.memberService.getMemberObservable().pipe(tap((member: MemberInterface) => {
            if (member) { this.member = member; }
            else {
                this.settingsSubject.next(null);
                this.member = null;
            }
        })).subscribe();
    }

    /**
     * returns an settings observable
     */
    public getSettingsObservable(): Observable<MemberSettingInterface> {
        // start a new laoding request and return it
        if (!this.isLoading && this.member) {
            this.settingsSubject.next(null);
            return this.load();
        }
        // transform current settings into an observable and return it
        return this.settingsSubject.asObservable().pipe(
            filter(settings => settings != null),
            first()
        );
    }

    /**
     * saves member settings at api
     */
    public save(data: MemberSettingInterface): Observable<MemberSettingInterface> {
        return this.httpService.post<MemberSettingInterface>(`${apiSettingUrl}/` + this.member.memberId, { settings: data }).pipe(
            tap((settings) => {
                this.settingsSubject.next(settings);
            }));
    }

    /**
     * loads member settings data from api
     */
    private load(): Observable<MemberSettingInterface> {
        this.isLoading = true;
        return this.httpService.get<MemberSettingInterface>(`${apiSettingUrl}/` + this.member.memberId).pipe(
            tap((settings) => {
                this.settingsSubject.next(settings);
                this.isLoading = false;
            }));
    }
}
